import React, { useContext } from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ErrorsContext, IdSessionContext, UserSessionContext } from "./App";
import { AnimatedHint } from "./components/AnimatedHint";
import { BottomBar } from "./components/BottomBar";
import { ErrorCard } from "./components/ErrorCard";
import { InfoCard } from "./components/InfoCard";
import { ArrowRightLeft, Badge } from "./components/micro/icons";
import { OfferCard } from "./components/OfferCard";
import { PhotoView } from "./components/PhotoView";
import { Spinner } from "./components/Spinner";
import { LanguageContext } from "./contexts/LanguageContext";
import { IdSession } from "./IdSession";
import { payment_link } from "../utils/settings";

const imgRules = new URL("../img/rules.png  ", import.meta.url);

type Props = {};

export function PhotoPreview(props: Props) {
  const language = useContext(LanguageContext);
  const { userSession } = React.useContext(UserSessionContext);

  const onConfirm = () => {
  };
  const navigate = useNavigate();
  const { idSession, setIdSession } = React.useContext(IdSessionContext);
  const { errors: apiErrors } = React.useContext(ErrorsContext);
  const [isChecked, setIsChecked] = React.useState(false);
  let location = useLocation();
  let urlParams = useParams();
  let [urlSearchParams] = useSearchParams();

  const photoUris = useLoaderData() as string;
  const [photoUri, setPhotoUri] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [reloading, setReloading] = React.useState(false);

  React.useEffect(() => {
    console.log("api errors", apiErrors);
  }, [apiErrors]);

  React.useEffect(() => {
    console.log("photo pahts", urlSearchParams.get("photoPaths"));
  }, [urlSearchParams]);

  React.useEffect(() => {
    if (photoUris) {
      setPhotoUri(photoUris.split(";")[0]);
    }
  }, [photoUris]);

  const imageLoaded = () => {
    setLoading(false);
    setReloading(false);
  };

  const onNext = () => {
    if (!isChecked && !apiErrors?.length) {
      return;
    }
    if (apiErrors?.length) {
      navigate(-1);
      return;
    }
    setIdSession({
      ...idSession,
      status_id: 5,
    } as IdSession);
    if (!idSession?.id) {
      console.log("no user session");
      return;
    }
    window.location.href = `${payment_link}?client_reference_id=${idSession.id}`;
  };

  return (
    <>
      <div className="w-full mb-60">
        <div className="mx-auto" style={{ display: loading ? "block" : "none" }}>
          <Spinner />
        </div>
        <div style={{ display: loading ? "none" : "block" }}>
          <div className="relative mx-2">
            <div className="grid grid-cols-2 gap-12">
              {photoUris && (
                <>
                  <div className="mx-auto">
                    <PhotoView imgClasses="h-52 object-contain" photoUri={photoUri} imageLoaded={imageLoaded} />
                  </div>
                  <div className="relative h-52 ">
                    <div className={`absolute animate animate-in-and-out -translate-x-10 translate-y-[0.5rem] `}>
                      <span className="text-primary"><ArrowRightLeft stroke={3} size={8} /></span>
                    </div>
                    <div style={{ animationDelay: "2s" }} className={`opacity-0 absolute animate animate-in-and-out -translate-x-10 translate-y-[3.8rem] `}>
                      <span className="text-primary"><ArrowRightLeft stroke={3} size={8} /></span>
                    </div>
                    <div style={{ animationDelay: "4s" }} className={`opacity-0 absolute animate animate-in-and-out -translate-x-10 translate-y-[7.1rem] `}>
                      <span className="text-primary"><ArrowRightLeft stroke={3} size={8} /></span>
                    </div>
                    <div style={{ animationDelay: "6s" }} className={`opacity-0 absolute animate animate-in-and-out -translate-x-10 translate-y-[10.4rem] `}>
                      <span className="text-primary"><ArrowRightLeft stroke={3} size={8} /></span>
                    </div>
                    <img src={imgRules.toString()} className="h-full object-cover object-left mx-auto" />
                  </div>
                </>)}
            </div>
          </div>
        </div>
        <div className="mx-6">
          <div className="mt-2 flex justify-around">
            <div
              className="mx-auto"
              style={{ visibility: reloading ? "visible" : "hidden" }}
            >
              <Spinner />
            </div>
          </div>
          {(apiErrors && apiErrors.length > 0 && (
            <>
              <ErrorCard title={"⚠️ " + language.preview_error_title}>
                <ul>
                  {apiErrors.map((error) => (
                    <li key={error.title}>
                      <span className="font-bold">{language[error.title + "_title"]}</span>:{" "}
                      {language[error.title + "_description"]}
                    </li>
                  ))}
                </ul>
              </ErrorCard>
            </>
          )) || (
              <>
                {/* <InfoCard
                text="Your photo has been corrected and now complies with the standards of the German Passport 🎉"
                title="Congratulations!"
              /> */}
                <OfferCard checkState={[isChecked, setIsChecked]} />
              </>
            )}
        </div>
        <div onClick={onNext} >
          <BottomBar
            isShown={true}
            directionBack={apiErrors?.length ? true : false}
            inactivate={!isChecked && !apiErrors?.length}
            text={apiErrors?.length ? language.preview_retry_button : language.preview_purchase_button}
          />
        </div>
      </div>
    </>
  );
}
