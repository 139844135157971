import React from "react";
import { ArrowRight } from "./micro/icons";
import AnimatedClock from "./Clock";

type Props = {
  isShown?: boolean;
  text?: string;
  inactivate?: boolean;
  directionBack?: boolean;
};
export const BottomBar = (props: Props) => {
  let { text, isShown, inactivate, directionBack } = props;
  isShown = isShown || true;
  directionBack = directionBack || false;

  return (
    <div
      className={
        (inactivate ? "bg-mid " : "bg-primary ") +
        (isShown ? "" : "translate-y-24 ") +
        "transition-transform ease-out fixed bottom-0 left-0 flex justify-between w-full h-24 p-6 rounded-t-3xl items-center"
      }
    >
      <h1 className="text-2xl font-bold text-white">{text || "Next"}</h1>
      <span className={`${directionBack ? "rotate-180 " : ""} bg-white ${inactivate ? "text-mid" : "text-primary"} rounded-full w-12 h-12 flex items-center justify-center`}>
        <ArrowRight stroke={3} />
        {/* {inactivate && (
          <AnimatedClock />
        )} */}
      </span>
    </div>
  );
};
