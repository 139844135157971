import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import { CountryContext } from "../contexts/CountryContext";

type Props = {
    country: string;
};

export const CountrySelected = (props) => {
    const language = useContext(LanguageContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { country } = useContext(CountryContext);
    const mapCountryToMap = (country) => {
        switch (country) {
            case "uk":
                return "gb";
            default:
                return country;
        }
    };
    return (
        <>
            {country && (
                <div className="mb-8 flex flex-col items-center cursor-pointer">
                    <span className={"!w-12 h-12 fi fi-" + mapCountryToMap(country)}></span>
                    <a className="underline text-primary text-md" onClick={() => navigate("/countries")}>{language.country_options_choose_lang}</a>
                </div>
            )}
        </>
    )
}