import React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
};

export const ErrorCard = (props: Props) => {
  return (
    <>
      <div className="bg-secondary rounded-3xl fixed bottom-0 left-0 h-33vh w-full p-6">
        <div className="flex flex-row">
          <div className="flex flex-col">
            <div className="text-dark text-2xl font-semibold mb-3">
              {props.title}
            </div>
            <div className="text-mid">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
