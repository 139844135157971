export const translations = {
    "al": {
        "background_removal_error": "Gabim gjatë heqjes së sfondit",
        "common_subtitle": "Fotografitë e pasaportave/vizave mbarëbotërore",
        "countries_ad": "Andorra",
        "countries_ae": "Emiratet e Bashkuara Arabe",
        "countries_al": "Shqipëria",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbajxhani",
        "countries_ba": "Bosnje Hercegovina",
        "countries_be": "Belgjika",
        "countries_bg": "Bullgaria",
        "countries_br": "Brazili",
        "countries_ch": "Zvicra",
        "countries_cn": "Kinë",
        "countries_cy": "Qipron",
        "countries_cz": "Çekia",
        "countries_de": "Gjermania",
        "countries_dk": "Danimarka",
        "countries_ee": "Estonia",
        "countries_es": "Spanja",
        "countries_fi": "Finlanda",
        "countries_fr": "Franca",
        "countries_ge": "Gjeorgjia",
        "countries_gr": "Greqia",
        "countries_hr": "Kroacia",
        "countries_hu": "Hungaria",
        "countries_id": "Indonezia",
        "countries_ie": "Irlanda",
        "countries_il": "Izrael",
        "countries_in": "Indi",
        "countries_ir": "Irani",
        "countries_is": "Islanda",
        "countries_it": "Italia",
        "countries_jo": "Jordania",
        "countries_jp": "Japonia",
        "countries_kr": "Koreja",
        "countries_kz": "Kazakistani",
        "countries_lb": "Libani",
        "countries_li": "Lihtenshtajni",
        "countries_lt": "Lituania",
        "countries_lu": "Luksemburgu",
        "countries_lv": "Letonia",
        "countries_mc": "Monako",
        "countries_md": "Moldavia",
        "countries_me": "Mali i Zi",
        "countries_mk": "Maqedonia",
        "countries_mt": "Malta",
        "countries_my": "Malajzia",
        "countries_ng": "Nigeria",
        "countries_nl": "Holanda",
        "countries_no": "Norvegjia",
        "countries_ph": "Filipinet",
        "countries_pl": "Polonia",
        "countries_pt": "Portugalia",
        "countries_ro": "Rumenia",
        "countries_rs": "Serbisë",
        "countries_ru": "Rusia",
        "countries_sa": "Arabia Saudite",
        "countries_se": "Suedia",
        "countries_si": "Sllovenia",
        "countries_sk": "Sllovakia",
        "countries_sm": "San Marino",
        "countries_th": "Tajlandë",
        "countries_title": "Zgjidhni vendin tuaj",
        "countries_tr": "Turqia",
        "countries_ua": "Ukrainë",
        "countries_uk": "Mbretëria e Bashkuar",
        "countries_us": "SHBA",
        "countries_vn": "Vietnami",
        "countries_xk": "Kosova",
        "country_options_choose_lang": "Zgjidhni një vend tjetër",
        "country_options_gt10": "11 e lart",
        "country_options_gt5": "5 dhe mbi",
        "country_options_let10": "nën 11 vjet",
        "country_options_let5": "nën 5 vjeç",
        "country_options_oci": "OCI",
        "country_options_online": "Ngarkoni",
        "country_options_printed": "Shtypur",
        "country_options_title": "Zgjidhni llojin tuaj të fotografisë",
        "email_subject": "Fotot tuaja",
        "email_text": "Fotot tuaja ju dërgohen me email. Faleminderit që përdorni SelfIDeed!",
        "email_with_images": "Udhëzime për foto të pasaportës dixhitale",
        "face_alignment_error_description": "Fotografia juaj nuk mund të përpunohet nga AI pa shkaktuar probleme. Ju lutemi provoni përsëri të lëvizni më larg nga kamera.",
        "face_alignment_error_title": "Gabim në shtrirjen e fytyrës",
        "hints_description_1": "Mbajeni celularin në gjatësinë e krahut dhe në nivelin e syve...",
        "hints_description_2": "... drejt dritës së ditës.",
        "hints_description_3": "Fikni blicin.",
        "hints_next_button": "Vazhdoni",
        "hints_next_button_waiting": "Merrni parasysh rregullat ...",
        "hints_subtitle": "Udhëzimet",
        "hints_title": "Rregullat",
        "image_resolution_too_small_description": "Rezolucioni i imazhit është shumë i vogël për t'iu përshtatur kërkesave.",
        "image_resolution_too_small_title": "Rezolucioni i imazhit shumë i vogël",
        "landing_digital_description": "Përdoreni për të ngarkuar foton tuaj",
        "landing_digital_header": "Versioni dixhital",
        "landing_next_button": "Provo tani",
        "landing_passport_description": "Përdoreni për të printuar veten në letër fotografike 10 x 15 cm ...ose... lëreni të printojë në një shërbim printimi fotografish",
        "landing_passport_header": "Fotografitë e pasaportës",
        "landing_title": "Bëni fotografitë e pasaportës suaj në internet",
        "no_face_error": "Fytyra nuk mund të gjendej nga algoritmi ynë - ju lutemi provoni përsëri,",
        "preview_checked_photo": "Kontrollova foton time dhe është në rregull",
        "preview_error_title": "Kanë ndodhur gabime!",
        "preview_offer": "Ne do të dërgojmë me email direkt foton e pasaportës dixhitale, së bashku me fotot e pasaportës printoni për 4,95 Euro",
        "preview_purchase_button": "Blerje",
        "preview_retry_button": "Provo përsëri",
        "preview_tc_1": "Unë pajtohem me",
        "preview_tc_2": "Termat & Kushtet",
        "preview_tc_3": "dhe",
        "preview_tc_4": "Politika e privatësisë",
        "preview_title": "Kontrolloni foton tuaj",
        "processing_hint": "AI ynë po përpunon foton tuaj...",
        "processing_title": "Përpunimi",
        "result_download_button": "Shkarko",
        "result_download_text": "Fotot tuaja ju janë dërguar me email. Faleminderit që përdorni SelfIDeed!",
        "result_title": "Rezultati",
        "upload_button_text": "Ngarko foto",
        "upload_title": "Merrni foton tuaj"
    },
    "az": {
        "background_removal_error": "Fonun silinməsi zamanı xəta",
        "common_subtitle": "Ümumdünya Pasport/Viza Şəkilləri",
        "countries_ad": "Andorra",
        "countries_ae": "Birləşmiş Ərəb Əmirlikləri",
        "countries_al": "Albaniya",
        "countries_am": "Ermənistan",
        "countries_at": "Avstriya",
        "countries_au": "Avstraliya",
        "countries_az": "Azərbaycan",
        "countries_ba": "Bosniya Herseqovina",
        "countries_be": "Belçika",
        "countries_bg": "Bolqarıstan",
        "countries_br": "Braziliya",
        "countries_ch": "İsveçrə",
        "countries_cn": "Çin",
        "countries_cy": "Kipr",
        "countries_cz": "Çexiya",
        "countries_de": "Almaniya",
        "countries_dk": "Danimarka",
        "countries_ee": "Estoniya",
        "countries_es": "İspaniya",
        "countries_fi": "Finlandiya",
        "countries_fr": "Fransa",
        "countries_ge": "Gürcüstan",
        "countries_gr": "Yunanıstan",
        "countries_hr": "Xorvatiya",
        "countries_hu": "Macarıstan",
        "countries_id": "İndoneziya",
        "countries_ie": "İrlandiya",
        "countries_il": "İsrail",
        "countries_in": "Hindistan",
        "countries_ir": "İran",
        "countries_is": "İslandiya",
        "countries_it": "İtaliya",
        "countries_jo": "İordaniya",
        "countries_jp": "Yaponiya",
        "countries_kr": "Koreya",
        "countries_kz": "Qazaxıstan",
        "countries_lb": "Livan",
        "countries_li": "Lixtenşteyn",
        "countries_lt": "Litva",
        "countries_lu": "Lüksemburq",
        "countries_lv": "Latviya",
        "countries_mc": "Monako",
        "countries_md": "Moldova",
        "countries_me": "Monteneqro",
        "countries_mk": "Makedoniya",
        "countries_mt": "Malta",
        "countries_my": "Malayziya",
        "countries_ng": "Nigeriya",
        "countries_nl": "Hollandiya",
        "countries_no": "Norveç",
        "countries_ph": "Filippin",
        "countries_pl": "Polşa",
        "countries_pt": "Portuqaliya",
        "countries_ro": "Rumıniya",
        "countries_rs": "Serbiya",
        "countries_ru": "Rusiya",
        "countries_sa": "Səudiyyə Ərəbistanı",
        "countries_se": "İsveç",
        "countries_si": "Sloveniya",
        "countries_sk": "Slovakiya",
        "countries_sm": "San Marino",
        "countries_th": "Tayland",
        "countries_title": "Ölkənizi Seçin",
        "countries_tr": "Türkiyə",
        "countries_ua": "Ukrayna",
        "countries_uk": "Birləşmiş Krallıq",
        "countries_us": "ABŞ",
        "countries_vn": "Vyetnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Fərqli bir ölkə seçin",
        "country_options_gt10": "11 və yuxarı",
        "country_options_gt5": "5 yaşdan yuxarı.",
        "country_options_let10": "11 yaşa qədər",
        "country_options_let5": "5 yaşdan aşağı",
        "country_options_oci": "OCI",
        "country_options_online": "Yükləmək",
        "country_options_printed": "Çap edilib",
        "country_options_title": "Şəkil Növünüzü Seçin",
        "email_subject": "Sənin şəkillərin",
        "email_text": "Şəkilləriniz sizə e-poçt vasitəsilə göndərilir. SelfIDeed-dən istifadə etdiyiniz üçün təşəkkür edirik!",
        "email_with_images": "Rəqəmsal Pasport Şəkil Təlimatları",
        "face_alignment_error_description": "Şəkiliniz problem yaratmadan AI tərəfindən emal edilə bilməz. Lütfən, kameradan uzaqlaşmağa yenidən cəhd edin.",
        "face_alignment_error_title": "Üz Hizalanması Xətası",
        "hints_description_1": "Mobil telefonunuzu qol uzunluğunda və göz səviyyəsində saxlayın...",
        "hints_description_2": "...gün işığına doğru.",
        "hints_description_3": "Flaşı söndürün.",
        "hints_next_button": "Davam et",
        "hints_next_button_waiting": "Qaydalara fikir verin...",
        "hints_subtitle": "Təlimatlar",
        "hints_title": "Qaydalar",
        "image_resolution_too_small_description": "Şəkil həlli tələblərə uyğun gəlmək üçün çox kiçikdir.",
        "image_resolution_too_small_title": "Şəkil Çözünürlüğü Çox Kiçik",
        "landing_digital_description": "Şəkilinizi yükləmək üçün istifadə edin",
        "landing_digital_header": "Rəqəmsal versiya",
        "landing_next_button": "İndi cəhd edin",
        "landing_passport_description": "Özünüzü 10 x 15 sm foto kağızında çap etmək üçün istifadə edin ...və ya... foto çap xidmətində çap etməyə icazə verin",
        "landing_passport_header": "Pasport şəkilləri",
        "landing_title": "Pasport şəkillərinizi onlayn edin",
        "no_face_error": "Üz alqoritmimiz tərəfindən tapılmadı - lütfən, yenidən cəhd edin,",
        "preview_checked_photo": "Şəkilimi yoxladım və qaydasındadır",
        "preview_error_title": "Səhvlər baş verdi!",
        "preview_offer": "Biz 4,95 Avro dəyərində rəqəmsal pasport şəklini və özünüz çap olunmuş pasport fotoşəkillərini birbaşa e-poçtla göndərəcəyik.",
        "preview_purchase_button": "Alış",
        "preview_retry_button": "Yenidən cəhd elə",
        "preview_tc_1": "ilə razıyam",
        "preview_tc_2": "Şərtlər və qaydalar",
        "preview_tc_3": "və",
        "preview_tc_4": "Gizlilik Siyasəti",
        "preview_title": "Şəkilinizi yoxlayın",
        "processing_hint": "Süni intellektimiz şəklinizi emal edir...",
        "processing_title": "Emal edilir",
        "result_download_button": "Yüklə",
        "result_download_text": "Şəkilləriniz sizə e-poçt vasitəsilə göndərilib. SelfIDeed-dən istifadə etdiyiniz üçün təşəkkür edirik!",
        "result_title": "Nəticə",
        "upload_button_text": "Şəkil Yüklə",
        "upload_title": "Şəkilinizi çəkin"
    },
    "bg": {
        "background_removal_error": "Грешка при премахване на фона",
        "common_subtitle": "Снимки на паспорти/визи в цял свят",
        "countries_ad": "Андора",
        "countries_ae": "Обединени арабски емирства",
        "countries_al": "Албания",
        "countries_am": "Армения",
        "countries_at": "Австрия",
        "countries_au": "Австралия",
        "countries_az": "Азербайджан",
        "countries_ba": "Босна и Херцеговина",
        "countries_be": "Белгия",
        "countries_bg": "България",
        "countries_br": "Бразилия",
        "countries_ch": "Швейцария",
        "countries_cn": "Китай",
        "countries_cy": "Кипър",
        "countries_cz": "Чехия",
        "countries_de": "Германия",
        "countries_dk": "Дания",
        "countries_ee": "Естония",
        "countries_es": "Испания",
        "countries_fi": "Финландия",
        "countries_fr": "Франция",
        "countries_ge": "Грузия",
        "countries_gr": "Гърция",
        "countries_hr": "Хърватия",
        "countries_hu": "Унгария",
        "countries_id": "Индонезия",
        "countries_ie": "Ирландия",
        "countries_il": "Израел",
        "countries_in": "Индия",
        "countries_ir": "Иран",
        "countries_is": "Исландия",
        "countries_it": "Италия",
        "countries_jo": "Йордания",
        "countries_jp": "Япония",
        "countries_kr": "Корея",
        "countries_kz": "Казахстан",
        "countries_lb": "Ливан",
        "countries_li": "Лихтенщайн",
        "countries_lt": "Литва",
        "countries_lu": "Люксембург",
        "countries_lv": "Латвия",
        "countries_mc": "Монако",
        "countries_md": "Молдова",
        "countries_me": "Черна гора",
        "countries_mk": "Македония",
        "countries_mt": "Малта",
        "countries_my": "Малайзия",
        "countries_ng": "Нигерия",
        "countries_nl": "Холандия",
        "countries_no": "Норвегия",
        "countries_ph": "Филипините",
        "countries_pl": "Полша",
        "countries_pt": "Португалия",
        "countries_ro": "Румения",
        "countries_rs": "Сърбия",
        "countries_ru": "Русия",
        "countries_sa": "Саудитска Арабия",
        "countries_se": "Швеция",
        "countries_si": "Словения",
        "countries_sk": "Словакия",
        "countries_sm": "Сан Марино",
        "countries_th": "Тайланд",
        "countries_title": "Изберете вашата държава",
        "countries_tr": "Турция",
        "countries_ua": "Украйна",
        "countries_uk": "Великобритания",
        "countries_us": "САЩ",
        "countries_vn": "Виетнам",
        "countries_xk": "Косово",
        "country_options_choose_lang": "Изберете друга държава",
        "country_options_gt10": "11 и повече",
        "country_options_gt5": "5 и над",
        "country_options_let10": "под 11 години",
        "country_options_let5": "под 5 години",
        "country_options_oci": "OCI",
        "country_options_online": "Качване",
        "country_options_printed": "Отпечатано",
        "country_options_title": "Изберете вашия тип снимка",
        "email_subject": "Твоите снимки",
        "email_text": "Снимките ви се изпращат по имейл. Благодарим ви, че използвате SelfIDeed!",
        "email_with_images": "Инструкции за цифрова паспортна снимка",
        "face_alignment_error_description": "Вашата снимка не може да бъде обработена от AI, без да причини проблеми. Моля, опитайте отново да се отдалечите от камерата.",
        "face_alignment_error_title": "Грешка при подравняване на лицето",
        "hints_description_1": "Дръжте мобилния си телефон на една ръка разстояние и на нивото на очите...",
        "hints_description_2": "... към дневната светлина.",
        "hints_description_3": "Изключете светкавицата.",
        "hints_next_button": "продължи",
        "hints_next_button_waiting": "Помислете за правилата...",
        "hints_subtitle": "Инструкции",
        "hints_title": "правила",
        "image_resolution_too_small_description": "Разделителната способност на изображението е твърде малка, за да отговаря на изискванията.",
        "image_resolution_too_small_title": "Разделителната способност на изображението е твърде малка",
        "landing_digital_description": "Използвайте за качване на ваша снимка",
        "landing_digital_header": "Електронен вариант",
        "landing_next_button": "Опитайте сега",
        "landing_passport_description": "Използвайте, за да отпечатате себе си върху фотохартия 10 x 15 cm ...или... го оставете да отпечата в услуга за печат на снимки",
        "landing_passport_header": "Паспортни снимки",
        "landing_title": "Направете вашите паспортни снимки онлайн",
        "no_face_error": "Лицето не може да бъде намерено от нашия алгоритъм - моля, опитайте отново,",
        "preview_checked_photo": "Проверих снимката си и всичко е наред",
        "preview_error_title": "Възникнаха грешки!",
        "preview_offer": "Ние директно ще изпратим по имейл цифровата паспортна снимка, заедно с отпечатаните сами паспортни снимки за 4,95 евро",
        "preview_purchase_button": "Покупка",
        "preview_retry_button": "Опитай пак",
        "preview_tc_1": "Съгласен съм с",
        "preview_tc_2": "Правила и условия",
        "preview_tc_3": "и на",
        "preview_tc_4": "Политика за поверителност",
        "preview_title": "Проверете вашата снимка",
        "processing_hint": "Нашият AI обработва вашата снимка...",
        "processing_title": "Обработка",
        "result_download_button": "Изтегли",
        "result_download_text": "Вашите снимки са ви изпратени по имейл. Благодарим ви, че използвате SelfIDeed!",
        "result_title": "Резултат",
        "upload_button_text": "Качи снимка",
        "upload_title": "Направете снимката си"
    },
    "cn": {
        "background_removal_error": "背景刪除期間出錯",
        "common_subtitle": "全球護照/簽證照片",
        "countries_ad": "安道爾",
        "countries_ae": "阿拉伯联合酋长国",
        "countries_al": "阿爾巴尼亞",
        "countries_am": "亞美尼亞",
        "countries_at": "奧地利",
        "countries_au": "澳洲",
        "countries_az": "亞塞拜然",
        "countries_ba": "波士尼亞赫塞哥維納",
        "countries_be": "比利時",
        "countries_bg": "保加利亞",
        "countries_br": "巴西",
        "countries_ch": "瑞士",
        "countries_cn": "中國",
        "countries_cy": "賽普勒斯",
        "countries_cz": "捷克",
        "countries_de": "德國",
        "countries_dk": "丹麥",
        "countries_ee": "愛沙尼亞",
        "countries_es": "西班牙",
        "countries_fi": "芬蘭",
        "countries_fr": "法國",
        "countries_ge": "喬治亞州",
        "countries_gr": "希臘",
        "countries_hr": "克羅埃西亞",
        "countries_hu": "匈牙利",
        "countries_id": "印尼",
        "countries_ie": "愛爾蘭",
        "countries_il": "以色列",
        "countries_in": "印度",
        "countries_ir": "伊朗",
        "countries_is": "冰島",
        "countries_it": "義大利",
        "countries_jo": "约旦",
        "countries_jp": "日本",
        "countries_kr": "韓國",
        "countries_kz": "哈薩克",
        "countries_lb": "黎巴嫩",
        "countries_li": "利希滕斯坦",
        "countries_lt": "立陶宛",
        "countries_lu": "盧森堡",
        "countries_lv": "拉脫維亞",
        "countries_mc": "摩納哥",
        "countries_md": "摩爾多瓦",
        "countries_me": "蒙特內哥羅",
        "countries_mk": "馬其頓",
        "countries_mt": "馬爾他",
        "countries_my": "馬來西亞",
        "countries_ng": "奈及利亞",
        "countries_nl": "荷蘭",
        "countries_no": "挪威",
        "countries_ph": "菲律賓",
        "countries_pl": "波蘭",
        "countries_pt": "葡萄牙",
        "countries_ro": "羅馬尼亞",
        "countries_rs": "塞爾維亞",
        "countries_ru": "俄羅斯",
        "countries_sa": "沙烏地阿拉伯",
        "countries_se": "瑞典",
        "countries_si": "斯洛維尼亞",
        "countries_sk": "斯洛伐克",
        "countries_sm": "聖馬利諾",
        "countries_th": "泰國",
        "countries_title": "選擇你的國家",
        "countries_tr": "土耳其",
        "countries_ua": "烏克蘭",
        "countries_uk": "英國",
        "countries_us": "美國",
        "countries_vn": "越南",
        "countries_xk": "科索沃",
        "country_options_choose_lang": "選擇不同的國家",
        "country_options_gt10": "11歲以上",
        "country_options_gt5": "5岁及以上",
        "country_options_let10": "11歲以下",
        "country_options_let5": "5岁以下",
        "country_options_oci": "OCI",
        "country_options_online": "上傳",
        "country_options_printed": "列印",
        "country_options_title": "選擇您的照片類型",
        "email_subject": "你的照片",
        "email_text": "您的照片將透過電子郵件發送給您。 感謝您使用 SelfIDeed！",
        "email_with_images": "數位護照照片說明",
        "face_alignment_error_description": "您的照片無法透過 AI 處理而不引起問題。 請再次嘗試遠離相機。",
        "face_alignment_error_title": "臉部對齊錯誤",
        "hints_description_1": "將手機保持在與眼睛保持一定距離的位置...",
        "hints_description_2": "……天亮了。",
        "hints_description_3": "關閉閃光燈。",
        "hints_next_button": "繼續",
        "hints_next_button_waiting": "考慮規則...",
        "hints_subtitle": "指示",
        "hints_title": "規則",
        "image_resolution_too_small_description": "影像解析度太小，無法滿足要求。",
        "image_resolution_too_small_title": "影像解析度太小",
        "landing_digital_description": "用於上傳您的照片",
        "landing_digital_header": "數位版",
        "landing_next_button": "現在試試",
        "landing_passport_description": "用於在 10 x 15 公分相片紙上列印您自己的照片...或...讓它在照片列印服務處列印",
        "landing_passport_header": "護照照片",
        "landing_title": "在線製作您的護照照片",
        "no_face_error": "我們的演算法無法找到人臉 - 請重試，",
        "preview_checked_photo": "我檢查了我的照片，沒問題",
        "preview_error_title": "發生錯誤！",
        "preview_offer": "我們將直接透過電子郵件發送數位護照照片以及自行列印的護照照片，價格為 4.95 歐元",
        "preview_purchase_button": "購買",
        "preview_retry_button": "再試一次",
        "preview_tc_1": "我同意",
        "preview_tc_2": "條款及條件",
        "preview_tc_3": "和",
        "preview_tc_4": "隱私權政策",
        "preview_title": "檢查你的照片",
        "processing_hint": "我們的人工智慧正在處理您的照片...",
        "processing_title": "加工",
        "result_download_button": "下載",
        "result_download_text": "您的照片已透過電子郵件發送給您。 感謝您使用 SelfIDeed！",
        "result_title": "結果",
        "upload_button_text": "上傳圖片",
        "upload_title": "拍張照片"
    },
    "cs": {
        "background_removal_error": "Chyba při odstraňování pozadí",
        "common_subtitle": "Fotografie z celosvětových pasů/víz",
        "countries_ad": "Andorra",
        "countries_ae": "Spojené arabské emiráty",
        "countries_al": "Albánie",
        "countries_am": "Arménie",
        "countries_at": "Rakousko",
        "countries_au": "Austrálie",
        "countries_az": "Ázerbajdžán",
        "countries_ba": "Bosna a Hercegovina",
        "countries_be": "Belgie",
        "countries_bg": "Bulharsko",
        "countries_br": "Brazílie",
        "countries_ch": "Švýcarsko",
        "countries_cn": "Čína",
        "countries_cy": "Kypr",
        "countries_cz": "Česko",
        "countries_de": "Německo",
        "countries_dk": "Dánsko",
        "countries_ee": "Estonsko",
        "countries_es": "Španělsko",
        "countries_fi": "Finsko",
        "countries_fr": "Francie",
        "countries_ge": "Gruzie",
        "countries_gr": "Řecko",
        "countries_hr": "Chorvatsko",
        "countries_hu": "Maďarsko",
        "countries_id": "Indonésie",
        "countries_ie": "Irsko",
        "countries_il": "Izrael",
        "countries_in": "Indie",
        "countries_ir": "Írán",
        "countries_is": "Island",
        "countries_it": "Itálie",
        "countries_jo": "Jordánsko",
        "countries_jp": "Japonsko",
        "countries_kr": "Korea",
        "countries_kz": "Kazachstán",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenštejnsko",
        "countries_lt": "Litva",
        "countries_lu": "Lucembursko",
        "countries_lv": "Lotyšsko",
        "countries_mc": "Monako",
        "countries_md": "Moldavsko",
        "countries_me": "Černá Hora",
        "countries_mk": "Makedonie",
        "countries_mt": "Malta",
        "countries_my": "Malajsie",
        "countries_ng": "Nigérie",
        "countries_nl": "Holandsko",
        "countries_no": "Norsko",
        "countries_ph": "Filipíny",
        "countries_pl": "Polsko",
        "countries_pt": "Portugalsko",
        "countries_ro": "Romenia",
        "countries_rs": "Srbsko",
        "countries_ru": "Rusko",
        "countries_sa": "Saudská arábie",
        "countries_se": "Švédsko",
        "countries_si": "Slovinsko",
        "countries_sk": "Slovensko",
        "countries_sm": "San Marino",
        "countries_th": "Thajsko",
        "countries_title": "Vyberte svoji zemi",
        "countries_tr": "Turecko",
        "countries_ua": "Ukrajina",
        "countries_uk": "Spojené království",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Vyberte jinou zemi",
        "country_options_gt10": "11 a více",
        "country_options_gt5": "5 a více",
        "country_options_let10": "do 11 let",
        "country_options_let5": "do 5 let",
        "country_options_oci": "OCI",
        "country_options_online": "nahrát",
        "country_options_printed": "Tištěno",
        "country_options_title": "Vyberte typ fotografie",
        "email_subject": "Tvé fotky",
        "email_text": "Vaše fotografie vám budou zaslány e-mailem. Děkujeme, že používáte SelfIDeed!",
        "email_with_images": "Digitální pasové fotografické pokyny",
        "face_alignment_error_description": "Vaši fotku nemůže umělá inteligence zpracovat, aniž by to způsobilo problémy. Zkuste se znovu přesunout dále od fotoaparátu.",
        "face_alignment_error_title": "Chyba zarovnání obličeje",
        "hints_description_1": "Držte mobil na délku paže a v úrovni očí...",
        "hints_description_2": "...na denní světlo.",
        "hints_description_3": "Vypněte blesk.",
        "hints_next_button": "Pokračovat",
        "hints_next_button_waiting": "Zvažte pravidla...",
        "hints_subtitle": "Instrukce",
        "hints_title": "Pravidla",
        "image_resolution_too_small_description": "Rozlišení obrazu je příliš malé na to, aby vyhovovalo požadavkům.",
        "image_resolution_too_small_title": "Rozlišení obrázku je příliš malé",
        "landing_digital_description": "Použijte pro nahrání vaší fotografie",
        "landing_digital_header": "Digitální verze",
        "landing_next_button": "Vyzkoušet",
        "landing_passport_description": "Použijte k tisku na fotografický papír 10 x 15 cm ...nebo... nechte jej vytisknout ve službě pro tisk fotografií",
        "landing_passport_header": "Fotografie z pasu",
        "landing_title": "Vytvořte si pasové fotografie online",
        "no_face_error": "Náš algoritmus nenašel obličej – zkuste to prosím znovu,",
        "preview_checked_photo": "Zkontroloval jsem svou fotku a je v pořádku",
        "preview_error_title": "Vyskytly se chyby!",
        "preview_offer": "Digitální pasovou fotografii vám zašleme přímo e-mailem spolu s vytištěnými pasovými fotografiemi za 4,95 EUR",
        "preview_purchase_button": "Nákup",
        "preview_retry_button": "Zkus to znovu",
        "preview_tc_1": "souhlasím s",
        "preview_tc_2": "Všeobecné obchodní podmínky",
        "preview_tc_3": "a",
        "preview_tc_4": "Zásady ochrany osobních údajů",
        "preview_title": "Zkontrolujte svou fotografii",
        "processing_hint": "Naše AI zpracovává vaši fotografii...",
        "processing_title": "zpracovává se",
        "result_download_button": "Stažení",
        "result_download_text": "Vaše fotografie vám byly zaslány e-mailem. Děkujeme, že používáte SelfIDeed!",
        "result_title": "Výsledek",
        "upload_button_text": "Nahrát fotku",
        "upload_title": "Vyfoťte se"
    },
    "de": {
        "background_removal_error": "Fehler beim Entfernen des Hintergrunds",
        "common_subtitle": "Weltweite Pass-/Visafotos",
        "countries_ad": "Andorra",
        "countries_ae": "Vereinigte Arabische Emirate",
        "countries_al": "Albanien",
        "countries_am": "Armenien",
        "countries_at": "Österreich",
        "countries_au": "Australien",
        "countries_az": "Aserbaidschan",
        "countries_ba": "Bosnien-Herzegowina",
        "countries_be": "Belgien",
        "countries_bg": "Bulgarien",
        "countries_br": "Brasilien",
        "countries_ch": "Schweiz",
        "countries_cn": "China",
        "countries_cy": "Zypern",
        "countries_cz": "Tschechien",
        "countries_de": "Deutschland",
        "countries_dk": "Dänemark",
        "countries_ee": "Estland",
        "countries_es": "Spanien",
        "countries_fi": "Finnland",
        "countries_fr": "Frankreich",
        "countries_ge": "Georgia",
        "countries_gr": "Griechenland",
        "countries_hr": "Kroatien",
        "countries_hu": "Ungarn",
        "countries_id": "Indonesien",
        "countries_ie": "Irland",
        "countries_il": "Israel",
        "countries_in": "Indien",
        "countries_ir": "Iran",
        "countries_is": "Island",
        "countries_it": "Italien",
        "countries_jo": "Jordanien",
        "countries_jp": "Japan",
        "countries_kr": "Korea",
        "countries_kz": "Kasachstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litauen",
        "countries_lu": "Luxemburg",
        "countries_lv": "Lettland",
        "countries_mc": "Monaco",
        "countries_md": "Moldawien",
        "countries_me": "Montenegro",
        "countries_mk": "Mazedonien",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Niederlande",
        "countries_no": "Norwegen",
        "countries_ph": "Philippinen",
        "countries_pl": "Polen",
        "countries_pt": "Portugal",
        "countries_ro": "Rumänien",
        "countries_rs": "Serbien",
        "countries_ru": "Russland",
        "countries_sa": "Saudi-Arabien",
        "countries_se": "Schweden",
        "countries_si": "Slowenien",
        "countries_sk": "Slowakei",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Wähle dein Land",
        "countries_tr": "Türkei",
        "countries_ua": "Ukraine",
        "countries_uk": "Großbritannien",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Wählen Sie ein anderes Land",
        "country_options_gt10": "11 und älter",
        "country_options_gt5": "5 und älter",
        "country_options_let10": "unter 11 Jahren",
        "country_options_let5": "unter 5 Jahren",
        "country_options_oci": "OCI",
        "country_options_online": "Hochladen",
        "country_options_printed": "Gedruckt",
        "country_options_title": "Wählen Sie Ihren Fototyp",
        "email_subject": "Deine Fotos",
        "email_text": "Ihre Fotos werden Ihnen per E-Mail zugesandt. Vielen Dank, dass Sie SelfIDeed nutzen!",
        "email_with_images": "Anleitung zum digitalen Passfoto",
        "face_alignment_error_description": "Ihr Foto kann von der KI nicht ohne Probleme verarbeitet werden. Bitte versuchen Sie noch einmal, sich weiter von der Kamera zu entfernen.",
        "face_alignment_error_title": "Fehler bei der Gesichtsausrichtung",
        "hints_description_1": "Halten Sie Ihr Handy auf Armeslänge und Augenhöhe...“",
        "hints_description_2": "...dem Tageslicht entgegen.",
        "hints_description_3": "Schalten Sie den Blitz aus.",
        "hints_next_button": "Weitermachen",
        "hints_next_button_waiting": "Beachten Sie die Regeln ...",
        "hints_subtitle": "Anweisungen",
        "hints_title": "Regeln",
        "image_resolution_too_small_description": "Die Bildauflösung ist zu gering, um den Anforderungen gerecht zu werden.",
        "image_resolution_too_small_title": "Bildauflösung zu klein",
        "landing_digital_description": "Zum Hochladen Ihres Fotos verwenden",
        "landing_digital_header": "Digitale Version",
        "landing_next_button": "Versuch's jetzt",
        "landing_passport_description": "Verwenden Sie es, um es selbst auf Fotopapier im Format 10 x 15 cm auszudrucken ... oder ... lassen Sie es bei einem Fotodruckdienst ausdrucken",
        "landing_passport_header": "Passfotos",
        "landing_title": "Machen Sie Ihre Passfotos online",
        "no_face_error": "Das Gesicht konnte von unserem Algorithmus nicht gefunden werden. Bitte versuchen Sie es erneut.",
        "preview_checked_photo": "Ich habe mein Foto überprüft und es ist in Ordnung",
        "preview_error_title": "Es sind Fehler aufgetreten!",
        "preview_offer": "Wir versenden das digitale Passfoto direkt per E-Mail, zusammen mit den selbst ausgedruckten Passfotos für 4,95 Euro",
        "preview_purchase_button": "Kaufen",
        "preview_retry_button": "Versuchen Sie es erneut",
        "preview_tc_1": "Ich stimme den",
        "preview_tc_2": "Nutzungsbedingungen",
        "preview_tc_3": "und der",
        "preview_tc_4": "Datenschutzrichtlinie zu",
        "preview_title": "Überprüfen Sie Ihr Foto",
        "processing_hint": "Unsere KI verarbeitet Ihr Foto...",
        "processing_title": "wird bearbeitet",
        "result_download_button": "Herunterladen",
        "result_download_text": "Ihre Fotos wurden Ihnen per E-Mail zugesandt. Vielen Dank, dass Sie SelfIDeed nutzen!",
        "result_title": "Ergebnis",
        "upload_button_text": "Foto hochladen",
        "upload_title": "Machen Sie Ihr Foto"
    },
    "dk": {
        "background_removal_error": "Fejl under fjernelse af baggrunden",
        "common_subtitle": "Verdensomspændende pas/visumbilleder",
        "countries_ad": "Andorra",
        "countries_ae": "Forenede Arabiske Emirater",
        "countries_al": "Albanien",
        "countries_am": "Armenien",
        "countries_at": "Østrig",
        "countries_au": "Australien",
        "countries_az": "Aserbajdsjan",
        "countries_ba": "Bosnien Hercegovina",
        "countries_be": "Belgien",
        "countries_bg": "Bulgarien",
        "countries_br": "Brasilien",
        "countries_ch": "Schweiz",
        "countries_cn": "Kina",
        "countries_cy": "Cypern",
        "countries_cz": "Tjekkiet",
        "countries_de": "Tyskland",
        "countries_dk": "Danmark",
        "countries_ee": "Estland",
        "countries_es": "Spanien",
        "countries_fi": "Finland",
        "countries_fr": "Frankrig",
        "countries_ge": "Georgien",
        "countries_gr": "Grækenland",
        "countries_hr": "Kroatien",
        "countries_hu": "Ungarn",
        "countries_id": "Indonesien",
        "countries_ie": "Irland",
        "countries_il": "Israel",
        "countries_in": "Indien",
        "countries_ir": "Iran",
        "countries_is": "Island",
        "countries_it": "Italien",
        "countries_jo": "Jordan",
        "countries_jp": "Japan",
        "countries_kr": "Korea",
        "countries_kz": "Kazachstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litauen",
        "countries_lu": "Luxembourg",
        "countries_lv": "Letland",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Makedonien",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Holland",
        "countries_no": "Norge",
        "countries_ph": "Filippinerne",
        "countries_pl": "Polen",
        "countries_pt": "Portugal",
        "countries_ro": "Rumænien",
        "countries_rs": "Serbien",
        "countries_ru": "Rusland",
        "countries_sa": "Saudi Arabien",
        "countries_se": "Sverige",
        "countries_si": "Slovenien",
        "countries_sk": "Slovakiet",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Vælg dit land",
        "countries_tr": "Tyrkiet",
        "countries_ua": "Ukraine",
        "countries_uk": "Det Forenede Kongerige",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Vælg et andet land",
        "country_options_gt10": "11 og derover",
        "country_options_gt5": "5 og ældre",
        "country_options_let10": "under 11 år",
        "country_options_let5": "under 5 år",
        "country_options_oci": "OCI",
        "country_options_online": "Upload",
        "country_options_printed": "Trykt",
        "country_options_title": "Vælg din fototype",
        "email_subject": "Dine billeder",
        "email_text": "Dine billeder sendes til dig via e-mail. Tak fordi du bruger SelfIDeed!",
        "email_with_images": "Digitalt pasfoto instruktioner",
        "face_alignment_error_description": "Dit billede kan ikke behandles af AI uden at forårsage problemer. Prøv igen at flytte længere væk fra kameraet.",
        "face_alignment_error_title": "Ansigtsjusteringsfejl",
        "hints_description_1": "Hold din mobil i armslængde og øjenhøjde...",
        "hints_description_2": "...mod dagslys.",
        "hints_description_3": "Sluk blitzen.",
        "hints_next_button": "Blive ved",
        "hints_next_button_waiting": "Overvej reglerne...",
        "hints_subtitle": "Instruktioner",
        "hints_title": "Regler",
        "image_resolution_too_small_description": "Billedopløsningen er for lille til at opfylde kravene.",
        "image_resolution_too_small_title": "Billedopløsning for lille",
        "landing_digital_description": "Bruges til at uploade dit billede",
        "landing_digital_header": "Digital version",
        "landing_next_button": "Prøv nu",
        "landing_passport_description": "Brug til at printe dig selv på 10 x 15 cm fotopapir ... eller... lad det printe hos en fotoprintservice",
        "landing_passport_header": "Pasbilleder",
        "landing_title": "Lav dine pasbilleder online",
        "no_face_error": "Ansigt kunne ikke findes af vores algoritme - prøv venligst igen,",
        "preview_checked_photo": "Jeg tjekkede mit billede, og det er ok",
        "preview_error_title": "Der opstod fejl!",
        "preview_offer": "Vi sender det digitale pasfoto direkte via e-mail sammen med print-selv-pasbillederne for 4,95 Euro",
        "preview_purchase_button": "Køb",
        "preview_retry_button": "Prøv igen",
        "preview_tc_1": "Jeg er enig i",
        "preview_tc_2": "Vilkår og Betingelser",
        "preview_tc_3": "og",
        "preview_tc_4": "Fortrolighedspolitik",
        "preview_title": "Tjek dit foto",
        "processing_hint": "Vores AI behandler dit billede...",
        "processing_title": "Forarbejdning",
        "result_download_button": "Hent",
        "result_download_text": "Dine billeder er blevet sendt til dig via e-mail. Tak fordi du bruger SelfIDeed!",
        "result_title": "Resultat",
        "upload_button_text": "Upload billede",
        "upload_title": "Tag dit billede"
    },
    "en": {
        "background_removal_error": "Error during background removal",
        "common_subtitle": "Worldwide Passport/Visa Photos",
        "countries_ad": "Andorra",
        "countries_ae": "United Arab Emirates",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbaijan",
        "countries_ba": "Bosnia Herzegovina",
        "countries_be": "Belgium",
        "countries_bg": "Bulgaria",
        "countries_br": "Brazil",
        "countries_ch": "Switzerland",
        "countries_cn": "China",
        "countries_cy": "Cyprus",
        "countries_cz": "Czechia",
        "countries_de": "Germany",
        "countries_dk": "Denmark",
        "countries_ee": "Estonia",
        "countries_es": "Spain",
        "countries_fi": "Finland",
        "countries_fr": "France",
        "countries_ge": "Georgia",
        "countries_gr": "Greece",
        "countries_hr": "Croatia",
        "countries_hu": "Hungary",
        "countries_id": "Indonesia",
        "countries_ie": "Ireland",
        "countries_il": "Israel",
        "countries_in": "India",
        "countries_ir": "Iran",
        "countries_is": "Iceland",
        "countries_it": "Italy",
        "countries_jo": "Jordan",
        "countries_jp": "Japan",
        "countries_kr": "Korea",
        "countries_kz": "Kazachstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Lithuania",
        "countries_lu": "Luxembourg",
        "countries_lv": "Latvia",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Netherlands",
        "countries_no": "Norway",
        "countries_ph": "Philippines",
        "countries_pl": "Poland",
        "countries_pt": "Portugal",
        "countries_ro": "Romenia",
        "countries_rs": "Serbia",
        "countries_ru": "Russia",
        "countries_sa": "Saudi Arabia",
        "countries_se": "Sweden",
        "countries_si": "Slovenia",
        "countries_sk": "Slovakia",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Choose Your Country",
        "countries_tr": "Turkey",
        "countries_ua": "Ukraine",
        "countries_uk": "United Kingdom",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Choose a different country",
        "country_options_gt10": "11 and over",
        "country_options_gt5": "5 and over",
        "country_options_let10": "under 11 years",
        "country_options_let5": "under 5 years",
        "country_options_oci": "OCI",
        "country_options_online": "Upload",
        "country_options_printed": "Printed",
        "country_options_title": "Choose Your Photo Type",
        "email_subject": "Your Photos",
        "email_text": "Your photos are sent to you by email. Thank you for using SelfIDeed!",
        "email_with_images": "Digital Passport Photo Instructions",
        "face_alignment_error_description": "Your photo can not be processed by AI without causing issues. Please try again moving further from the camera.",
        "face_alignment_error_title": "Face Alignment Error",
        "hints_description_1": "Hold your mobile at arm's length & eye level...\n\n",
        "hints_description_2": "...towards daylight.",
        "hints_description_3": "Turn off flash.",
        "hints_next_button": "Continue",
        "hints_next_button_waiting": "Consider the rules...",
        "hints_subtitle": "Instructions",
        "hints_title": "Rules",
        "image_resolution_too_small_description": "The image resolution is too small to fit the requirements.",
        "image_resolution_too_small_title": "Image Resolution Too Small",
        "landing_digital_description": "Use for uploading your photo",
        "landing_digital_header": "Digital Version",
        "landing_next_button": "Try Now",
        "landing_passport_description": "Use to print yourself on 10 x 15 cm photo paper ...or... let it print at a photo print service",
        "landing_passport_header": "Passport Photos",
        "landing_title": "Make Your Passport Photos Online",
        "no_face_error": "Face could not be found by our algorithm - please try again,",
        "preview_checked_photo": "I checked my photo and it's OK",
        "preview_error_title": "Errors Occured!",
        "preview_offer": "We will directly email the digital passport photo, together with the print-yourself passport photos for 4,95 Euro",
        "preview_purchase_button": "Purchase",
        "preview_retry_button": "Try again",
        "preview_tc_1": "I aggree to the",
        "preview_tc_2": "Terms & Conditions",
        "preview_tc_3": "and the",
        "preview_tc_4": "Privacy Policy",
        "preview_title": "Check Your Photo",
        "processing_hint": "Our AI is processing your photo...",
        "processing_title": "Processing",
        "result_download_button": "Download",
        "result_download_text": "Your photos have been sent to you by email. Thank you for using SelfIDeed!",
        "result_title": "Result",
        "upload_button_text": "Upload Photo",
        "upload_title": "Take Your Photo"
    },
    "es": {
        "background_removal_error": "Error durante la eliminación del fondo",
        "common_subtitle": "Fotos de pasaportes/visas internacionales",
        "countries_ad": "Andorra",
        "countries_ae": "Emiratos Árabes Unidos",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbaiyán",
        "countries_ba": "bosniaherzegovina",
        "countries_be": "Bélgica",
        "countries_bg": "Bulgaria",
        "countries_br": "Brasil",
        "countries_ch": "Suiza",
        "countries_cn": "Porcelana",
        "countries_cy": "Chipre",
        "countries_cz": "Chequia",
        "countries_de": "Alemania",
        "countries_dk": "Dinamarca",
        "countries_ee": "Estonia",
        "countries_es": "España",
        "countries_fi": "Finlandia",
        "countries_fr": "Francia",
        "countries_ge": "Georgia",
        "countries_gr": "Grecia",
        "countries_hr": "Croacia",
        "countries_hu": "Hungría",
        "countries_id": "Indonesia",
        "countries_ie": "Irlanda",
        "countries_il": "Israel",
        "countries_in": "India",
        "countries_ir": "Irán",
        "countries_is": "Islandia",
        "countries_it": "Italia",
        "countries_jo": "Jordania",
        "countries_jp": "Japón",
        "countries_kr": "Corea",
        "countries_kz": "Kazajstán",
        "countries_lb": "Líbano",
        "countries_li": "Liechtenstein",
        "countries_lt": "Lituania",
        "countries_lu": "Luxemburgo",
        "countries_lv": "Letonia",
        "countries_mc": "Mónaco",
        "countries_md": "Moldavia",
        "countries_me": "Montenegro",
        "countries_mk": "macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malasia",
        "countries_ng": "Nigeria",
        "countries_nl": "Países Bajos",
        "countries_no": "Noruega",
        "countries_ph": "Filipinas",
        "countries_pl": "Polonia",
        "countries_pt": "Portugal",
        "countries_ro": "rumania",
        "countries_rs": "Serbia",
        "countries_ru": "Rusia",
        "countries_sa": "Arabia Saudita",
        "countries_se": "Suecia",
        "countries_si": "Eslovenia",
        "countries_sk": "Eslovaquia",
        "countries_sm": "San Marino",
        "countries_th": "Tailandia",
        "countries_title": "Elige tu país",
        "countries_tr": "Turquía",
        "countries_ua": "Ucrania",
        "countries_uk": "Reino Unido",
        "countries_us": "EE.UU",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Elige un país diferente",
        "country_options_gt10": "11 y más",
        "country_options_gt5": "5 y más",
        "country_options_let10": "menores de 11 años",
        "country_options_let5": "menores de 5 años",
        "country_options_oci": "OCI",
        "country_options_online": "Subir",
        "country_options_printed": "Impreso",
        "country_options_title": "Elige tu tipo de foto",
        "email_subject": "Tus fotos",
        "email_text": "Tus fotos te las enviamos por correo electrónico. ¡Gracias por utilizar SelfIDeed!",
        "email_with_images": "Instrucciones para la fotografía de pasaporte digital",
        "face_alignment_error_description": "La IA no puede procesar su foto sin causar problemas. Inténtalo de nuevo alejándote de la cámara.",
        "face_alignment_error_title": "Error de alineación facial",
        "hints_description_1": "Mantén tu móvil con el brazo extendido y a la altura de los ojos...",
        "hints_description_2": "...hacia la luz del día.",
        "hints_description_3": "Apague el flash.",
        "hints_next_button": "Continuar",
        "hints_next_button_waiting": "Considere las reglas...",
        "hints_subtitle": "Instrucciones",
        "hints_title": "Normas",
        "image_resolution_too_small_description": "La resolución de la imagen es demasiado pequeña para cumplir los requisitos.",
        "image_resolution_too_small_title": "Resolución de imagen demasiado pequeña",
        "landing_digital_description": "Úselo para cargar su foto",
        "landing_digital_header": "Versión digital",
        "landing_next_button": "Probar ahora",
        "landing_passport_description": "Úselo para imprimir usted mismo en papel fotográfico de 10 x 15 cm... o... déjelo imprimir en un servicio de impresión fotográfica.",
        "landing_passport_header": "Fotos de pasaporte",
        "landing_title": "Haga sus fotos de pasaporte en línea",
        "no_face_error": "Nuestro algoritmo no pudo encontrar la cara; inténtelo de nuevo.",
        "preview_checked_photo": "Revisé mi foto y está bien.",
        "preview_error_title": "¡Ocurrieron errores!",
        "preview_offer": "Le enviaremos directamente por correo electrónico la foto del pasaporte digital, junto con las fotografías del pasaporte que podrá imprimir usted mismo por 4,95 euros.",
        "preview_purchase_button": "Compra",
        "preview_retry_button": "Intentar otra vez",
        "preview_tc_1": "Estoy de acuerdo con el",
        "preview_tc_2": "Términos y condiciones",
        "preview_tc_3": "y el",
        "preview_tc_4": "política de privacidad",
        "preview_title": "Revisa tu foto",
        "processing_hint": "Nuestra IA está procesando tu foto...",
        "processing_title": "Procesando",
        "result_download_button": "Descargar",
        "result_download_text": "Tus fotos te han sido enviadas por correo electrónico. ¡Gracias por utilizar SelfIDeed!",
        "result_title": "Resultado",
        "upload_button_text": "Subir foto",
        "upload_title": "Toma tu foto"
    },
    "fi": {
        "background_removal_error": "Virhe taustan poiston aikana",
        "common_subtitle": "Maailmanlaajuiset passi-/viisumikuvat",
        "countries_ad": "Andorra",
        "countries_ae": "Arabiemiirikunnat",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Itävalta",
        "countries_au": "Australia",
        "countries_az": "Azerbaidžan",
        "countries_ba": "Bosnia Hertsegovina",
        "countries_be": "Belgia",
        "countries_bg": "Bulgaria",
        "countries_br": "Brasilia",
        "countries_ch": "Sveitsi",
        "countries_cn": "Kiina",
        "countries_cy": "Kypros",
        "countries_cz": "Tšekki",
        "countries_de": "Saksa",
        "countries_dk": "Tanska",
        "countries_ee": "Viro",
        "countries_es": "Espanja",
        "countries_fi": "Suomi",
        "countries_fr": "Ranska",
        "countries_ge": "Georgia",
        "countries_gr": "Kreikka",
        "countries_hr": "Kroatia",
        "countries_hu": "Unkari",
        "countries_id": "Indonesia",
        "countries_ie": "Irlanti",
        "countries_il": "Israel",
        "countries_in": "Intia",
        "countries_ir": "Iran",
        "countries_is": "Islanti",
        "countries_it": "Italia",
        "countries_jo": "Jordania",
        "countries_jp": "Japani",
        "countries_kr": "Korea",
        "countries_kz": "Kazakstan",
        "countries_lb": "Libanon",
        "countries_li": "Liechtenstein",
        "countries_lt": "Liettua",
        "countries_lu": "Luxemburg",
        "countries_lv": "Latvia",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Makedonia",
        "countries_mt": "Malta",
        "countries_my": "Malesia",
        "countries_ng": "Nigeria",
        "countries_nl": "Alankomaat",
        "countries_no": "Norja",
        "countries_ph": "Filippiinit",
        "countries_pl": "Puola",
        "countries_pt": "Portugali",
        "countries_ro": "Romenia",
        "countries_rs": "Serbia",
        "countries_ru": "Venäjä",
        "countries_sa": "Saudi-Arabia",
        "countries_se": "Ruotsi",
        "countries_si": "Slovenia",
        "countries_sk": "Slovakia",
        "countries_sm": "San Marino",
        "countries_th": "Thaimaa",
        "countries_title": "Valitse maasi",
        "countries_tr": "Turkki",
        "countries_ua": "Ukraina",
        "countries_uk": "Yhdistynyt kuningaskunta",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Valitse toinen maa",
        "country_options_gt10": "11 ja yli",
        "country_options_gt5": "5 vuotta ja yli",
        "country_options_let10": "alle 11 vuotta",
        "country_options_let5": "alle 5-vuotiaat",
        "country_options_oci": "OCI",
        "country_options_online": "Lataa",
        "country_options_printed": "Painettu",
        "country_options_title": "Valitse valokuvatyyppisi",
        "email_subject": "Valokuvasi",
        "email_text": "Kuvasi lähetetään sinulle sähköpostitse. Kiitos, että käytät SelfIDeediä!",
        "email_with_images": "Digitaalisen passin valokuvaohjeet",
        "face_alignment_error_description": "Tekoäly ei voi käsitellä valokuvaasi ilman ongelmia. Yritä uudelleen siirtyä kauemmas kamerasta.",
        "face_alignment_error_title": "Kasvojen kohdistusvirhe",
        "hints_description_1": "Pidä kännykkääsi käsivarren ja silmien korkeudella...",
        "hints_description_2": "... kohti päivänvaloa.",
        "hints_description_3": "Sammuta salama.",
        "hints_next_button": "Jatkaa",
        "hints_next_button_waiting": "Mieti säännöt...",
        "hints_subtitle": "Ohjeet",
        "hints_title": "säännöt",
        "image_resolution_too_small_description": "Kuvan resoluutio on liian pieni vastaamaan vaatimuksia.",
        "image_resolution_too_small_title": "Kuvan tarkkuus liian pieni",
        "landing_digital_description": "Käytä valokuvasi lataamiseen",
        "landing_digital_header": "Digitaalinen versio",
        "landing_next_button": "Kokeile nyt",
        "landing_passport_description": "Käytä tulostaaksesi itsesi 10 x 15 cm valokuvapaperille ...tai... anna sen tulostaa valokuvatulostuspalvelussa",
        "landing_passport_header": "Passikuvat",
        "landing_title": "Tee passikuvasi verkossa",
        "no_face_error": "Algoritmimme ei löytänyt kasvoja - yritä uudelleen,",
        "preview_checked_photo": "Tarkistin kuvani ja se oli kunnossa",
        "preview_error_title": "Virheitä tapahtui!",
        "preview_offer": "Lähetämme digitaalisen passikuvan suoraan sähköpostitse yhdessä tulostettujen passikuvien kanssa hintaan 4,95 euroa",
        "preview_purchase_button": "Ostaa",
        "preview_retry_button": "Yritä uudelleen",
        "preview_tc_1": "Olen samaa mieltä",
        "preview_tc_2": "käyttöehdot",
        "preview_tc_3": "ja",
        "preview_tc_4": "Tietosuojakäytäntö",
        "preview_title": "Tarkista valokuvasi",
        "processing_hint": "Tekoälymme käsittelee valokuvaasi...",
        "processing_title": "Käsittely",
        "result_download_button": "ladata",
        "result_download_text": "Kuvasi on lähetetty sinulle sähköpostitse. Kiitos, että käytät SelfIDeediä!",
        "result_title": "Tulos",
        "upload_button_text": "Lataa valokuva",
        "upload_title": "Ota valokuvasi"
    },
    "fr": {
        "background_removal_error": "Erreur lors de la suppression de l'arrière-plan",
        "common_subtitle": "Photos de passeport/visa dans le monde entier",
        "countries_ad": "Andorre",
        "countries_ae": "Émirats arabes unis",
        "countries_al": "Albanie",
        "countries_am": "Arménie",
        "countries_at": "L'Autriche",
        "countries_au": "Australie",
        "countries_az": "Azerbaïdjan",
        "countries_ba": "Bosnie-Herzégovine",
        "countries_be": "Belgique",
        "countries_bg": "Bulgarie",
        "countries_br": "Brésil",
        "countries_ch": "Suisse",
        "countries_cn": "Chine",
        "countries_cy": "Chypre",
        "countries_cz": "Tchéquie",
        "countries_de": "Allemagne",
        "countries_dk": "Danemark",
        "countries_ee": "Estonie",
        "countries_es": "Espagne",
        "countries_fi": "Finlande",
        "countries_fr": "France",
        "countries_ge": "Géorgie",
        "countries_gr": "Grèce",
        "countries_hr": "Croatie",
        "countries_hu": "Hongrie",
        "countries_id": "Indonésie",
        "countries_ie": "Irlande",
        "countries_il": "Israël",
        "countries_in": "Inde",
        "countries_ir": "L'Iran",
        "countries_is": "Islande",
        "countries_it": "Italie",
        "countries_jo": "Jordanie",
        "countries_jp": "Japon",
        "countries_kr": "Corée",
        "countries_kz": "Kazakhstan",
        "countries_lb": "Liban",
        "countries_li": "Liechtenstein",
        "countries_lt": "Lituanie",
        "countries_lu": "Luxembourg",
        "countries_lv": "Lettonie",
        "countries_mc": "Monaco",
        "countries_md": "Moldavie",
        "countries_me": "Monténégro",
        "countries_mk": "Macédoine",
        "countries_mt": "Malte",
        "countries_my": "Malaisie",
        "countries_ng": "Nigeria",
        "countries_nl": "Pays-Bas",
        "countries_no": "Norvège",
        "countries_ph": "Philippines",
        "countries_pl": "Pologne",
        "countries_pt": "le Portugal",
        "countries_ro": "Roménie",
        "countries_rs": "Serbie",
        "countries_ru": "Russie",
        "countries_sa": "Arabie Saoudite",
        "countries_se": "Suède",
        "countries_si": "Slovénie",
        "countries_sk": "Slovaquie",
        "countries_sm": "Saint Marin",
        "countries_th": "Thaïlande",
        "countries_title": "Choisis ton pays",
        "countries_tr": "Turquie",
        "countries_ua": "Ukraine",
        "countries_uk": "Royaume-Uni",
        "countries_us": "Etats-Unis",
        "countries_vn": "Viêt Nam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Choisissez un autre pays",
        "country_options_gt10": "11 ans et plus",
        "country_options_gt5": "5 ans et plus",
        "country_options_let10": "moins de 11 ans",
        "country_options_let5": "moins de 5 ans",
        "country_options_oci": "OCI",
        "country_options_online": "Télécharger",
        "country_options_printed": "Imprimé",
        "country_options_title": "Choisissez votre type de photo",
        "email_subject": "Tes photos",
        "email_text": "Vos photos vous sont envoyées par email. Merci d'utiliser SelfIDeed !",
        "email_with_images": "Instructions pour la photo de passeport numérique",
        "face_alignment_error_description": "Votre photo ne peut pas être traitée par l'IA sans causer de problèmes. Veuillez réessayer en vous éloignant de la caméra.",
        "face_alignment_error_title": "Erreur d'alignement du visage",
        "hints_description_1": "Tenez votre mobile à bout de bras et au niveau des yeux...",
        "hints_description_2": "...vers le jour.",
        "hints_description_3": "Éteignez le flash.",
        "hints_next_button": "Continuer",
        "hints_next_button_waiting": "Considérez les règles...",
        "hints_subtitle": "Instructions",
        "hints_title": "Règles",
        "image_resolution_too_small_description": "La résolution de l'image est trop petite pour répondre aux exigences.",
        "image_resolution_too_small_title": "Résolution d'image trop petite",
        "landing_digital_description": "Utiliser pour télécharger votre photo",
        "landing_digital_header": "Version numérique",
        "landing_next_button": "Essayez maintenant",
        "landing_passport_description": "Utilisez-le pour imprimer vous-même sur du papier photo 10 x 15 cm...ou... laissez-le imprimer dans un service d'impression photo",
        "landing_passport_header": "Photos de passeport",
        "landing_title": "Créez vos photos de passeport en ligne",
        "no_face_error": "Le visage n'a pas pu être trouvé par notre algorithme - veuillez réessayer,",
        "preview_checked_photo": "J'ai vérifié ma photo et c'est OK",
        "preview_error_title": "Des erreurs se sont produites !",
        "preview_offer": "Nous vous enverrons directement par e-mail la photo d'identité numérique, ainsi que les photos d'identité à imprimer vous-même pour 4,95 euros.",
        "preview_purchase_button": "Achat",
        "preview_retry_button": "Essayer à nouveau",
        "preview_tc_1": "J'accepte le",
        "preview_tc_2": "termes et conditions",
        "preview_tc_3": "et le",
        "preview_tc_4": "politique de confidentialité",
        "preview_title": "Vérifiez votre photo",
        "processing_hint": "Notre IA traite votre photo...",
        "processing_title": "Traitement",
        "result_download_button": "Télécharger",
        "result_download_text": "Vos photos vous ont été envoyées par email. Merci d'utiliser SelfIDeed !",
        "result_title": "Résultat",
        "upload_button_text": "Envoyer la photo",
        "upload_title": "Prenez votre photo"
    },
    "gr": {
        "background_removal_error": "Σφάλμα κατά την αφαίρεση φόντου",
        "common_subtitle": "Φωτογραφίες διαβατηρίου/βίζας σε όλο τον κόσμο",
        "countries_ad": "Ανδόρα",
        "countries_ae": "Ηνωμένα Αραβικά Εμιράτα",
        "countries_al": "Αλβανία",
        "countries_am": "Αρμενία",
        "countries_at": "Αυστρία",
        "countries_au": "Αυστραλία",
        "countries_az": "Αζερμπαϊτζάν",
        "countries_ba": "Βοσνία Ερζεγοβίνη",
        "countries_be": "Βέλγιο",
        "countries_bg": "Βουλγαρία",
        "countries_br": "Βραζιλία",
        "countries_ch": "Ελβετία",
        "countries_cn": "Κίνα",
        "countries_cy": "Κύπρος",
        "countries_cz": "Τσεχία",
        "countries_de": "Γερμανία",
        "countries_dk": "Δανία",
        "countries_ee": "Εσθονία",
        "countries_es": "Ισπανία",
        "countries_fi": "Φινλανδία",
        "countries_fr": "Γαλλία",
        "countries_ge": "Γεωργία",
        "countries_gr": "Ελλάδα",
        "countries_hr": "την Κροατία",
        "countries_hu": "Ουγγαρία",
        "countries_id": "Ινδονησία",
        "countries_ie": "Ιρλανδία",
        "countries_il": "Ισραήλ",
        "countries_in": "Ινδία",
        "countries_ir": "Ιράν",
        "countries_is": "Ισλανδία",
        "countries_it": "Ιταλία",
        "countries_jo": "Ιορδανία",
        "countries_jp": "Ιαπωνία",
        "countries_kr": "Κορέα",
        "countries_kz": "Καζαχστάν",
        "countries_lb": "Λιβανος",
        "countries_li": "Λιχτενστάιν",
        "countries_lt": "Λιθουανία",
        "countries_lu": "Λουξεμβούργο",
        "countries_lv": "Λετονία",
        "countries_mc": "Μονακό",
        "countries_md": "Μολδαβία",
        "countries_me": "Μαυροβούνιο",
        "countries_mk": "Μακεδόνια",
        "countries_mt": "Μάλτα",
        "countries_my": "Μαλαισία",
        "countries_ng": "Νιγηρία",
        "countries_nl": "Ολλανδία",
        "countries_no": "Νορβηγία",
        "countries_ph": "Φιλιππίνες",
        "countries_pl": "Πολωνία",
        "countries_pt": "Πορτογαλία",
        "countries_ro": "Ρωμανία",
        "countries_rs": "Σερβία",
        "countries_ru": "Ρωσία",
        "countries_sa": "Σαουδική Αραβία",
        "countries_se": "Σουηδία",
        "countries_si": "Σλοβενία",
        "countries_sk": "Σλοβακία",
        "countries_sm": "Σαν Μαρίνο",
        "countries_th": "Ταϊλάνδη",
        "countries_title": "Επέλεξε τη χώρα σου",
        "countries_tr": "Τουρκία",
        "countries_ua": "Ουκρανία",
        "countries_uk": "Ηνωμένο Βασίλειο",
        "countries_us": "ΗΠΑ",
        "countries_vn": "Βιετνάμ",
        "countries_xk": "Κοσσυφοπέδιο",
        "country_options_choose_lang": "Επιλέξτε μια διαφορετική χώρα",
        "country_options_gt10": "11 και άνω",
        "country_options_gt5": "5 και άνω",
        "country_options_let10": "κάτω των 11 ετών",
        "country_options_let5": "κάτω από 5 χρόνια",
        "country_options_oci": "OCI",
        "country_options_online": "Μεταφόρτωση",
        "country_options_printed": "Εντυπος",
        "country_options_title": "Επιλέξτε τον τύπο φωτογραφίας σας",
        "email_subject": "Οι φωτογραφίες σου",
        "email_text": "Οι φωτογραφίες σας αποστέλλονται σε εσάς μέσω email. Σας ευχαριστούμε που χρησιμοποιείτε το SelfIDeed!",
        "email_with_images": "Οδηγίες φωτογραφιών ψηφιακού διαβατηρίου",
        "face_alignment_error_description": "Η φωτογραφία σας δεν μπορεί να υποβληθεί σε επεξεργασία από AI χωρίς να προκληθούν προβλήματα. Δοκιμάστε ξανά να μετακινηθείτε πιο μακριά από την κάμερα.",
        "face_alignment_error_title": "Σφάλμα ευθυγράμμισης προσώπου",
        "hints_description_1": "Κρατήστε το κινητό σας στο ύψος του χεριού και των ματιών...",
        "hints_description_2": "...προς το φως της ημέρας.",
        "hints_description_3": "Απενεργοποιήστε το φλας.",
        "hints_next_button": "Να συνεχίσει",
        "hints_next_button_waiting": "Σκεφτείτε τους κανόνες...",
        "hints_subtitle": "Οδηγίες",
        "hints_title": "Κανόνες",
        "image_resolution_too_small_description": "Η ανάλυση της εικόνας είναι πολύ μικρή για να ταιριάζει στις απαιτήσεις.",
        "image_resolution_too_small_title": "Η ανάλυση εικόνας είναι πολύ μικρή",
        "landing_digital_description": "Χρησιμοποιήστε το για τη μεταφόρτωση της φωτογραφίας σας",
        "landing_digital_header": "Ψηφιακή Έκδοση",
        "landing_next_button": "Προσπαθήστε τώρα",
        "landing_passport_description": "Χρησιμοποιήστε το για να εκτυπώσετε τον εαυτό σας σε φωτογραφικό χαρτί 10 x 15 cm ...ή... αφήστε το να εκτυπώσει σε μια υπηρεσία εκτύπωσης φωτογραφιών",
        "landing_passport_header": "Φωτογραφίες διαβατηρίου",
        "landing_title": "Κάντε τις φωτογραφίες του διαβατηρίου σας στο Διαδίκτυο",
        "no_face_error": "Δεν ήταν δυνατή η εύρεση του προσώπου από τον αλγόριθμό μας - δοκιμάστε ξανά,",
        "preview_checked_photo": "Έλεγξα τη φωτογραφία μου και είναι εντάξει",
        "preview_error_title": "Παρουσιάστηκαν σφάλματα!",
        "preview_offer": "Θα στείλουμε απευθείας email την ψηφιακή φωτογραφία διαβατηρίου, μαζί με τις φωτογραφίες του διαβατηρίου εκτύπωσης για 4,95 ευρώ",
        "preview_purchase_button": "Αγορά",
        "preview_retry_button": "Προσπάθησε ξανά",
        "preview_tc_1": "συμφωνώ με το",
        "preview_tc_2": "όροι και Προϋποθέσεις",
        "preview_tc_3": "και το",
        "preview_tc_4": "Πολιτική Απορρήτου",
        "preview_title": "Ελέγξτε τη φωτογραφία σας",
        "processing_hint": "Το AI μας επεξεργάζεται τη φωτογραφία σας...",
        "processing_title": "Επεξεργασία",
        "result_download_button": "Κατεβάστε",
        "result_download_text": "Οι φωτογραφίες σας έχουν σταλεί σε εσάς μέσω email. Σας ευχαριστούμε που χρησιμοποιείτε το SelfIDeed!",
        "result_title": "Αποτέλεσμα",
        "upload_button_text": "Ανέβασμα φωτογραφίας",
        "upload_title": "Τραβήξτε τη φωτογραφία σας"
    },
    "hr": {
        "background_removal_error": "Pogreška tijekom uklanjanja pozadine",
        "common_subtitle": "Fotografije putovnica/viza za cijeli svijet",
        "countries_ad": "Andora",
        "countries_ae": "Ujedinjeni Arapski Emirati",
        "countries_al": "Albanija",
        "countries_am": "Armenija",
        "countries_at": "Austrija",
        "countries_au": "Australija",
        "countries_az": "Azerbejdžan",
        "countries_ba": "Bosna i Hercegovina",
        "countries_be": "Belgija",
        "countries_bg": "Bugarska",
        "countries_br": "Brazil",
        "countries_ch": "Švicarska",
        "countries_cn": "Kina",
        "countries_cy": "Cipar",
        "countries_cz": "Češka",
        "countries_de": "Njemačka",
        "countries_dk": "Danska",
        "countries_ee": "Estonija",
        "countries_es": "Španjolska",
        "countries_fi": "Finska",
        "countries_fr": "Francuska",
        "countries_ge": "Gruzija",
        "countries_gr": "Grčka",
        "countries_hr": "Hrvatska",
        "countries_hu": "Mađarska",
        "countries_id": "Indonezija",
        "countries_ie": "Irska",
        "countries_il": "Izrael",
        "countries_in": "Indija",
        "countries_ir": "Iran",
        "countries_is": "Island",
        "countries_it": "Italija",
        "countries_jo": "Jordan",
        "countries_jp": "Japan",
        "countries_kr": "Koreja",
        "countries_kz": "Kazahstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litva",
        "countries_lu": "Luksemburg",
        "countries_lv": "Latvija",
        "countries_mc": "Monako",
        "countries_md": "Moldavija",
        "countries_me": "Crne Gore",
        "countries_mk": "Makedonija",
        "countries_mt": "Malta",
        "countries_my": "Malezija",
        "countries_ng": "Nigerija",
        "countries_nl": "Nizozemska",
        "countries_no": "Norveška",
        "countries_ph": "Filipini",
        "countries_pl": "Poljska",
        "countries_pt": "Portugal",
        "countries_ro": "Rumunjska",
        "countries_rs": "Srbija",
        "countries_ru": "Rusija",
        "countries_sa": "Saudijska Arabija",
        "countries_se": "Švedska",
        "countries_si": "Slovenija",
        "countries_sk": "Slovačka",
        "countries_sm": "San Marino",
        "countries_th": "Tajland",
        "countries_title": "Odaberite svoju zemlju",
        "countries_tr": "Turska",
        "countries_ua": "Ukrajina",
        "countries_uk": "Ujedinjeno Kraljevstvo",
        "countries_us": "SAD",
        "countries_vn": "Vijetnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Odaberite drugu zemlju",
        "country_options_gt10": "11 i više",
        "country_options_gt5": "5 i više",
        "country_options_let10": "ispod 11 godina",
        "country_options_let5": "ispod 5 godina",
        "country_options_oci": "OCI",
        "country_options_online": "Učitaj",
        "country_options_printed": "Tiskano",
        "country_options_title": "Odaberite svoju vrstu fotografije",
        "email_subject": "Tvoje slike",
        "email_text": "Vaše fotografije se šalju e-poštom. Hvala što koristite SelfIDeed!",
        "email_with_images": "Upute za fotografiju digitalne putovnice",
        "face_alignment_error_description": "AI ne može obraditi vašu fotografiju bez uzroka problema. Molimo pokušajte ponovno i udaljite se od kamere.",
        "face_alignment_error_title": "Pogreška poravnanja lica",
        "hints_description_1": "Držite mobitel na udaljenosti ruke i u razini očiju...",
        "hints_description_2": "...prema dnevnom svjetlu.",
        "hints_description_3": "Isključite bljeskalicu.",
        "hints_next_button": "Nastaviti",
        "hints_next_button_waiting": "Razmotrite pravila...",
        "hints_subtitle": "upute",
        "hints_title": "Pravila",
        "image_resolution_too_small_description": "Razlučivost slike je premala da bi odgovarala zahtjevima.",
        "image_resolution_too_small_title": "Rezolucija slike premala",
        "landing_digital_description": "Koristite za učitavanje vaše fotografije",
        "landing_digital_header": "Digitalna verzija",
        "landing_next_button": "Pokušajte sada",
        "landing_passport_description": "Ispišite sami na foto papiru 10 x 15 cm ...ili... prepustite ispisu u servisu za ispis fotografija",
        "landing_passport_header": "Fotografije za putovnicu",
        "landing_title": "Napravite svoje fotografije za putovnicu online",
        "no_face_error": "Naš algoritam nije mogao pronaći lice - pokušajte ponovo,",
        "preview_checked_photo": "Provjerio sam svoju fotografiju i u redu je",
        "preview_error_title": "Došlo je do pogrešaka!",
        "preview_offer": "Izravno ćemo poslati e-poštom digitalnu fotografiju za putovnicu, zajedno s fotografijama za putovnicu koje sami isprintate za 4,95 eura",
        "preview_purchase_button": "Kupiti",
        "preview_retry_button": "Pokušajte ponovno",
        "preview_tc_1": "Slažem se s",
        "preview_tc_2": "Uvjeti korištenja",
        "preview_tc_3": "i",
        "preview_tc_4": "Politika privatnosti",
        "preview_title": "Provjerite svoju fotografiju",
        "processing_hint": "Naš AI obrađuje vašu fotografiju...",
        "processing_title": "Obrada",
        "result_download_button": "preuzimanje datoteka",
        "result_download_text": "Vaše su vam fotografije poslane e-poštom. Hvala što koristite SelfIDeed!",
        "result_title": "Proizlaziti",
        "upload_button_text": "Učitaj fotografiju",
        "upload_title": "Snimi svoju fotografiju"
    },
    "hu": {
        "background_removal_error": "Hiba a háttér eltávolítása közben",
        "common_subtitle": "Világméretű útlevél-/vízumfotók",
        "countries_ad": "Andorra",
        "countries_ae": "Egyesült Arab Emírségek",
        "countries_al": "Albánia",
        "countries_am": "Örményország",
        "countries_at": "Ausztria",
        "countries_au": "Ausztrália",
        "countries_az": "Azerbajdzsán",
        "countries_ba": "Bosznia-Hercegovina",
        "countries_be": "Belgium",
        "countries_bg": "Bulgária",
        "countries_br": "Brazília",
        "countries_ch": "Svájc",
        "countries_cn": "Kína",
        "countries_cy": "Ciprus",
        "countries_cz": "Csehország",
        "countries_de": "Németország",
        "countries_dk": "Dánia",
        "countries_ee": "Észtország",
        "countries_es": "Spanyolország",
        "countries_fi": "Finnország",
        "countries_fr": "Franciaország",
        "countries_ge": "Grúzia",
        "countries_gr": "Görögország",
        "countries_hr": "Horvátország",
        "countries_hu": "Magyarország",
        "countries_id": "Indonézia",
        "countries_ie": "Írország",
        "countries_il": "Izrael",
        "countries_in": "India",
        "countries_ir": "Irán",
        "countries_is": "Izland",
        "countries_it": "Olaszország",
        "countries_jo": "Jordánia",
        "countries_jp": "Japán",
        "countries_kr": "Korea",
        "countries_kz": "Kazachsztán",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litvánia",
        "countries_lu": "Luxemburg",
        "countries_lv": "Lettország",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegró",
        "countries_mk": "Macedónia",
        "countries_mt": "Málta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigéria",
        "countries_nl": "Hollandia",
        "countries_no": "Norvégia",
        "countries_ph": "Fülöp-szigetek",
        "countries_pl": "Lengyelország",
        "countries_pt": "Portugália",
        "countries_ro": "Roménia",
        "countries_rs": "Szerbia",
        "countries_ru": "Oroszország",
        "countries_sa": "Szaud-Arábia",
        "countries_se": "Svédország",
        "countries_si": "Szlovénia",
        "countries_sk": "Szlovákia",
        "countries_sm": "San Marino",
        "countries_th": "Thaiföld",
        "countries_title": "Válaszd ki az országodat",
        "countries_tr": "Törökország",
        "countries_ua": "Ukrajna",
        "countries_uk": "Egyesült Királyság",
        "countries_us": "Egyesült Államok",
        "countries_vn": "Vietnam",
        "countries_xk": "Koszovó",
        "country_options_choose_lang": "Válasszon másik országot",
        "country_options_gt10": "11 év felettiek",
        "country_options_gt5": "5 éves és felett",
        "country_options_let10": "11 év alattiak",
        "country_options_let5": "5 év alatti",
        "country_options_oci": "OCI",
        "country_options_online": "Feltöltés",
        "country_options_printed": "Nyomtatott",
        "country_options_title": "Válassza ki a fénykép típusát",
        "email_subject": "A te fotóid",
        "email_text": "Fényképeit e-mailben küldjük el. Köszönjük, hogy a SelfIDeed-et használja!",
        "email_with_images": "Digitális útlevélfotó utasítások",
        "face_alignment_error_description": "A fotót nem tudja feldolgozni az AI anélkül, hogy problémákat okozna. Kérjük, próbálja meg újra távolodni a kamerától.",
        "face_alignment_error_title": "Arcigazítási hiba",
        "hints_description_1": "Tartsa a mobilját karnyújtásnyira és szemmagasságban...",
        "hints_description_2": "...nappal felé.",
        "hints_description_3": "Kapcsolja ki a vakut.",
        "hints_next_button": "Folytatni",
        "hints_next_button_waiting": "Fontolja meg a szabályokat...",
        "hints_subtitle": "Utasítás",
        "hints_title": "Szabályok",
        "image_resolution_too_small_description": "A képfelbontás túl kicsi ahhoz, hogy megfeleljen a követelményeknek.",
        "image_resolution_too_small_title": "A képfelbontás túl kicsi",
        "landing_digital_description": "Használja a fénykép feltöltéséhez",
        "landing_digital_header": "Digitális verzió",
        "landing_next_button": "Próbálja ki most",
        "landing_passport_description": "Nyomtassa ki magát 10 x 15 cm-es fotópapírra ...vagy... hagyja kinyomtatni egy fotónyomtató szolgáltatásban",
        "landing_passport_header": "Útlevél fotók",
        "landing_title": "Készítse el igazolványképeit online",
        "no_face_error": "Algoritmusunk nem találja az arcot – próbálja újra,",
        "preview_checked_photo": "Megnéztem a fotómat és rendben van",
        "preview_error_title": "Hibák történtek!",
        "preview_offer": "Közvetlenül e-mailben küldjük el a digitális igazolványképet a nyomtatott igazolványképekkel együtt 4,95 euróért",
        "preview_purchase_button": "Vásárlás",
        "preview_retry_button": "Próbáld újra",
        "preview_tc_1": "Egyetértek a",
        "preview_tc_2": "Felhasználási feltételek",
        "preview_tc_3": "és a",
        "preview_tc_4": "Adatvédelmi irányelvek",
        "preview_title": "Ellenőrizze a fényképét",
        "processing_hint": "A mesterséges intelligencia feldolgozza a fényképét...",
        "processing_title": "Feldolgozás",
        "result_download_button": "Letöltés",
        "result_download_text": "Fényképeit e-mailben elküldtük Önnek. Köszönjük, hogy a SelfIDeed-et használja!",
        "result_title": "Eredmény",
        "upload_button_text": "Fénykép feltöltése",
        "upload_title": "Készítse el a fényképét"
    },
    "id": {
        "background_removal_error": "Kesalahan saat menghapus latar belakang",
        "common_subtitle": "Foto Paspor/Visa Seluruh Dunia",
        "countries_ad": "Andorra",
        "countries_ae": "Uni Emirat Arab",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbaijan",
        "countries_ba": "Bosnia-Herzegovina",
        "countries_be": "Belgium",
        "countries_bg": "Bulgaria",
        "countries_br": "Brazil",
        "countries_ch": "Swiss",
        "countries_cn": "Cina",
        "countries_cy": "Siprus",
        "countries_cz": "Ceko",
        "countries_de": "Jerman",
        "countries_dk": "Denmark",
        "countries_ee": "Estonia",
        "countries_es": "Spanyol",
        "countries_fi": "Finlandia",
        "countries_fr": "Perancis",
        "countries_ge": "Georgia",
        "countries_gr": "Yunani",
        "countries_hr": "Kroasia",
        "countries_hu": "Hungaria",
        "countries_id": "Indonesia",
        "countries_ie": "Irlandia",
        "countries_il": "Israel",
        "countries_in": "India",
        "countries_ir": "Iran",
        "countries_is": "Islandia",
        "countries_it": "Italia",
        "countries_jo": "Yordania",
        "countries_jp": "Jepang",
        "countries_kr": "Korea",
        "countries_kz": "Kazakstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Lithuania",
        "countries_lu": "Luksemburg",
        "countries_lv": "Latvia",
        "countries_mc": "Monako",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Makedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Belanda",
        "countries_no": "Norway",
        "countries_ph": "Filipina",
        "countries_pl": "Polandia",
        "countries_pt": "Portugal",
        "countries_ro": "Rumania",
        "countries_rs": "Serbia",
        "countries_ru": "Rusia",
        "countries_sa": "Arab Saudi",
        "countries_se": "Swedia",
        "countries_si": "Slovenia",
        "countries_sk": "Slowakia",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Pilih negaramu",
        "countries_tr": "Turki",
        "countries_ua": "Ukraina",
        "countries_uk": "Britania Raya",
        "countries_us": "Amerika Serikat",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Pilih negara lain",
        "country_options_gt10": "11 tahun ke atas",
        "country_options_gt5": "5 tahun ke atas",
        "country_options_let10": "di bawah 11 tahun",
        "country_options_let5": "di bawah 5 tahun",
        "country_options_oci": "OCI",
        "country_options_online": "Mengunggah",
        "country_options_printed": "Dicetak",
        "country_options_title": "Pilih Jenis Foto Anda",
        "email_subject": "Foto-fotomu",
        "email_text": "Foto Anda dikirimkan kepada Anda melalui email. Terima kasih telah menggunakan SelfIDeed!",
        "email_with_images": "Petunjuk Foto Paspor Digital",
        "face_alignment_error_description": "Foto Anda tidak dapat diproses oleh AI tanpa menimbulkan masalah. Silakan coba lagi dengan menjauh dari kamera.",
        "face_alignment_error_title": "Kesalahan Penjajaran Wajah",
        "hints_description_1": "Pegang ponsel Anda sejauh lengan & setinggi mata...",
        "hints_description_2": "... menjelang siang hari.",
        "hints_description_3": "Matikan lampu kilat.",
        "hints_next_button": "Melanjutkan",
        "hints_next_button_waiting": "Pertimbangkan aturannya...",
        "hints_subtitle": "instruksi",
        "hints_title": "Aturan",
        "image_resolution_too_small_description": "Resolusi gambar terlalu kecil untuk memenuhi persyaratan.",
        "image_resolution_too_small_title": "Resolusi Gambar Terlalu Kecil",
        "landing_digital_description": "Gunakan untuk mengunggah foto Anda",
        "landing_digital_header": "Versi Digital",
        "landing_next_button": "Coba sekarang",
        "landing_passport_description": "Gunakan untuk mencetak sendiri di kertas foto ukuran 10 x 15 cm...atau...biarkan mencetak di jasa cetak foto",
        "landing_passport_header": "Pas photo",
        "landing_title": "Jadikan Foto Paspor Anda Online",
        "no_face_error": "Wajah tidak dapat ditemukan oleh algoritma kami - silakan coba lagi,",
        "preview_checked_photo": "Aku memeriksa fotoku dan tidak apa-apa",
        "preview_error_title": "Terjadi Kesalahan!",
        "preview_offer": "Kami akan langsung mengirimkan foto paspor digital melalui email, beserta foto paspor yang Anda cetak sendiri seharga 4,95 Euro",
        "preview_purchase_button": "Pembelian",
        "preview_retry_button": "Coba lagi",
        "preview_tc_1": "Saya setuju dengan",
        "preview_tc_2": "Syarat & Ketentuan",
        "preview_tc_3": "dan itu",
        "preview_tc_4": "Kebijakan pribadi",
        "preview_title": "Periksa Foto Anda",
        "processing_hint": "AI kami sedang memproses foto Anda...",
        "processing_title": "Pengolahan",
        "result_download_button": "Unduh",
        "result_download_text": "Foto Anda telah dikirimkan kepada Anda melalui email. Terima kasih telah menggunakan SelfIDeed!",
        "result_title": "Hasil",
        "upload_button_text": "Mengunggah foto",
        "upload_title": "Ambil Foto Anda"
    },
    "il": {
        "background_removal_error": "שגיאה במהלך הסרת הרקע",
        "common_subtitle": "תמונות דרכון/ויזה ברחבי העולם",
        "countries_ad": "אנדורה",
        "countries_ae": "איחוד האמירויות הערביות",
        "countries_al": "אלבניה",
        "countries_am": "אַרְמֶנִיָה",
        "countries_at": "אוֹסְטְרֵיָה",
        "countries_au": "אוֹסטְרַלִיָה",
        "countries_az": "אזרבייג'ן",
        "countries_ba": "בוסניה הרצגובינה",
        "countries_be": "בלגיה",
        "countries_bg": "בולגריה",
        "countries_br": "בְּרָזִיל",
        "countries_ch": "שוויץ",
        "countries_cn": "חרסינה",
        "countries_cy": "קַפרִיסִין",
        "countries_cz": "צ'כיה",
        "countries_de": "גֶרמָנִיָה",
        "countries_dk": "דנמרק",
        "countries_ee": "אסטוניה",
        "countries_es": "סְפָרַד",
        "countries_fi": "פינלנד",
        "countries_fr": "צָרְפַת",
        "countries_ge": "גאורגיה",
        "countries_gr": "יָוָן",
        "countries_hr": "קרואטיה",
        "countries_hu": "הונגריה",
        "countries_id": "אִינדוֹנֵזִיָה",
        "countries_ie": "אירלנד",
        "countries_il": "ישראל",
        "countries_in": "הוֹדוּ",
        "countries_ir": "איראן",
        "countries_is": "אִיסלַנד",
        "countries_it": "אִיטַלִיָה",
        "countries_jo": "ירדן",
        "countries_jp": "יפן",
        "countries_kr": "קוריאה",
        "countries_kz": "קזחסטן",
        "countries_lb": "ליבנון",
        "countries_li": "ליכטנשטיין",
        "countries_lt": "ליטא",
        "countries_lu": "לוקסמבורג",
        "countries_lv": "לטביה",
        "countries_mc": "מונקו",
        "countries_md": "מולדובה",
        "countries_me": "מונטנגרו",
        "countries_mk": "מוּקדוֹן",
        "countries_mt": "מלטה",
        "countries_my": "מלזיה",
        "countries_ng": "ניגריה",
        "countries_nl": "הולנד",
        "countries_no": "נורווגיה",
        "countries_ph": "פיליפינים",
        "countries_pl": "פּוֹלִין",
        "countries_pt": "פּוֹרטוּגָל",
        "countries_ro": "רומניה",
        "countries_rs": "סרביה",
        "countries_ru": "רוּסִיָה",
        "countries_sa": "ערב הסעודית",
        "countries_se": "שבדיה",
        "countries_si": "סלובניה",
        "countries_sk": "סלובקיה",
        "countries_sm": "סן מרינו",
        "countries_th": "תאילנד",
        "countries_title": "בחר את מדינתך",
        "countries_tr": "טורקיה",
        "countries_ua": "אוקראינה",
        "countries_uk": "הממלכה המאוחדת",
        "countries_us": "ארה\"ב",
        "countries_vn": "וייטנאם",
        "countries_xk": "קוסובו",
        "country_options_choose_lang": "בחר מדינה אחרת",
        "country_options_gt10": "11 ומעלה",
        "country_options_gt5": "5 שנים ומעלה",
        "country_options_let10": "מתחת ל-11 שנים",
        "country_options_let5": "מתחת ל-5",
        "country_options_oci": "OCI",
        "country_options_online": "העלה",
        "country_options_printed": "מודפס",
        "country_options_title": "בחר את סוג התמונה שלך",
        "email_subject": "התמונות שלך",
        "email_text": "התמונות שלך נשלחות אליך במייל. תודה על השימוש ב-SelfIDeed!",
        "email_with_images": "הוראות לצילום פספורט דיגיטלי",
        "face_alignment_error_description": "לא ניתן לעבד את התמונה שלך על ידי AI מבלי לגרום לבעיות. אנא נסה שוב להתרחק מהמצלמה.",
        "face_alignment_error_title": "שגיאת יישור פנים",
        "hints_description_1": "החזק את הנייד שלך בגובה היד ובגובה העיניים...",
        "hints_description_2": "...לקראת אור יום.",
        "hints_description_3": "כבה את הפלאש.",
        "hints_next_button": "לְהַמשִׁיך",
        "hints_next_button_waiting": "קחו בחשבון את הכללים...",
        "hints_subtitle": "הוראות",
        "hints_title": "כללים",
        "image_resolution_too_small_description": "רזולוציית התמונה קטנה מדי כדי להתאים לדרישות.",
        "image_resolution_too_small_title": "רזולוציית תמונה קטנה מדי",
        "landing_digital_description": "השתמש להעלאת התמונה שלך",
        "landing_digital_header": "גרסה דיגיטלית",
        "landing_next_button": "נסה עכשיו",
        "landing_passport_description": "השתמש כדי להדפיס את עצמך על נייר צילום בגודל 10 על 15 ס\"מ ... או... תן לו להדפיס בשירות הדפסת תמונות",
        "landing_passport_header": "תמונות פספורט",
        "landing_title": "הפוך את תמונות הדרכון שלך לאינטרנט",
        "no_face_error": "הפנים לא נמצאו על ידי האלגוריתם שלנו - אנא נסה שוב,",
        "preview_checked_photo": "בדקתי את התמונה שלי והיא בסדר",
        "preview_error_title": "התרחשו שגיאות!",
        "preview_offer": "אנו נשלח ישירות למייל את תמונת הפספורט הדיגיטלית, יחד עם תמונות הדרכון להדפיס בעצמך תמורת 4.95 אירו",
        "preview_purchase_button": "לִרְכּוֹשׁ",
        "preview_retry_button": "נסה שוב",
        "preview_tc_1": "אני מסכים ל",
        "preview_tc_2": "תנאים והגבלות",
        "preview_tc_3": "וה",
        "preview_tc_4": "מדיניות הפרטיות",
        "preview_title": "בדוק את התמונה שלך",
        "processing_hint": "ה-AI שלנו מעבד את התמונה שלך...",
        "processing_title": "מעבד",
        "result_download_button": "הורד",
        "result_download_text": "התמונות שלך נשלחו אליך באימייל. תודה על השימוש ב-SelfIDeed!",
        "result_title": "תוֹצָאָה",
        "upload_button_text": "העלה תמונה",
        "upload_title": "צלם את התמונה שלך"
    },
    "ir": {
        "background_removal_error": "خطا در حین حذف پس زمینه",
        "common_subtitle": "عکس پاسپورت/ویزا در سراسر جهان",
        "countries_ad": "آندورا",
        "countries_ae": "امارات متحده عربی",
        "countries_al": "آلبانی",
        "countries_am": "ارمنستان",
        "countries_at": "اتریش",
        "countries_au": "استرالیا",
        "countries_az": "آذربایجان",
        "countries_ba": "بوسنی هرزگوین",
        "countries_be": "بلژیک",
        "countries_bg": "بلغارستان",
        "countries_br": "برزیل",
        "countries_ch": "سوئیس",
        "countries_cn": "چین",
        "countries_cy": "قبرس",
        "countries_cz": "چک",
        "countries_de": "آلمان",
        "countries_dk": "دانمارک",
        "countries_ee": "استونی",
        "countries_es": "اسپانیا",
        "countries_fi": "فنلاند",
        "countries_fr": "فرانسه",
        "countries_ge": "گرجستان",
        "countries_gr": "یونان",
        "countries_hr": "کرواسی",
        "countries_hu": "مجارستان",
        "countries_id": "اندونزی",
        "countries_ie": "ایرلند",
        "countries_il": "اسرائیل",
        "countries_in": "هند",
        "countries_ir": "ایران",
        "countries_is": "ایسلند",
        "countries_it": "ایتالیا",
        "countries_jo": "اردن",
        "countries_jp": "ژاپن",
        "countries_kr": "کشور کره",
        "countries_kz": "قزاقستان",
        "countries_lb": "لیبان",
        "countries_li": "لیختن اشتاین",
        "countries_lt": "لیتوانی",
        "countries_lu": "لوکزامبورگ",
        "countries_lv": "لتونی",
        "countries_mc": "موناکو",
        "countries_md": "مولداوی",
        "countries_me": "مونته نگرو",
        "countries_mk": "مقدونیه",
        "countries_mt": "مالت",
        "countries_my": "مالزی",
        "countries_ng": "نیجریه",
        "countries_nl": "هلند",
        "countries_no": "نروژ",
        "countries_ph": "فیلیپین",
        "countries_pl": "لهستان",
        "countries_pt": "کشور پرتغال",
        "countries_ro": "رومنیا",
        "countries_rs": "صربستان",
        "countries_ru": "روسیه",
        "countries_sa": "عربستان سعودی",
        "countries_se": "سوئد",
        "countries_si": "اسلوونی",
        "countries_sk": "اسلواکی",
        "countries_sm": "سن مارینو",
        "countries_th": "تایلند",
        "countries_title": "کشورتان را انتخاب کنید",
        "countries_tr": "ترکیه",
        "countries_ua": "اوکراین",
        "countries_uk": "انگلستان",
        "countries_us": "ایالات متحده آمریکا",
        "countries_vn": "ویتنام",
        "countries_xk": "کوزوو",
        "country_options_choose_lang": "کشور دیگری را انتخاب کنید",
        "country_options_gt10": "11 و بیشتر",
        "country_options_gt5": "5 سال و بیشتر",
        "country_options_let10": "زیر 11 سال",
        "country_options_let5": "زیر 5 سال",
        "country_options_oci": "OCI",
        "country_options_online": "بارگذاری",
        "country_options_printed": "چاپ شده",
        "country_options_title": "نوع عکس خود را انتخاب کنید",
        "email_subject": "عکس های خود را",
        "email_text": "عکس های شما از طریق ایمیل برای شما ارسال می شود. با تشکر از شما برای استفاده از SelfIDeed!",
        "email_with_images": "دستورالعمل عکس پاسپورت دیجیتال",
        "face_alignment_error_description": "عکس شما بدون ایجاد مشکل توسط هوش مصنوعی قابل پردازش نیست. لطفاً دوباره سعی کنید از دوربین فاصله بگیرید.",
        "face_alignment_error_title": "خطای تراز چهره",
        "hints_description_1": "موبایل خود را هم سطح دست و هم سطح چشم نگه دارید...",
        "hints_description_2": "...به سمت روشنایی روز",
        "hints_description_3": "فلاش را خاموش کنید",
        "hints_next_button": "ادامه هید",
        "hints_next_button_waiting": "قوانین را در نظر بگیرید ...",
        "hints_subtitle": "دستورالعمل ها",
        "hints_title": "قوانین",
        "image_resolution_too_small_description": "وضوح تصویر برای مطابقت با الزامات بسیار کوچک است.",
        "image_resolution_too_small_title": "وضوح تصویر خیلی کوچک است",
        "landing_digital_description": "برای آپلود عکس خود استفاده کنید",
        "landing_digital_header": "نسخه دیجیتال",
        "landing_next_button": "حالا امتحان کن",
        "landing_passport_description": "برای چاپ روی کاغذ عکس 10 × 15 سانتی متر ... یا... اجازه دهید در سرویس چاپ عکس چاپ شود",
        "landing_passport_header": "عکس پاسپورت",
        "landing_title": "عکس های گذرنامه خود را آنلاین کنید",
        "no_face_error": "الگوریتم ما چهره را پیدا نکرد - لطفاً دوباره امتحان کنید،",
        "preview_checked_photo": "عکسمو چک کردم مشکلی نداره",
        "preview_error_title": "خطاهای رخ داده!",
        "preview_offer": "ما مستقیماً عکس گذرنامه دیجیتال را به همراه عکسهای گذرنامه چاپی خود به قیمت 4.95 یورو ایمیل می کنیم.",
        "preview_purchase_button": "خرید",
        "preview_retry_button": "دوباره امتحان کنید",
        "preview_tc_1": "من موافقم",
        "preview_tc_2": "شرایط و ضوابط",
        "preview_tc_3": "و",
        "preview_tc_4": "سیاست حفظ حریم خصوصی",
        "preview_title": "عکس خود را بررسی کنید",
        "processing_hint": "هوش مصنوعی ما در حال پردازش عکس شماست...",
        "processing_title": "در حال پردازش",
        "result_download_button": "دانلود",
        "result_download_text": "عکس های شما از طریق ایمیل برای شما ارسال شده است. با تشکر از شما برای استفاده از SelfIDeed!",
        "result_title": "نتیجه",
        "upload_button_text": "آپلود عکس",
        "upload_title": "عکس خود را بگیرید"
    },
    "it": {
        "background_removal_error": "Errore durante la rimozione dello sfondo",
        "common_subtitle": "Foto di passaporti/visti in tutto il mondo",
        "countries_ad": "Andorra",
        "countries_ae": "Emirati Arabi Uniti",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbaigian",
        "countries_ba": "Bosnia Erzegovina",
        "countries_be": "Belgio",
        "countries_bg": "Bulgaria",
        "countries_br": "Brasile",
        "countries_ch": "Svizzera",
        "countries_cn": "Cina",
        "countries_cy": "Cipro",
        "countries_cz": "Cechia",
        "countries_de": "Germania",
        "countries_dk": "Danimarca",
        "countries_ee": "Estonia",
        "countries_es": "Spagna",
        "countries_fi": "Finlandia",
        "countries_fr": "Francia",
        "countries_ge": "Georgia",
        "countries_gr": "Grecia",
        "countries_hr": "Croazia",
        "countries_hu": "Ungheria",
        "countries_id": "Indonesia",
        "countries_ie": "Irlanda",
        "countries_il": "Israele",
        "countries_in": "India",
        "countries_ir": "Iran",
        "countries_is": "Islanda",
        "countries_it": "Italia",
        "countries_jo": "Giordania",
        "countries_jp": "Giappone",
        "countries_kr": "Corea",
        "countries_kz": "Kazakistan",
        "countries_lb": "Libano",
        "countries_li": "Liechtenstein",
        "countries_lt": "Lituania",
        "countries_lu": "Lussemburgo",
        "countries_lv": "Lettonia",
        "countries_mc": "Monaco",
        "countries_md": "Moldavia",
        "countries_me": "Montenegro",
        "countries_mk": "Macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Olanda",
        "countries_no": "Norvegia",
        "countries_ph": "Filippine",
        "countries_pl": "Polonia",
        "countries_pt": "Portogallo",
        "countries_ro": "Romania",
        "countries_rs": "Serbia",
        "countries_ru": "Russia",
        "countries_sa": "Arabia Saudita",
        "countries_se": "Svezia",
        "countries_si": "Slovenia",
        "countries_sk": "Slovacchia",
        "countries_sm": "San Marino",
        "countries_th": "Tailandia",
        "countries_title": "Scegli il tuo paese",
        "countries_tr": "Turchia",
        "countries_ua": "Ucraina",
        "countries_uk": "Regno Unito",
        "countries_us": "Stati Uniti d'America",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Scegli un paese diverso",
        "country_options_gt10": "11 e oltre",
        "country_options_gt5": "5 e oltre",
        "country_options_let10": "sotto gli 11 anni",
        "country_options_let5": "sotto i 5 anni",
        "country_options_oci": "OCI",
        "country_options_online": "Caricamento",
        "country_options_printed": "Stampato",
        "country_options_title": "Scegli il tipo di foto",
        "email_subject": "Le tue foto",
        "email_text": "Le tue foto ti vengono inviate via email. Grazie per aver utilizzato SelfIDeed!",
        "email_with_images": "Istruzioni per la foto del passaporto digitale",
        "face_alignment_error_description": "La tua foto non può essere elaborata dall'intelligenza artificiale senza causare problemi. Riprova allontanandoti dalla fotocamera.",
        "face_alignment_error_title": "Errore di allineamento del volto",
        "hints_description_1": "Tieni il cellulare a distanza di un braccio e all'altezza degli occhi...",
        "hints_description_2": "...verso l'alba.",
        "hints_description_3": "Disattiva il flash.",
        "hints_next_button": "Continua",
        "hints_next_button_waiting": "Considera le regole...",
        "hints_subtitle": "Istruzioni",
        "hints_title": "Regole",
        "image_resolution_too_small_description": "La risoluzione dell'immagine è troppo piccola per soddisfare i requisiti.",
        "image_resolution_too_small_title": "Risoluzione dell'immagine troppo piccola",
        "landing_digital_description": "Utilizzalo per caricare la tua foto",
        "landing_digital_header": "Versione digitale",
        "landing_next_button": "Prova ora",
        "landing_passport_description": "Utilizzalo per stamparti su carta fotografica 10 x 15 cm...o... lascialo stampare presso un servizio di stampa fotografica",
        "landing_passport_header": "Foto del passaporto",
        "landing_title": "Crea le tue foto per il passaporto online",
        "no_face_error": "Il nostro algoritmo non ha trovato il volto: riprova,",
        "preview_checked_photo": "Ho controllato la mia foto ed è OK",
        "preview_error_title": "Si sono verificati errori!",
        "preview_offer": "Ti invieremo direttamente via email la foto tessera digitale, insieme alle foto tessera stampabili per 4,95 Euro",
        "preview_purchase_button": "Acquistare",
        "preview_retry_button": "Riprova",
        "preview_tc_1": "Sono d'accordo con",
        "preview_tc_2": "Termini & Condizioni",
        "preview_tc_3": "e il",
        "preview_tc_4": "politica sulla riservatezza",
        "preview_title": "Controlla la tua foto",
        "processing_hint": "La nostra intelligenza artificiale sta elaborando la tua foto...",
        "processing_title": "in lavorazione",
        "result_download_button": "Scaricamento",
        "result_download_text": "Le tue foto ti sono state inviate via email. Grazie per aver utilizzato SelfIDeed!",
        "result_title": "Risultato",
        "upload_button_text": "Carica una foto",
        "upload_title": "Scatta la tua foto"
    },
    "jp": {
        "background_removal_error": "背景除去中のエラー",
        "common_subtitle": "世界中のパスポート/ビザの写真",
        "countries_ad": "アンドラ",
        "countries_ae": "アラブ首長国連邦",
        "countries_al": "アルバニア",
        "countries_am": "アルメニア",
        "countries_at": "オーストリア",
        "countries_au": "オーストラリア",
        "countries_az": "アゼルバイジャン",
        "countries_ba": "ボスニア・ヘルツェゴビナ",
        "countries_be": "ベルギー",
        "countries_bg": "ブルガリア",
        "countries_br": "ブラジル",
        "countries_ch": "スイス",
        "countries_cn": "中国",
        "countries_cy": "キプロス",
        "countries_cz": "チェコ",
        "countries_de": "ドイツ",
        "countries_dk": "デンマーク",
        "countries_ee": "エストニア",
        "countries_es": "スペイン",
        "countries_fi": "フィンランド",
        "countries_fr": "フランス",
        "countries_ge": "ジョージア",
        "countries_gr": "ギリシャ",
        "countries_hr": "クロアチア",
        "countries_hu": "ハンガリー",
        "countries_id": "インドネシア",
        "countries_ie": "アイルランド",
        "countries_il": "イスラエル",
        "countries_in": "インド",
        "countries_ir": "イラン",
        "countries_is": "アイスランド",
        "countries_it": "イタリア",
        "countries_jo": "ヨルダン",
        "countries_jp": "日本",
        "countries_kr": "韓国",
        "countries_kz": "カザフスタン",
        "countries_lb": "リバノン",
        "countries_li": "リキテンスタイン",
        "countries_lt": "リトアニア",
        "countries_lu": "ルクセンブルク",
        "countries_lv": "ラトビア",
        "countries_mc": "モナコ",
        "countries_md": "モルドバ",
        "countries_me": "モンテネグロ",
        "countries_mk": "マケドニア",
        "countries_mt": "マルタ",
        "countries_my": "マレーシア",
        "countries_ng": "ナイジェリア",
        "countries_nl": "オランダ",
        "countries_no": "ノルウェー",
        "countries_ph": "フィリピン",
        "countries_pl": "ポーランド",
        "countries_pt": "ポルトガル",
        "countries_ro": "ロメニア",
        "countries_rs": "セルビア",
        "countries_ru": "ロシア",
        "countries_sa": "サウジアラビア",
        "countries_se": "スウェーデン",
        "countries_si": "スロベニア",
        "countries_sk": "スロバキア",
        "countries_sm": "サンマリノ",
        "countries_th": "タイ",
        "countries_title": "あなたの国を選んでください",
        "countries_tr": "トルコ",
        "countries_ua": "ウクライナ",
        "countries_uk": "イギリス",
        "countries_us": "アメリカ合衆国",
        "countries_vn": "ベトナム",
        "countries_xk": "コソボ",
        "country_options_choose_lang": "別の国を選択してください",
        "country_options_gt10": "11歳以上",
        "country_options_gt5": "5歳以上",
        "country_options_let10": "11歳未満",
        "country_options_let5": "5歳未満",
        "country_options_oci": "OCI",
        "country_options_online": "アップロード",
        "country_options_printed": "印刷済み",
        "country_options_title": "写真の種類を選択してください",
        "email_subject": "あなたの写真",
        "email_text": "写真は電子メールで送信されます。 SelfIDeed をご利用いただきありがとうございます。",
        "email_with_images": "デジタルパスポートの写真の手順",
        "face_alignment_error_description": "あなたの写真を AI で処理しても問題は発生しません。 カメラから離れてもう一度お試しください。",
        "face_alignment_error_title": "顔の位置合わせエラー",
        "hints_description_1": "携帯電話を腕を伸ばして目の高さで持ちます...",
        "hints_description_2": "...日の光に向かって。",
        "hints_description_3": "フラッシュをオフにします。",
        "hints_next_button": "続く",
        "hints_next_button_waiting": "ルールを考えてみましょう...",
        "hints_subtitle": "説明書",
        "hints_title": "ルール",
        "image_resolution_too_small_description": "画像の解像度が小さすぎて要件に適合しません。",
        "image_resolution_too_small_title": "画像解像度が小さすぎる",
        "landing_digital_description": "写真のアップロードに使用します",
        "landing_digital_header": "デジタル版",
        "landing_next_button": "今すぐやってみて下さい",
        "landing_passport_description": "10 x 15 cm の写真用紙に自分自身をプリントするために使用します...または...写真プリント サービスでプリントしてもらいます",
        "landing_passport_header": "パスポートの写真",
        "landing_title": "パスポートの写真をオンラインで作成",
        "no_face_error": "アルゴリズムによって顔が見つかりませんでした - もう一度試してください。",
        "preview_checked_photo": "写真を確認しましたが、問題ありませんでした",
        "preview_error_title": "エラーが発生しました!",
        "preview_offer": "デジタルパスポート写真と、自分で印刷したパスポート写真を 4,95 ユーロで直接電子メールでお送りします。",
        "preview_purchase_button": "購入",
        "preview_retry_button": "もう一度やり直してください",
        "preview_tc_1": "に同意します",
        "preview_tc_2": "利用規約",
        "preview_tc_3": "そしてその",
        "preview_tc_4": "プライバシーポリシー",
        "preview_title": "写真を確認してください",
        "processing_hint": "AI があなたの写真を処理しています...",
        "processing_title": "処理",
        "result_download_button": "ダウンロード",
        "result_download_text": "あなたの写真は電子メールで送信されました。 SelfIDeed をご利用いただきありがとうございます。",
        "result_title": "結果",
        "upload_button_text": "写真をアップする",
        "upload_title": "写真を撮ってください"
    },
    "kr": {
        "background_removal_error": "배경 제거 중 오류",
        "common_subtitle": "전세계 여권/비자 사진",
        "countries_ad": "안도라",
        "countries_ae": "아랍 에미리트 연합",
        "countries_al": "알바니아",
        "countries_am": "아르메니아",
        "countries_at": "오스트리아",
        "countries_au": "호주",
        "countries_az": "아제르바이잔",
        "countries_ba": "보스니아 헤르체고비나",
        "countries_be": "벨기에",
        "countries_bg": "불가리아",
        "countries_br": "브라질",
        "countries_ch": "스위스",
        "countries_cn": "중국",
        "countries_cy": "키프로스",
        "countries_cz": "체코",
        "countries_de": "독일",
        "countries_dk": "덴마크",
        "countries_ee": "에스토니아",
        "countries_es": "스페인",
        "countries_fi": "핀란드",
        "countries_fr": "프랑스",
        "countries_ge": "그루지야",
        "countries_gr": "그리스",
        "countries_hr": "크로아티아",
        "countries_hu": "헝가리",
        "countries_id": "인도네시아",
        "countries_ie": "아일랜드",
        "countries_il": "이스라엘",
        "countries_in": "인도",
        "countries_ir": "이란",
        "countries_is": "아이슬란드",
        "countries_it": "이탈리아",
        "countries_jo": "요르단",
        "countries_jp": "일본",
        "countries_kr": "한국",
        "countries_kz": "카자흐스탄",
        "countries_lb": "리바논",
        "countries_li": "리히텐슈타인",
        "countries_lt": "리투아니아",
        "countries_lu": "룩셈부르크",
        "countries_lv": "라트비아",
        "countries_mc": "모나코",
        "countries_md": "몰도바",
        "countries_me": "몬테네그로",
        "countries_mk": "마케도니아",
        "countries_mt": "몰타",
        "countries_my": "말레이시아",
        "countries_ng": "나이지리아",
        "countries_nl": "네덜란드",
        "countries_no": "노르웨이",
        "countries_ph": "필리핀 제도",
        "countries_pl": "폴란드",
        "countries_pt": "포르투갈",
        "countries_ro": "로메니아",
        "countries_rs": "세르비아",
        "countries_ru": "러시아",
        "countries_sa": "사우디 아라비아",
        "countries_se": "스웨덴",
        "countries_si": "슬로베니아",
        "countries_sk": "슬로바키아",
        "countries_sm": "산 마리노",
        "countries_th": "태국",
        "countries_title": "당신의 나라를 선택하시오",
        "countries_tr": "터키",
        "countries_ua": "우크라이나",
        "countries_uk": "영국",
        "countries_us": "미국",
        "countries_vn": "베트남",
        "countries_xk": "코소보",
        "country_options_choose_lang": "다른 국가를 선택하세요",
        "country_options_gt10": "11세 이상",
        "country_options_gt5": "5세 이상",
        "country_options_let10": "11세 미만",
        "country_options_let5": "5세 미만",
        "country_options_oci": "OCI",
        "country_options_online": "업로드",
        "country_options_printed": "인쇄됨",
        "country_options_title": "사진 유형을 선택하세요",
        "email_subject": "너의 사진들",
        "email_text": "귀하의 사진은 이메일로 전송됩니다. SelfIDeed를 이용해 주셔서 감사합니다!",
        "email_with_images": "디지털 여권 사진 지침",
        "face_alignment_error_description": "귀하의 사진은 문제 없이 AI로 처리될 수 없습니다. 카메라에서 더 멀리 이동하여 다시 시도해 보세요.",
        "face_alignment_error_title": "얼굴 정렬 오류",
        "hints_description_1": "휴대폰을 팔 길이와 눈높이로 유지하세요.",
        "hints_description_2": "...일광을 향해.",
        "hints_description_3": "플래시를 끄세요.",
        "hints_next_button": "계속하다",
        "hints_next_button_waiting": "규칙을 고려해보세요..",
        "hints_subtitle": "지침",
        "hints_title": "규칙",
        "image_resolution_too_small_description": "이미지 해상도가 너무 작아서 요구 사항에 맞지 않습니다.",
        "image_resolution_too_small_title": "이미지 해상도가 너무 작음",
        "landing_digital_description": "사진 업로드에 사용",
        "landing_digital_header": "디지털 버전",
        "landing_next_button": "지금 사용해 보세요",
        "landing_passport_description": "10 x 15cm 인화지에 직접 인쇄하는 데 사용하거나... 사진 인쇄 서비스에서 인쇄하도록 하세요.",
        "landing_passport_header": "여권 사진",
        "landing_title": "여권 사진을 온라인으로 만드세요",
        "no_face_error": "알고리즘으로 얼굴을 찾을 수 없습니다. 다시 시도해 주세요.",
        "preview_checked_photo": "사진 확인해보니 괜찮아요",
        "preview_error_title": "오류가 발생했습니다!",
        "preview_offer": "4,95유로에 직접 인쇄한 여권 사진과 함께 디지털 여권 사진을 이메일로 직접 보내드립니다.",
        "preview_purchase_button": "구입",
        "preview_retry_button": "다시 시도하십시오",
        "preview_tc_1": "나는 다음에 동의한다.",
        "preview_tc_2": "이용약관",
        "preview_tc_3": "그리고",
        "preview_tc_4": "개인 정보 정책",
        "preview_title": "사진 확인",
        "processing_hint": "AI가 사진을 처리하고 있습니다...",
        "processing_title": "처리",
        "result_download_button": "다운로드",
        "result_download_text": "귀하의 사진이 이메일로 전송되었습니다. SelfIDeed를 이용해 주셔서 감사합니다!",
        "result_title": "결과",
        "upload_button_text": "사진 업로드",
        "upload_title": "사진을 찍으세요"
    },
    "lt": {
        "background_removal_error": "Klaida šalinant foną",
        "common_subtitle": "Pasaulio pasų / vizų nuotraukos",
        "countries_ad": "Andora",
        "countries_ae": "Jungtiniai Arabų Emyratai",
        "countries_al": "Albanija",
        "countries_am": "Armėnija",
        "countries_at": "Austrija",
        "countries_au": "Australija",
        "countries_az": "Azerbaidžanas",
        "countries_ba": "Bosnija ir Hercegovina",
        "countries_be": "Belgija",
        "countries_bg": "Bulgarija",
        "countries_br": "Brazilija",
        "countries_ch": "Šveicarija",
        "countries_cn": "Kinija",
        "countries_cy": "Kipras",
        "countries_cz": "Čekija",
        "countries_de": "Vokietija",
        "countries_dk": "Danija",
        "countries_ee": "Estija",
        "countries_es": "Ispanija",
        "countries_fi": "Suomija",
        "countries_fr": "Prancūzija",
        "countries_ge": "Gruzija",
        "countries_gr": "Graikija",
        "countries_hr": "Kroatija",
        "countries_hu": "Vengrija",
        "countries_id": "Indonezija",
        "countries_ie": "Airija",
        "countries_il": "Izraelis",
        "countries_in": "Indija",
        "countries_ir": "Iranas",
        "countries_is": "Islandija",
        "countries_it": "Italija",
        "countries_jo": "Jordanija",
        "countries_jp": "Japonija",
        "countries_kr": "Korėja",
        "countries_kz": "Kazachstanas",
        "countries_lb": "Libanas",
        "countries_li": "Lichtenšteinas",
        "countries_lt": "Lietuva",
        "countries_lu": "Liuksemburgas",
        "countries_lv": "Latvija",
        "countries_mc": "Monakas",
        "countries_md": "Moldova",
        "countries_me": "Juodkalnija",
        "countries_mk": "Makedonija",
        "countries_mt": "Malta",
        "countries_my": "Malaizija",
        "countries_ng": "Nigerija",
        "countries_nl": "Nyderlandai",
        "countries_no": "Norvegija",
        "countries_ph": "Filipinai",
        "countries_pl": "Lenkija",
        "countries_pt": "Portugalija",
        "countries_ro": "Rumunija",
        "countries_rs": "Serbija",
        "countries_ru": "Rusija",
        "countries_sa": "Saudo Arabija",
        "countries_se": "Švedija",
        "countries_si": "Slovėnija",
        "countries_sk": "Slovakija",
        "countries_sm": "San Marinas",
        "countries_th": "Tailandas",
        "countries_title": "Pasirinkite savo šalį",
        "countries_tr": "Turkija",
        "countries_ua": "Ukraina",
        "countries_uk": "Jungtinė Karalystė",
        "countries_us": "JAV",
        "countries_vn": "Vietnamas",
        "countries_xk": "Kosovas",
        "country_options_choose_lang": "Pasirinkite kitą šalį",
        "country_options_gt10": "11 ir daugiau",
        "country_options_gt5": "5 ir daugiau",
        "country_options_let10": "iki 11 metų",
        "country_options_let5": "iki 5 metų",
        "country_options_oci": "OCI",
        "country_options_online": "Įkelti",
        "country_options_printed": "Išspausdinta",
        "country_options_title": "Pasirinkite savo nuotraukos tipą",
        "email_subject": "Tavo nuotraukos",
        "email_text": "Jūsų nuotraukos siunčiamos el. paštu. Dėkojame, kad naudojatės SelfIDeed!",
        "email_with_images": "Skaitmeninės paso nuotraukos instrukcijos",
        "face_alignment_error_description": "Jūsų nuotraukos negali apdoroti AI nesukeliant problemų. Pabandykite dar kartą judėti toliau nuo fotoaparato.",
        "face_alignment_error_title": "Veido išlygiavimo klaida",
        "hints_description_1": "Laikykite savo mobilųjį ištiestos rankos ir akių lygyje...",
        "hints_description_2": "...prie dienos šviesos.",
        "hints_description_3": "Išjungti blykstę.",
        "hints_next_button": "Tęsti",
        "hints_next_button_waiting": "Apsvarstykite taisykles...",
        "hints_subtitle": "Instrukcijos",
        "hints_title": "Taisyklės",
        "image_resolution_too_small_description": "Vaizdo skiriamoji geba per maža, kad atitiktų reikalavimus.",
        "image_resolution_too_small_title": "Vaizdo skyra per maža",
        "landing_digital_description": "Naudokite savo nuotraukai įkelti",
        "landing_digital_header": "Skaitmeninė versija",
        "landing_next_button": "Pabandykite dabar",
        "landing_passport_description": "Naudokite norėdami atsispausdinti ant 10 x 15 cm fotopopieriaus ...arba... leiskite atsispausdinti nuotraukų spausdinimo tarnyboje",
        "landing_passport_header": "Paso nuotraukos",
        "landing_title": "Padarykite savo paso nuotraukas internetu",
        "no_face_error": "Pagal algoritmą veido nepavyko rasti – bandykite dar kartą,",
        "preview_checked_photo": "Pažiūrėjau savo nuotrauką ir viskas gerai",
        "preview_error_title": "Įvyko klaidų!",
        "preview_offer": "Skaitmeninę paso nuotrauką kartu su atsispausdintomis paso nuotraukomis atsiųsime tiesiogiai el. paštu už 4,95 euro",
        "preview_purchase_button": "Pirkimas",
        "preview_retry_button": "Bandyk iš naujo",
        "preview_tc_1": "Sutinku su",
        "preview_tc_2": "Sąlygos ir nuostatos",
        "preview_tc_3": "ir",
        "preview_tc_4": "Privatumo politika",
        "preview_title": "Patikrinkite savo nuotrauką",
        "processing_hint": "Mūsų AI apdoroja jūsų nuotrauką...",
        "processing_title": "Apdorojimas",
        "result_download_button": "parsisiųsti",
        "result_download_text": "Jūsų nuotraukos buvo išsiųstos jums el. paštu. Dėkojame, kad naudojatės SelfIDeed!",
        "result_title": "Rezultatas",
        "upload_button_text": "Įkelti nuotrauką",
        "upload_title": "Nufotografuokite"
    },
    "lv": {
        "background_removal_error": "Kļūda fona noņemšanas laikā",
        "common_subtitle": "Vispasaules pases/vīzu fotoattēli",
        "countries_ad": "Andora",
        "countries_ae": "Apvienotie Arābu Emirāti",
        "countries_al": "Albānija",
        "countries_am": "Armēnija",
        "countries_at": "Austrija",
        "countries_au": "Austrālija",
        "countries_az": "Azerbaidžāna",
        "countries_ba": "Bosnija Hercegovina",
        "countries_be": "Beļģija",
        "countries_bg": "Bulgārija",
        "countries_br": "Brazīlija",
        "countries_ch": "Šveice",
        "countries_cn": "Ķīna",
        "countries_cy": "Kipra",
        "countries_cz": "Čehija",
        "countries_de": "Vācija",
        "countries_dk": "Dānija",
        "countries_ee": "Igaunija",
        "countries_es": "Spānija",
        "countries_fi": "Somija",
        "countries_fr": "Francija",
        "countries_ge": "Gruzija",
        "countries_gr": "Grieķija",
        "countries_hr": "Horvātija",
        "countries_hu": "Ungārija",
        "countries_id": "Indonēzija",
        "countries_ie": "Īrija",
        "countries_il": "Izraēla",
        "countries_in": "Indija",
        "countries_ir": "Irāna",
        "countries_is": "Islande",
        "countries_it": "Itālija",
        "countries_jo": "Jordānija",
        "countries_jp": "Japāna",
        "countries_kr": "Koreja",
        "countries_kz": "Kazahstāna",
        "countries_lb": "Libāna",
        "countries_li": "Lihtenšteina",
        "countries_lt": "Lietuva",
        "countries_lu": "Luksemburga",
        "countries_lv": "Latvija",
        "countries_mc": "Monako",
        "countries_md": "Moldova",
        "countries_me": "Melnkalne",
        "countries_mk": "Maķedonija",
        "countries_mt": "Malta",
        "countries_my": "Malaizija",
        "countries_ng": "Nigērija",
        "countries_nl": "Nīderlande",
        "countries_no": "Norvēģija",
        "countries_ph": "Filipīnas",
        "countries_pl": "Polija",
        "countries_pt": "Portugāle",
        "countries_ro": "Rumānija",
        "countries_rs": "Serbija",
        "countries_ru": "Krievija",
        "countries_sa": "Saūda Arābija",
        "countries_se": "Zviedrija",
        "countries_si": "Slovēnija",
        "countries_sk": "Slovākija",
        "countries_sm": "Sanmarīno",
        "countries_th": "Taizeme",
        "countries_title": "Izvēlieties savu valsti",
        "countries_tr": "Turcija",
        "countries_ua": "Ukraina",
        "countries_uk": "Apvienotā Karaliste",
        "countries_us": "ASV",
        "countries_vn": "Vjetnama",
        "countries_xk": "Kosova",
        "country_options_choose_lang": "Izvēlieties citu valsti",
        "country_options_gt10": "11 un vairāk",
        "country_options_gt5": "5 un vairāk",
        "country_options_let10": "jaunāki par 11 gadiem",
        "country_options_let5": "līdz 5 gadiem",
        "country_options_oci": "OCI",
        "country_options_online": "Augšupielādēt",
        "country_options_printed": "Iespiests",
        "country_options_title": "Izvēlieties savu fotoattēla veidu",
        "email_subject": "Tavas fotogrāfijas",
        "email_text": "Jūsu fotoattēli tiek nosūtīti jums pa e-pastu. Paldies, ka izmantojāt SelfIDeed!",
        "email_with_images": "Digitālās pases fotoattēlu instrukcijas",
        "face_alignment_error_description": "AI nevar apstrādāt jūsu fotoattēlu, neradot problēmas. Lūdzu, mēģiniet vēlreiz virzīties tālāk no kameras.",
        "face_alignment_error_title": "Sejas līdzināšanas kļūda",
        "hints_description_1": "Turiet savu mobilo rokas stiepiena attālumā un acu līmenī...",
        "hints_description_2": "...pret dienas gaismu.",
        "hints_description_3": "Izslēdziet zibspuldzi.",
        "hints_next_button": "Turpināt",
        "hints_next_button_waiting": "Apsveriet noteikumus ...",
        "hints_subtitle": "Instrukcijas",
        "hints_title": "Noteikumi",
        "image_resolution_too_small_description": "Attēla izšķirtspēja ir pārāk maza, lai atbilstu prasībām.",
        "image_resolution_too_small_title": "Attēla izšķirtspēja ir pārāk maza",
        "landing_digital_description": "Izmantojiet, lai augšupielādētu fotoattēlu",
        "landing_digital_header": "Digitālā versija",
        "landing_next_button": "Mēģiniet tagad",
        "landing_passport_description": "Izmantojiet, lai izdrukātu sevi uz 10 x 15 cm fotopapīra ...vai... ļaujiet to izdrukāt fotoattēlu drukas servisā",
        "landing_passport_header": "Pases fotogrāfijas",
        "landing_title": "Izveidojiet savus pases fotoattēlus tiešsaistē",
        "no_face_error": "Mūsu algoritms nevarēja atrast seju — lūdzu, mēģiniet vēlreiz,",
        "preview_checked_photo": "Es pārbaudīju savu fotoattēlu un viss ir kārtībā",
        "preview_error_title": "Radās kļūdas!",
        "preview_offer": "Mēs tieši nosūtīsim digitālo pases fotoattēlu kopā ar izdrukātajām pases fotogrāfijām par 4,95 eiro.",
        "preview_purchase_button": "Pirkums",
        "preview_retry_button": "Mēģini vēlreiz",
        "preview_tc_1": "Es piekrītu",
        "preview_tc_2": "Noteikumi",
        "preview_tc_3": "un",
        "preview_tc_4": "Privātuma politika",
        "preview_title": "Pārbaudiet savu fotoattēlu",
        "processing_hint": "Mūsu AI apstrādā jūsu fotoattēlu...",
        "processing_title": "Apstrāde",
        "result_download_button": "Lejupielādēt",
        "result_download_text": "Jūsu fotoattēli ir nosūtīti jums pa e-pastu. Paldies, ka izmantojāt SelfIDeed!",
        "result_title": "Rezultāts",
        "upload_button_text": "Augšupielādēt foto",
        "upload_title": "Uzņemiet savu fotoattēlu"
    },
    "my": {
        "background_removal_error": "Ralat semasa mengalih keluar latar belakang",
        "common_subtitle": "Foto Pasport/Visa Seluruh Dunia",
        "countries_ad": "Andorra",
        "countries_ae": "Emiriah Arab Bersatu",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbaijan",
        "countries_ba": "Bosnia Herzegovina",
        "countries_be": "Belgium",
        "countries_bg": "Bulgaria",
        "countries_br": "Brazil",
        "countries_ch": "Switzerland",
        "countries_cn": "China",
        "countries_cy": "Cyprus",
        "countries_cz": "Czechia",
        "countries_de": "Jerman",
        "countries_dk": "Denmark",
        "countries_ee": "Estonia",
        "countries_es": "Sepanyol",
        "countries_fi": "Finland",
        "countries_fr": "Perancis",
        "countries_ge": "Georgia",
        "countries_gr": "Greece",
        "countries_hr": "Croatia",
        "countries_hu": "Hungary",
        "countries_id": "Indonesia",
        "countries_ie": "Ireland",
        "countries_il": "Israel",
        "countries_in": "India",
        "countries_ir": "Iran",
        "countries_is": "Iceland",
        "countries_it": "Itali",
        "countries_jo": "Jordan",
        "countries_jp": "Jepun",
        "countries_kr": "Korea",
        "countries_kz": "Kazakhstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Lithuania",
        "countries_lu": "Luxembourg",
        "countries_lv": "Latvia",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Belanda",
        "countries_no": "Norway",
        "countries_ph": "Filipina",
        "countries_pl": "Poland",
        "countries_pt": "Portugal",
        "countries_ro": "Romenia",
        "countries_rs": "Serbia",
        "countries_ru": "Rusia",
        "countries_sa": "Arab Saudi",
        "countries_se": "Sweden",
        "countries_si": "Slovenia",
        "countries_sk": "Slovakia",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Pilih negara anda",
        "countries_tr": "Turki",
        "countries_ua": "Ukraine",
        "countries_uk": "United Kingdom",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Pilih negara lain",
        "country_options_gt10": "11 dan lebih",
        "country_options_gt5": "5 tahun dan ke atas",
        "country_options_let10": "bawah 11 tahun",
        "country_options_let5": "bawah 5 tahun",
        "country_options_oci": "OCI",
        "country_options_online": "Muat naik",
        "country_options_printed": "Dicetak",
        "country_options_title": "Pilih Jenis Foto Anda",
        "email_subject": "Foto Anda",
        "email_text": "Foto anda dihantar kepada anda melalui e-mel. Terima kasih kerana menggunakan SelfIDeed!",
        "email_with_images": "Arahan Foto Pasport Digital",
        "face_alignment_error_description": "Foto anda tidak boleh diproses oleh AI tanpa menyebabkan masalah. Sila cuba lagi bergerak lebih jauh dari kamera.",
        "face_alignment_error_title": "Ralat Penjajaran Muka",
        "hints_description_1": "Pegang telefon bimbit anda pada paras lengan & mata...",
        "hints_description_2": "... menuju siang hari.",
        "hints_description_3": "Matikan denyar.",
        "hints_next_button": "teruskan",
        "hints_next_button_waiting": "Pertimbangkan peraturan...",
        "hints_subtitle": "Arahan",
        "hints_title": "Peraturan",
        "image_resolution_too_small_description": "Peleraian imej terlalu kecil untuk memenuhi keperluan.",
        "image_resolution_too_small_title": "Peleraian Imej Terlalu Kecil",
        "landing_digital_description": "Gunakan untuk memuat naik foto anda",
        "landing_digital_header": "Versi Digital",
        "landing_next_button": "Cuba sekarang",
        "landing_passport_description": "Gunakan untuk mencetak sendiri pada kertas foto 10 x 15 cm ...atau... biarkan ia mencetak di perkhidmatan cetakan foto",
        "landing_passport_header": "Gambar Pasport",
        "landing_title": "Buat Foto Pasport Anda Dalam Talian",
        "no_face_error": "Wajah tidak dapat ditemui oleh algoritma kami - sila cuba lagi,",
        "preview_checked_photo": "Saya menyemak foto saya dan ia OK",
        "preview_error_title": "Ralat Berlaku!",
        "preview_offer": "Kami akan terus e-mel gambar pasport digital, bersama-sama dengan gambar pasport cetak sendiri dengan harga 4.95 Euro",
        "preview_purchase_button": "Belian",
        "preview_retry_button": "Cuba lagi",
        "preview_tc_1": "Saya bersetuju dengan",
        "preview_tc_2": "Terma & Syarat",
        "preview_tc_3": "dan juga",
        "preview_tc_4": "Dasar Privasi",
        "preview_title": "Semak Foto Anda",
        "processing_hint": "AI kami sedang memproses foto anda...",
        "processing_title": "Memproses",
        "result_download_button": "Muat turun",
        "result_download_text": "Foto anda telah dihantar kepada anda melalui e-mel. Terima kasih kerana menggunakan SelfIDeed!",
        "result_title": "Hasilnya",
        "upload_button_text": "Muatnaik gambar",
        "upload_title": "Ambil Foto Anda"
    },
    "nl": {
        "background_removal_error": "Fout tijdens het verwijderen van de achtergrond",
        "common_subtitle": "Wereldwijde paspoort-/visumfoto's",
        "countries_ad": "Andorra",
        "countries_ae": "Verenigde Arabische Emiraten",
        "countries_al": "Albanië",
        "countries_am": "Armenië",
        "countries_at": "Oostenrijk",
        "countries_au": "Australië",
        "countries_az": "Azerbeidzjan",
        "countries_ba": "Bosnië-Herzegovina",
        "countries_be": "België",
        "countries_bg": "Bulgarije",
        "countries_br": "Brazilië",
        "countries_ch": "Zwitserland",
        "countries_cn": "China",
        "countries_cy": "Cyprus",
        "countries_cz": "Tsjechië",
        "countries_de": "Duitsland",
        "countries_dk": "Denemarken",
        "countries_ee": "Estland",
        "countries_es": "Spanje",
        "countries_fi": "Finland",
        "countries_fr": "Frankrijk",
        "countries_ge": "Georgië",
        "countries_gr": "Griekenland",
        "countries_hr": "Kroatië",
        "countries_hu": "Hongarije",
        "countries_id": "Indonesië",
        "countries_ie": "Ierland",
        "countries_il": "Israël",
        "countries_in": "Indië",
        "countries_ir": "Iran",
        "countries_is": "IJsland",
        "countries_it": "Italië",
        "countries_jo": "Jordanië",
        "countries_jp": "Japan",
        "countries_kr": "Korea",
        "countries_kz": "Kazachstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litouwen",
        "countries_lu": "Luxemburg",
        "countries_lv": "Letland",
        "countries_mc": "Monaco",
        "countries_md": "Moldavië",
        "countries_me": "Montenegro",
        "countries_mk": "Macedonië",
        "countries_mt": "Malta",
        "countries_my": "Maleisië",
        "countries_ng": "Nigeria",
        "countries_nl": "Nederland",
        "countries_no": "Noorwegen",
        "countries_ph": "Filippijnen",
        "countries_pl": "Polen",
        "countries_pt": "Portugal",
        "countries_ro": "Roemenië",
        "countries_rs": "Servië",
        "countries_ru": "Rusland",
        "countries_sa": "Saoedi-Arabië",
        "countries_se": "Zweden",
        "countries_si": "Slovenië",
        "countries_sk": "Slowakije",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Kies je land",
        "countries_tr": "Turkije",
        "countries_ua": "Oekraïne",
        "countries_uk": "Verenigd Koninkrijk",
        "countries_us": "VS",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Kies een ander land",
        "country_options_gt10": "11 en ouder",
        "country_options_gt5": "5 en ouder",
        "country_options_let10": "onder de 11 jaar",
        "country_options_let5": "onder de 5 jaar",
        "country_options_oci": "OCI",
        "country_options_online": "Uploaden",
        "country_options_printed": "Gedrukt",
        "country_options_title": "Kies uw fototype",
        "email_subject": "Jouw foto's",
        "email_text": "Uw foto's worden per e-mail naar u verzonden. Bedankt dat u SelfIDeed gebruikt!",
        "email_with_images": "Instructies voor digitale pasfoto",
        "face_alignment_error_description": "Uw foto kan niet zonder problemen door AI worden verwerkt. Probeer het opnieuw en ga verder van de camera af.",
        "face_alignment_error_title": "Fout bij gezichtsuitlijning",
        "hints_description_1": "Houd uw mobiel op armlengte en ooghoogte...",
        "hints_description_2": "...naar daglicht.",
        "hints_description_3": "Schakel flits uit.",
        "hints_next_button": "Doorgaan",
        "hints_next_button_waiting": "Denk aan de regels...",
        "hints_subtitle": "Instructies",
        "hints_title": "Reglement",
        "image_resolution_too_small_description": "De beeldresolutie is te klein om aan de vereisten te voldoen.",
        "image_resolution_too_small_title": "Beeldresolutie te klein",
        "landing_digital_description": "Gebruiken voor het uploaden van uw foto",
        "landing_digital_header": "Digitale versie",
        "landing_next_button": "Probeer nu",
        "landing_passport_description": "Gebruik deze om zelf af te drukken op fotopapier van 10 x 15 cm ...of... laat deze afdrukken bij een fotoafdrukservice",
        "landing_passport_header": "Pasfoto's",
        "landing_title": "Maak uw pasfoto's online",
        "no_face_error": "Gezicht kan niet worden gevonden door ons algoritme. Probeer het opnieuw.",
        "preview_checked_photo": "Ik heb mijn foto gecontroleerd en deze is in orde",
        "preview_error_title": "Er zijn fouten opgetreden!",
        "preview_offer": "De digitale pasfoto sturen wij direct per e-mail, samen met de zelfafdrukbare pasfoto's voor 4,95 Euro",
        "preview_purchase_button": "Aankoop",
        "preview_retry_button": "Probeer het nog eens",
        "preview_tc_1": "Ik ga akkoord met de",
        "preview_tc_2": "algemene voorwaarden",
        "preview_tc_3": "en de",
        "preview_tc_4": "Privacybeleid",
        "preview_title": "Controleer uw foto",
        "processing_hint": "Onze AI verwerkt uw foto...",
        "processing_title": "Verwerken",
        "result_download_button": "Downloaden",
        "result_download_text": "Uw foto's zijn per e-mail naar u verzonden. Bedankt dat u SelfIDeed gebruikt!",
        "result_title": "Resultaat",
        "upload_button_text": "Upload foto",
        "upload_title": "Maak uw foto"
    },
    "no": {
        "background_removal_error": "Feil under fjerning av bakgrunnen",
        "common_subtitle": "Verdensomspennende pass/visumbilder",
        "countries_ad": "Andorra",
        "countries_ae": "De forente arabiske emirater",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Østerrike",
        "countries_au": "Australia",
        "countries_az": "Aserbajdsjan",
        "countries_ba": "Bosnia Hercegovina",
        "countries_be": "Belgia",
        "countries_bg": "Bulgaria",
        "countries_br": "Brasil",
        "countries_ch": "Sveits",
        "countries_cn": "Kina",
        "countries_cy": "Kypros",
        "countries_cz": "Tsjekkia",
        "countries_de": "Tyskland",
        "countries_dk": "Danmark",
        "countries_ee": "Estland",
        "countries_es": "Spania",
        "countries_fi": "Finland",
        "countries_fr": "Frankrike",
        "countries_ge": "Georgia",
        "countries_gr": "Hellas",
        "countries_hr": "Kroatia",
        "countries_hu": "Ungarn",
        "countries_id": "Indonesia",
        "countries_ie": "Irland",
        "countries_il": "Israel",
        "countries_in": "India",
        "countries_ir": "Iran",
        "countries_is": "Island",
        "countries_it": "Italia",
        "countries_jo": "Jordan",
        "countries_jp": "Japan",
        "countries_kr": "Korea",
        "countries_kz": "Kasakstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litauen",
        "countries_lu": "Luxembourg",
        "countries_lv": "Latvia",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Makedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Nederland",
        "countries_no": "Norge",
        "countries_ph": "Filippinene",
        "countries_pl": "Polen",
        "countries_pt": "Portugal",
        "countries_ro": "Romania",
        "countries_rs": "Serbia",
        "countries_ru": "Russland",
        "countries_sa": "Saudi-Arabia",
        "countries_se": "Sverige",
        "countries_si": "Slovenia",
        "countries_sk": "Slovakia",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Velg landet ditt",
        "countries_tr": "Tyrkia",
        "countries_ua": "Ukraina",
        "countries_uk": "Storbritannia",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Velg et annet land",
        "country_options_gt10": "11 og over",
        "country_options_gt5": "5 og over",
        "country_options_let10": "under 11 år",
        "country_options_let5": "under 5 år",
        "country_options_oci": "OCI",
        "country_options_online": "Laste opp",
        "country_options_printed": "Skrevet ut",
        "country_options_title": "Velg bildetype",
        "email_subject": "Dine bilder",
        "email_text": "Bildene dine sendes til deg på e-post. Takk for at du bruker SelfIDeed!",
        "email_with_images": "Digital passfoto instruksjoner",
        "face_alignment_error_description": "Bildet ditt kan ikke behandles av AI uten å forårsake problemer. Prøv igjen å bevege deg lenger fra kameraet.",
        "face_alignment_error_title": "Feil ved ansiktsjustering",
        "hints_description_1": "Hold mobilen i armlengdes avstand og øyehøyde...",
        "hints_description_2": "...mot dagslys.",
        "hints_description_3": "Slå av blitsen.",
        "hints_next_button": "Fortsette",
        "hints_next_button_waiting": "Tenk på reglene...",
        "hints_subtitle": "Bruksanvisning",
        "hints_title": "Regler",
        "image_resolution_too_small_description": "Bildeoppløsningen er for liten til å oppfylle kravene.",
        "image_resolution_too_small_title": "Bildeoppløsningen er for liten",
        "landing_digital_description": "Bruk for å laste opp bildet ditt",
        "landing_digital_header": "Digital versjon",
        "landing_next_button": "Prøv nå",
        "landing_passport_description": "Bruk til å skrive ut selv på 10 x 15 cm fotopapir ... eller... la det skrives ut hos en fotoutskriftstjeneste",
        "landing_passport_header": "Passbilder",
        "landing_title": "Lag passbilder online",
        "no_face_error": "Ansiktet ble ikke funnet av algoritmen vår - prøv igjen,",
        "preview_checked_photo": "Jeg sjekket bildet mitt og det er OK",
        "preview_error_title": "Det oppstod feil!",
        "preview_offer": "Vi sender det digitale passbildet direkte på e-post, sammen med print-selv-passbildene for 4,95 Euro",
        "preview_purchase_button": "Kjøp",
        "preview_retry_button": "Prøv igjen",
        "preview_tc_1": "Jeg er enig i",
        "preview_tc_2": "Betingelser og vilkår",
        "preview_tc_3": "og",
        "preview_tc_4": "Personvernerklæring",
        "preview_title": "Sjekk bildet ditt",
        "processing_hint": "Vår AI behandler bildet ditt...",
        "processing_title": "Behandling",
        "result_download_button": "nedlasting",
        "result_download_text": "Bildene dine har blitt sendt til deg på e-post. Takk for at du bruker SelfIDeed!",
        "result_title": "Resultat",
        "upload_button_text": "Last opp bilde",
        "upload_title": "Ta bildet ditt"
    },
    "pl": {
        "background_removal_error": "Błąd podczas usuwania tła",
        "common_subtitle": "Zdjęcia do paszportów/wiz z całego świata",
        "countries_ad": "Andora",
        "countries_ae": "Zjednoczone Emiraty Arabskie",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbejdżan",
        "countries_ba": "Bośnia i Hercegowina",
        "countries_be": "Belgia",
        "countries_bg": "Bułgaria",
        "countries_br": "Brazylia",
        "countries_ch": "Szwajcaria",
        "countries_cn": "Chiny",
        "countries_cy": "Cypr",
        "countries_cz": "Czechy",
        "countries_de": "Niemcy",
        "countries_dk": "Dania",
        "countries_ee": "Estonia",
        "countries_es": "Hiszpania",
        "countries_fi": "Finlandia",
        "countries_fr": "Francja",
        "countries_ge": "Gruzja",
        "countries_gr": "Grecja",
        "countries_hr": "Chorwacja",
        "countries_hu": "Węgry",
        "countries_id": "Indonezja",
        "countries_ie": "Irlandia",
        "countries_il": "Izrael",
        "countries_in": "Indie",
        "countries_ir": "Iran",
        "countries_is": "Islandia",
        "countries_it": "Włochy",
        "countries_jo": "Jordania",
        "countries_jp": "Japonia",
        "countries_kr": "Korea",
        "countries_kz": "Kazachstan",
        "countries_lb": "Liban",
        "countries_li": "Lichtensteina",
        "countries_lt": "Litwa",
        "countries_lu": "Luksemburg",
        "countries_lv": "Łotwa",
        "countries_mc": "Monako",
        "countries_md": "Moldova",
        "countries_me": "Czarnogóra",
        "countries_mk": "Macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malezja",
        "countries_ng": "Nigeria",
        "countries_nl": "Holandia",
        "countries_no": "Norwegia",
        "countries_ph": "Filipiny",
        "countries_pl": "Polska",
        "countries_pt": "Portugalia",
        "countries_ro": "Rumunia",
        "countries_rs": "Serbia",
        "countries_ru": "Rosja",
        "countries_sa": "Arabia Saudyjska",
        "countries_se": "Szwecja",
        "countries_si": "Słowenia",
        "countries_sk": "Słowacja",
        "countries_sm": "San Marino",
        "countries_th": "Tajlandia",
        "countries_title": "Wybierz swój kraj",
        "countries_tr": "Turcja",
        "countries_ua": "Ukraina",
        "countries_uk": "Zjednoczone Królestwo",
        "countries_us": "USA",
        "countries_vn": "Wietnam",
        "countries_xk": "Kosowo",
        "country_options_choose_lang": "Wybierz inny kraj",
        "country_options_gt10": "11 i więcej",
        "country_options_gt5": "5 lat i więcej",
        "country_options_let10": "poniżej 11 lat",
        "country_options_let5": "poniżej 5 lat",
        "country_options_oci": "OCI",
        "country_options_online": "Wgrywać",
        "country_options_printed": "Drukowane",
        "country_options_title": "Wybierz typ zdjęcia",
        "email_subject": "Twoje zdjęcia",
        "email_text": "Twoje zdjęcia zostaną przesłane do Ciebie e-mailem. Dziękujemy za korzystanie z SelfIDeed!",
        "email_with_images": "Instrukcje dotyczące cyfrowego zdjęcia paszportowego",
        "face_alignment_error_description": "Twoje zdjęcie nie może zostać przetworzone przez sztuczną inteligencję bez powodowania problemów. Spróbuj ponownie, oddalając się od aparatu.",
        "face_alignment_error_title": "Błąd wyrównania twarzy",
        "hints_description_1": "Trzymaj telefon komórkowy na wyciągnięcie ręki i na poziomie oczu...",
        "hints_description_2": "...w stronę światła dziennego.",
        "hints_description_3": "Wyłącz lampę błyskową.",
        "hints_next_button": "Kontynuować",
        "hints_next_button_waiting": "Weź pod uwagę zasady...",
        "hints_subtitle": "Instrukcje",
        "hints_title": "Zasady",
        "image_resolution_too_small_description": "Rozdzielczość obrazu jest zbyt mała, aby spełnić wymagania.",
        "image_resolution_too_small_title": "Rozdzielczość obrazu jest zbyt mała",
        "landing_digital_description": "Użyj, aby przesłać swoje zdjęcie",
        "landing_digital_header": "Wersja cyfrowa",
        "landing_next_button": "Spróbuj teraz",
        "landing_passport_description": "Użyj, aby wydrukować siebie na papierze fotograficznym 10 x 15 cm... lub... zleć wydruk w drukarni fotograficznej",
        "landing_passport_header": "Zdjęcia paszportowe",
        "landing_title": "Zrób zdjęcia paszportowe online",
        "no_face_error": "Nasz algorytm nie mógł znaleźć twarzy - spróbuj ponownie,",
        "preview_checked_photo": "Sprawdziłem swoje zdjęcie i jest OK",
        "preview_error_title": "Wystąpiły błędy!",
        "preview_offer": "Wyślemy bezpośrednio e-mailem cyfrowe zdjęcie paszportowe wraz ze zdjęciami paszportowymi do samodzielnego wydrukowania za 4,95 euro",
        "preview_purchase_button": "Zakup",
        "preview_retry_button": "Spróbuj ponownie",
        "preview_tc_1": "Zgadzam się",
        "preview_tc_2": "Zasady i Warunki",
        "preview_tc_3": "i",
        "preview_tc_4": "Polityka prywatności",
        "preview_title": "Sprawdź swoje zdjęcie",
        "processing_hint": "Nasza sztuczna inteligencja przetwarza Twoje zdjęcie...",
        "processing_title": "Przetwarzanie",
        "result_download_button": "Pobierać",
        "result_download_text": "Twoje zdjęcia zostały wysłane do Ciebie e-mailem. Dziękujemy za korzystanie z SelfIDeed!",
        "result_title": "Wynik",
        "upload_button_text": "Prześlij zdjęcie",
        "upload_title": "Zrób zdjęcie"
    },
    "pt": {
        "background_removal_error": "Erro durante a remoção do plano de fundo",
        "common_subtitle": "Fotos de passaporte/visto em todo o mundo",
        "countries_ad": "Andorra",
        "countries_ae": "Emirados Árabes Unidos",
        "countries_al": "Albânia",
        "countries_am": "Armênia",
        "countries_at": "Áustria",
        "countries_au": "Austrália",
        "countries_az": "Azerbaijão",
        "countries_ba": "Bósnia e Herzegovina",
        "countries_be": "Bélgica",
        "countries_bg": "Bulgária",
        "countries_br": "Brasil",
        "countries_ch": "Suíça",
        "countries_cn": "China",
        "countries_cy": "Chipre",
        "countries_cz": "Tcheca",
        "countries_de": "Alemanha",
        "countries_dk": "Dinamarca",
        "countries_ee": "Estônia",
        "countries_es": "Espanha",
        "countries_fi": "Finlândia",
        "countries_fr": "França",
        "countries_ge": "Geórgia",
        "countries_gr": "Grécia",
        "countries_hr": "Croácia",
        "countries_hu": "Hungria",
        "countries_id": "Indonésia",
        "countries_ie": "Irlanda",
        "countries_il": "Israel",
        "countries_in": "Índia",
        "countries_ir": "Irã",
        "countries_is": "Islândia",
        "countries_it": "Itália",
        "countries_jo": "Jordânia",
        "countries_jp": "Japão",
        "countries_kr": "Coréia",
        "countries_kz": "Cazaquistão",
        "countries_lb": "Líbano",
        "countries_li": "Lichtenstein",
        "countries_lt": "Lituânia",
        "countries_lu": "Luxemburgo",
        "countries_lv": "Letônia",
        "countries_mc": "Mônaco",
        "countries_md": "Moldávia",
        "countries_me": "Montenegro",
        "countries_mk": "Macedônia",
        "countries_mt": "Malta",
        "countries_my": "Malásia",
        "countries_ng": "Nigéria",
        "countries_nl": "Holanda",
        "countries_no": "Noruega",
        "countries_ph": "Filipinas",
        "countries_pl": "Polônia",
        "countries_pt": "Portugal",
        "countries_ro": "Romênia",
        "countries_rs": "Sérvia",
        "countries_ru": "Rússia",
        "countries_sa": "Arábia Saudita",
        "countries_se": "Suécia",
        "countries_si": "Eslovênia",
        "countries_sk": "Eslováquia",
        "countries_sm": "São Marino",
        "countries_th": "Tailândia",
        "countries_title": "Escolha seu país",
        "countries_tr": "Turquia",
        "countries_ua": "Ucrânia",
        "countries_uk": "Reino Unido",
        "countries_us": "EUA",
        "countries_vn": "Vietnã",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Escolha um país diferente",
        "country_options_gt10": "11 anos ou mais",
        "country_options_gt5": "5 e acima",
        "country_options_let10": "menores de 11 anos",
        "country_options_let5": "menores de 5 anos",
        "country_options_oci": "OCI",
        "country_options_online": "Carregar",
        "country_options_printed": "Impresso",
        "country_options_title": "Escolha o seu tipo de foto",
        "email_subject": "Suas fotos",
        "email_text": "Suas fotos são enviadas para você por e-mail. Obrigado por usar o SelfIDeed!",
        "email_with_images": "Instruções para foto do passaporte digital",
        "face_alignment_error_description": "Sua foto não pode ser processada pela IA sem causar problemas. Tente novamente se afastar da câmera.",
        "face_alignment_error_title": "Erro de alinhamento facial",
        "hints_description_1": "Segure seu celular com o braço estendido e na altura dos olhos...",
        "hints_description_2": "...em direção ao amanhecer.",
        "hints_description_3": "Desligue o flash.",
        "hints_next_button": "Continuar",
        "hints_next_button_waiting": "Considere as regras...",
        "hints_subtitle": "Instruções",
        "hints_title": "Regras",
        "image_resolution_too_small_description": "A resolução da imagem é muito pequena para atender aos requisitos.",
        "image_resolution_too_small_title": "Resolução de imagem muito pequena",
        "landing_digital_description": "Use para enviar sua foto",
        "landing_digital_header": "Versão Digital",
        "landing_next_button": "Tente agora",
        "landing_passport_description": "Use para imprimir em papel fotográfico 10 x 15 cm ...ou... deixe imprimir em um serviço de impressão de fotos",
        "landing_passport_header": "Fotos de passaporte",
        "landing_title": "Faça suas fotos de passaporte online",
        "no_face_error": "O rosto não foi encontrado pelo nosso algoritmo. Tente novamente.",
        "preview_checked_photo": "Verifiquei minha foto e está tudo bem",
        "preview_error_title": "Erros ocorridos!",
        "preview_offer": "Enviaremos diretamente por e-mail a foto digital do passaporte, juntamente com as fotos do passaporte para impressão por 4,95 euros",
        "preview_purchase_button": "Comprar",
        "preview_retry_button": "Tente novamente",
        "preview_tc_1": "Eu concordo com o",
        "preview_tc_2": "termos e Condições",
        "preview_tc_3": "e a",
        "preview_tc_4": "política de Privacidade",
        "preview_title": "Verifique sua foto",
        "processing_hint": "Nossa IA está processando sua foto...",
        "processing_title": "Em processamento",
        "result_download_button": "Download",
        "result_download_text": "Suas fotos foram enviadas para você por e-mail. Obrigado por usar o SelfIDeed!",
        "result_title": "Resultado",
        "upload_button_text": "Carregar foto",
        "upload_title": "Tire sua foto"
    },
    "ro": {
        "background_removal_error": "Eroare la eliminarea fundalului",
        "common_subtitle": "Fotografii din pașapoarte/viză la nivel mondial",
        "countries_ad": "Andorra",
        "countries_ae": "Emiratele Arabe Unite",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Austria",
        "countries_au": "Australia",
        "countries_az": "Azerbaidjan",
        "countries_ba": "Bosnia Herţegovina",
        "countries_be": "Belgia",
        "countries_bg": "Bulgaria",
        "countries_br": "Brazilia",
        "countries_ch": "Elveţia",
        "countries_cn": "China",
        "countries_cy": "Cipru",
        "countries_cz": "Cehia",
        "countries_de": "Germania",
        "countries_dk": "Danemarca",
        "countries_ee": "Estonia",
        "countries_es": "Spania",
        "countries_fi": "Finlanda",
        "countries_fr": "Franţa",
        "countries_ge": "Georgia",
        "countries_gr": "Grecia",
        "countries_hr": "Croaţia",
        "countries_hu": "Ungaria",
        "countries_id": "Indonezia",
        "countries_ie": "Irlanda",
        "countries_il": "Israel",
        "countries_in": "India",
        "countries_ir": "Iranul",
        "countries_is": "Islanda",
        "countries_it": "Italia",
        "countries_jo": "Iordania",
        "countries_jp": "Japonia",
        "countries_kr": "Coreea",
        "countries_kz": "Kazahstan",
        "countries_lb": "Liban",
        "countries_li": "Lichtenstein",
        "countries_lt": "Lituania",
        "countries_lu": "Luxemburg",
        "countries_lv": "Letonia",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Muntenegru",
        "countries_mk": "Macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaezia",
        "countries_ng": "Nigeria",
        "countries_nl": "Olanda",
        "countries_no": "Norvegia",
        "countries_ph": "Filipine",
        "countries_pl": "Polonia",
        "countries_pt": "Portugalia",
        "countries_ro": "Romania",
        "countries_rs": "Serbia",
        "countries_ru": "Rusia",
        "countries_sa": "Arabia Saudită",
        "countries_se": "Suedia",
        "countries_si": "Slovenia",
        "countries_sk": "Slovacia",
        "countries_sm": "San Marino",
        "countries_th": "Tailanda",
        "countries_title": "Alege-ți țara",
        "countries_tr": "Turcia",
        "countries_ua": "Ucraina",
        "countries_uk": "Regatul Unit",
        "countries_us": "STATELE UNITE ALE AMERICII",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Alege o altă țară",
        "country_options_gt10": "11 și peste",
        "country_options_gt5": "5 și peste",
        "country_options_let10": "sub 11 ani",
        "country_options_let5": "sub 5 ani",
        "country_options_oci": "OCI",
        "country_options_online": "Încărcați",
        "country_options_printed": "Imprimat",
        "country_options_title": "Alegeți tipul dvs. de fotografie",
        "email_subject": "Fotografiile tale",
        "email_text": "Fotografiile tale îți sunt trimise prin e-mail. Vă mulțumim că folosiți SelfIDeed!",
        "email_with_images": "Instrucțiuni de fotografiere a pașaportului digital",
        "face_alignment_error_description": "Fotografia ta nu poate fi procesată de AI fără a cauza probleme. Vă rugăm să încercați din nou să vă deplasați mai departe de cameră.",
        "face_alignment_error_title": "Eroare de aliniere a feței",
        "hints_description_1": "Țineți mobilul la distanță de braț și la nivelul ochilor...",
        "hints_description_2": "...spre lumina zilei.",
        "hints_description_3": "Opriți blițul.",
        "hints_next_button": "Continua",
        "hints_next_button_waiting": "Luați în considerare regulile...",
        "hints_subtitle": "Instrucțiuni",
        "hints_title": "Reguli",
        "image_resolution_too_small_description": "Rezoluția imaginii este prea mică pentru a se potrivi cerințelor.",
        "image_resolution_too_small_title": "Rezoluția imaginii este prea mică",
        "landing_digital_description": "Utilizați pentru încărcarea fotografiei dvs",
        "landing_digital_header": "Versiune digitală",
        "landing_next_button": "Încearcă acum",
        "landing_passport_description": "Folosiți-vă pentru a vă imprima pe hârtie foto de 10 x 15 cm ...sau... lăsați-l să se imprime la un serviciu de imprimare foto",
        "landing_passport_header": "Fotografii cu pașaport",
        "landing_title": "Fă-ți fotografiile din pașaport online",
        "no_face_error": "Fața nu a putut fi găsită de algoritmul nostru - vă rugăm să încercați din nou,",
        "preview_checked_photo": "Mi-am verificat fotografia si e ok",
        "preview_error_title": "Au apărut erori!",
        "preview_offer": "Vom trimite prin e-mail direct fotografia de pașaport digital, împreună cu fotografiile de pașaport imprimate de dvs. pentru 4,95 Euro",
        "preview_purchase_button": "Cumpărare",
        "preview_retry_button": "Încearcă din nou",
        "preview_tc_1": "Sunt de acord cu",
        "preview_tc_2": "termeni si conditii",
        "preview_tc_3": "si",
        "preview_tc_4": "Politica de confidențialitate",
        "preview_title": "Verificați-vă fotografia",
        "processing_hint": "AI-ul nostru vă procesează fotografia...",
        "processing_title": "Prelucrare",
        "result_download_button": "Descarca",
        "result_download_text": "Fotografiile tale ți-au fost trimise prin e-mail. Vă mulțumim că folosiți SelfIDeed!",
        "result_title": "Rezultat",
        "upload_button_text": "Încarcă fotografie",
        "upload_title": "Fă-ți fotografia"
    },
    "rs": {
        "background_removal_error": "Грешка током уклањања позадине",
        "common_subtitle": "Фотографије пасоша/виза широм света",
        "countries_ad": "Андора",
        "countries_ae": "Уједињени Арапски Емирати",
        "countries_al": "Албанија",
        "countries_am": "Јерменија",
        "countries_at": "Аустрија",
        "countries_au": "Аустралија",
        "countries_az": "Азербејџан",
        "countries_ba": "Босна и Херцеговина",
        "countries_be": "Белгија",
        "countries_bg": "Бугарска",
        "countries_br": "Бразил",
        "countries_ch": "Швајцарска",
        "countries_cn": "Кина",
        "countries_cy": "Кипар",
        "countries_cz": "Чешка",
        "countries_de": "Немачка",
        "countries_dk": "Данска",
        "countries_ee": "Естониа",
        "countries_es": "Спаин",
        "countries_fi": "Финланд",
        "countries_fr": "Француска",
        "countries_ge": "Георгиа",
        "countries_gr": "Грчка",
        "countries_hr": "Хрватска",
        "countries_hu": "Мађарска",
        "countries_id": "Индонесиа",
        "countries_ie": "Ирска",
        "countries_il": "Израел",
        "countries_in": "Индија",
        "countries_ir": "Иран",
        "countries_is": "Исланд",
        "countries_it": "Италија",
        "countries_jo": "Јордан",
        "countries_jp": "Јапан",
        "countries_kr": "Кореа",
        "countries_kz": "Казахстан",
        "countries_lb": "Либан",
        "countries_li": "Лихтенштајн",
        "countries_lt": "Литванија",
        "countries_lu": "Луксембург",
        "countries_lv": "Летонија",
        "countries_mc": "Монако",
        "countries_md": "Молдавија",
        "countries_me": "Црна Гора",
        "countries_mk": "Македонија",
        "countries_mt": "Малта",
        "countries_my": "Малезија",
        "countries_ng": "Нигериа",
        "countries_nl": "Низоземска",
        "countries_no": "Норвешка",
        "countries_ph": "Филипини",
        "countries_pl": "Пољска",
        "countries_pt": "Португал",
        "countries_ro": "Румунија",
        "countries_rs": "Србија",
        "countries_ru": "Русија",
        "countries_sa": "Саудијска Арабија",
        "countries_se": "Сведен",
        "countries_si": "Словенија",
        "countries_sk": "Словакиа",
        "countries_sm": "Сан-Марино",
        "countries_th": "Тајланд",
        "countries_title": "Изабери своју земљу",
        "countries_tr": "Турска",
        "countries_ua": "Украјина",
        "countries_uk": "Велика Британија",
        "countries_us": "сад",
        "countries_vn": "Вијетнам",
        "countries_xk": "Косово",
        "country_options_choose_lang": "Изаберите другу земљу",
        "country_options_gt10": "11 и више",
        "country_options_gt5": "5 и старше",
        "country_options_let10": "млађи од 11 година",
        "country_options_let5": "испод 5 година",
        "country_options_oci": "OCI",
        "country_options_online": "Отпремити",
        "country_options_printed": "Штампано",
        "country_options_title": "Одаберите свој тип фотографије",
        "email_subject": "Ваше слике",
        "email_text": "Ваше фотографије вам се шаљу е-поштом. Хвала вам што користите СелфИДеед!",
        "email_with_images": "Дигитална упутства за фотографије за пасош",
        "face_alignment_error_description": "АИ не може да обради вашу фотографију без изазивања проблема. Покушајте поново да се померите даље од камере.",
        "face_alignment_error_title": "Грешка у поравнању лица",
        "hints_description_1": "Држите свој мобилни на висини руке и очију...",
        "hints_description_2": "...према дневном светлу.",
        "hints_description_3": "Искључите блиц.",
        "hints_next_button": "Настави",
        "hints_next_button_waiting": "Узмите у обзир правила...",
        "hints_subtitle": "Упутства",
        "hints_title": "Правила",
        "image_resolution_too_small_description": "Резолуција слике је премала да би одговарала захтевима.",
        "image_resolution_too_small_title": "Резолуција слике је премала",
        "landing_digital_description": "Користите за отпремање ваше фотографије",
        "landing_digital_header": "Дигитална верзија",
        "landing_next_button": "Покушај сада",
        "landing_passport_description": "Користите да сами одштампате на фото-папиру 10 к 15 цм ... или... дозволите да се штампа у сервису за штампање фотографија",
        "landing_passport_header": "Фотографије за пасош",
        "landing_title": "Направите своје фотографије за пасош на мрежи",
        "no_face_error": "Наш алгоритам није могао да пронађе лице - покушајте поново,",
        "preview_checked_photo": "Проверио сам своју фотографију и у реду је",
        "preview_error_title": "Дошло је до грешака!",
        "preview_offer": "Дигиталну фотографију за пасош, заједно са одштампаним фотографијама за пасош, директно ћемо послати е-поштом за 4,95 евра",
        "preview_purchase_button": "Куповина",
        "preview_retry_button": "Покушајте поново",
        "preview_tc_1": "Слажем се са",
        "preview_tc_2": "Услови коришћења",
        "preview_tc_3": "анд тхе",
        "preview_tc_4": "Правила о приватности",
        "preview_title": "Проверите своју фотографију",
        "processing_hint": "Наша вештачка интелигенција обрађује вашу фотографију...",
        "processing_title": "Обрада",
        "result_download_button": "Преузимање",
        "result_download_text": "Ваше фотографије су вам послате е-поштом. Хвала вам што користите СелфИДеед!",
        "result_title": "Резултат",
        "upload_button_text": "Уплоад Пхото",
        "upload_title": "Снимите своју фотографију"
    },
    "ru": {
        "background_removal_error": "Ошибка при удалении фона",
        "common_subtitle": "Фотографии на паспорт/визы со всего мира",
        "countries_ad": "Андорра",
        "countries_ae": "Объединённые Арабские Эмираты",
        "countries_al": "Албания",
        "countries_am": "Армения",
        "countries_at": "Австрия",
        "countries_au": "Австралия",
        "countries_az": "Азербайджан",
        "countries_ba": "Босния Герцеговина",
        "countries_be": "Бельгия",
        "countries_bg": "Болгария",
        "countries_br": "Бразилия",
        "countries_ch": "Швейцария",
        "countries_cn": "Китай",
        "countries_cy": "Кипр",
        "countries_cz": "Чехия",
        "countries_de": "Германия",
        "countries_dk": "Дания",
        "countries_ee": "Эстония",
        "countries_es": "Испания",
        "countries_fi": "Финляндия",
        "countries_fr": "Франция",
        "countries_ge": "Грузия",
        "countries_gr": "Греция",
        "countries_hr": "Хорватия",
        "countries_hu": "Венгрия",
        "countries_id": "Индонезия",
        "countries_ie": "Ирландия",
        "countries_il": "Израиль",
        "countries_in": "Индия",
        "countries_ir": "Иран",
        "countries_is": "Исландия",
        "countries_it": "Италия",
        "countries_jo": "Иордания",
        "countries_jp": "Япония",
        "countries_kr": "Корея",
        "countries_kz": "Казахстан",
        "countries_lb": "Ливан",
        "countries_li": "Лихтенштейн",
        "countries_lt": "Литва",
        "countries_lu": "Люксембург",
        "countries_lv": "Латвия",
        "countries_mc": "Монако",
        "countries_md": "Молдова",
        "countries_me": "Черногория",
        "countries_mk": "Македония",
        "countries_mt": "Мальта",
        "countries_my": "Малайзия",
        "countries_ng": "Нигерия",
        "countries_nl": "Нидерланды",
        "countries_no": "Норвегия",
        "countries_ph": "Филиппины",
        "countries_pl": "Польша",
        "countries_pt": "Португалия",
        "countries_ro": "Ромения",
        "countries_rs": "Сербия",
        "countries_ru": "Россия",
        "countries_sa": "Саудовская Аравия",
        "countries_se": "Швеция",
        "countries_si": "Словения",
        "countries_sk": "Словакия",
        "countries_sm": "Сан-Марино",
        "countries_th": "Таиланд",
        "countries_title": "Выберите вашу страну",
        "countries_tr": "Турция",
        "countries_ua": "Украина",
        "countries_uk": "Великобритания",
        "countries_us": "США",
        "countries_vn": "Вьетнам",
        "countries_xk": "Косово",
        "country_options_choose_lang": "Выбрать другую страну",
        "country_options_gt10": "11 и старше",
        "country_options_gt5": "5 и выше",
        "country_options_let10": "до 11 лет",
        "country_options_let5": "младше 5 лет",
        "country_options_oci": "OCI",
        "country_options_online": "Загрузить",
        "country_options_printed": "Печатный",
        "country_options_title": "Выберите тип фотографии",
        "email_subject": "Ваши фотографии",
        "email_text": "Ваши фотографии отправлены вам по электронной почте. Благодарим вас за использование SelfIDeed!",
        "email_with_images": "Инструкции по фотографии для цифрового паспорта",
        "face_alignment_error_description": "Ваша фотография не может быть обработана ИИ без проблем. Пожалуйста, попробуйте еще раз отойти дальше от камеры.",
        "face_alignment_error_title": "Ошибка выравнивания лица",
        "hints_description_1": "Держите мобильный телефон на расстоянии вытянутой руки и на уровне глаз...",
        "hints_description_2": "... к дневному свету.",
        "hints_description_3": "Выключите вспышку.",
        "hints_next_button": "Продолжать",
        "hints_next_button_waiting": "Рассмотрим правила...",
        "hints_subtitle": "инструкции",
        "hints_title": "Правила",
        "image_resolution_too_small_description": "Разрешение изображения слишком мало для соответствия требованиям.",
        "image_resolution_too_small_title": "Разрешение изображения слишком маленькое",
        "landing_digital_description": "Используйте для загрузки вашей фотографии",
        "landing_digital_header": "Цифровая версия",
        "landing_next_button": "Попробуй",
        "landing_passport_description": "Используйте, чтобы распечатать себя на фотобумаге размером 10 x 15 см...или... распечатайте в фотоателье.",
        "landing_passport_header": "Фотографии на паспорт",
        "landing_title": "Сделайте фотографии на паспорт онлайн",
        "no_face_error": "Наш алгоритм не смог найти лицо. Повторите попытку.",
        "preview_checked_photo": "Я проверил свое фото, все в порядке",
        "preview_error_title": "Произошли ошибки!",
        "preview_offer": "Мы отправим по электронной почте цифровую фотографию паспорта вместе с распечатанными фотографиями паспорта за 4,95 евро.",
        "preview_purchase_button": "Покупка",
        "preview_retry_button": "Попробуйте еще раз",
        "preview_tc_1": "Я согласен на",
        "preview_tc_2": "Условия использования",
        "preview_tc_3": "и",
        "preview_tc_4": "политика конфиденциальности",
        "preview_title": "Проверьте свою фотографию",
        "processing_hint": "Наш искусственный интеллект обрабатывает вашу фотографию...",
        "processing_title": "Обработка",
        "result_download_button": "Скачать",
        "result_download_text": "Ваши фотографии были отправлены вам на электронную почту. Благодарим вас за использование SelfIDeed!",
        "result_title": "Результат",
        "upload_button_text": "Загрузить фото",
        "upload_title": "Сфотографируйтесь"
    },
    "sa": {
        "background_removal_error": "خطأ أثناء إزالة الخلفية",
        "common_subtitle": "صور جواز السفر/التأشيرة العالمية",
        "countries_ad": "أندورا",
        "countries_ae": "الإمارات العربية المتحدة",
        "countries_al": "ألبانيا",
        "countries_am": "أرمينيا",
        "countries_at": "النمسا",
        "countries_au": "أستراليا",
        "countries_az": "أذربيجان",
        "countries_ba": "البوسنة والهرسك",
        "countries_be": "بلجيكا",
        "countries_bg": "بلغاريا",
        "countries_br": "البرازيل",
        "countries_ch": "سويسرا",
        "countries_cn": "الصين",
        "countries_cy": "قبرص",
        "countries_cz": "التشيك",
        "countries_de": "ألمانيا",
        "countries_dk": "الدنمارك",
        "countries_ee": "إستونيا",
        "countries_es": "إسبانيا",
        "countries_fi": "فنلندا",
        "countries_fr": "فرنسا",
        "countries_ge": "جورجيا",
        "countries_gr": "اليونان",
        "countries_hr": "كرواتيا",
        "countries_hu": "هنغاريا",
        "countries_id": "إندونيسيا",
        "countries_ie": "أيرلندا",
        "countries_il": "إسرائيل",
        "countries_in": "الهند",
        "countries_ir": "إيران",
        "countries_is": "أيسلندا",
        "countries_it": "إيطاليا",
        "countries_jo": "الأردن",
        "countries_jp": "اليابان",
        "countries_kr": "كوريا",
        "countries_kz": "كازاخستان",
        "countries_lb": "لبنان",
        "countries_li": "ليختنشتاين",
        "countries_lt": "ليتوانيا",
        "countries_lu": "لوكسمبورغ",
        "countries_lv": "لاتفيا",
        "countries_mc": "موناكو",
        "countries_md": "مولدوفا",
        "countries_me": "الجبل الأسود",
        "countries_mk": "مقدونيا",
        "countries_mt": "مالطا",
        "countries_my": "ماليزيا",
        "countries_ng": "نيجيريا",
        "countries_nl": "هولندا",
        "countries_no": "النرويج",
        "countries_ph": "فيلبيني",
        "countries_pl": "بولندا",
        "countries_pt": "البرتغال",
        "countries_ro": "رومانيا",
        "countries_rs": "صربيا",
        "countries_ru": "روسيا",
        "countries_sa": "المملكة العربية السعودية",
        "countries_se": "السويد",
        "countries_si": "سلوفينيا",
        "countries_sk": "سلوفاكيا",
        "countries_sm": "سان مارينو",
        "countries_th": "تايلاند",
        "countries_title": "اختر بلدك",
        "countries_tr": "تركيا",
        "countries_ua": "أوكرانيا",
        "countries_uk": "المملكة المتحدة",
        "countries_us": "الولايات المتحدة الأمريكية",
        "countries_vn": "فيتنام",
        "countries_xk": "كوسوفو",
        "country_options_choose_lang": "اختر دولة مختلفة",
        "country_options_gt10": "11 وأكثر",
        "country_options_gt5": "5 سال به بالا",
        "country_options_let10": "تحت 11 سنة",
        "country_options_let5": "تحت 5 سنوات",
        "country_options_oci": "OCI",
        "country_options_online": "رفع",
        "country_options_printed": "مطبوعة",
        "country_options_title": "اختر نوع الصورة الخاصة بك",
        "email_subject": "صورك",
        "email_text": "يتم إرسال الصور الخاصة بك لك عن طريق البريد الإلكتروني. شكرا لاستخدامك SelfIDeed!",
        "email_with_images": "تعليمات صورة جواز السفر الرقمية",
        "face_alignment_error_description": "لا يمكن معالجة صورتك بواسطة الذكاء الاصطناعي دون التسبب في مشاكل. يرجى محاولة التحرك مرة أخرى بعيدًا عن الكاميرا.",
        "face_alignment_error_title": "خطأ في محاذاة الوجه",
        "hints_description_1": "أمسك هاتفك المحمول على مستوى الذراع وعلى مستوى العين..",
        "hints_description_2": ".. نحو ضوء النهار.",
        "hints_description_3": "قم بإيقاف تشغيل الفلاش.",
        "hints_next_button": "يكمل",
        "hints_next_button_waiting": "خذ بعين الاعتبار القواعد...",
        "hints_subtitle": "تعليمات",
        "hints_title": "قواعد",
        "image_resolution_too_small_description": "دقة الصورة صغيرة جدًا بحيث لا تتناسب مع المتطلبات.",
        "image_resolution_too_small_title": "دقة الصورة صغيرة جدًا",
        "landing_digital_description": "استخدم لتحميل صورتك",
        "landing_digital_header": "النسخة الرقمية",
        "landing_next_button": "جرب الآن",
        "landing_passport_description": "استخدمها للطباعة بنفسك على ورق صور مقاس 10 × 15 سم... أو... دعها تطبع في خدمة طباعة الصور",
        "landing_passport_header": "صور جواز السفر",
        "landing_title": "اصنع صور جواز السفر الخاصة بك على الإنترنت",
        "no_face_error": "تعذر العثور على الوجه بواسطة الخوارزمية الخاصة بنا - يرجى المحاولة مرة أخرى،",
        "preview_checked_photo": "لقد تحققت من صورتي وكل شيء على ما يرام",
        "preview_error_title": "حدثت أخطاء!",
        "preview_offer": "سنقوم بإرسال صورة جواز السفر الرقمية مباشرة عبر البريد الإلكتروني، بالإضافة إلى صور جواز السفر المطبوعة بنفسك مقابل 4,95 يورو",
        "preview_purchase_button": "شراء",
        "preview_retry_button": "حاول ثانية",
        "preview_tc_1": "أنا أوافق على",
        "preview_tc_2": "البنود و الظروف",
        "preview_tc_3": "و ال",
        "preview_tc_4": "سياسة الخصوصية",
        "preview_title": "تحقق من صورتك",
        "processing_hint": "يقوم الذكاء الاصطناعي الخاص بنا بمعالجة صورتك...",
        "processing_title": "يعالج",
        "result_download_button": "تحميل",
        "result_download_text": "تم إرسال الصور الخاصة بك لك عن طريق البريد الإلكتروني. شكرا لاستخدامك SelfIDeed!",
        "result_title": "نتيجة",
        "upload_button_text": "حمل الصورة",
        "upload_title": "التقط صورتك"
    },
    "se": {
        "background_removal_error": "Fel under borttagning av bakgrunden",
        "common_subtitle": "Världsomspännande pass/visumfoton",
        "countries_ad": "Andorra",
        "countries_ae": "Förenade Arabemiraten",
        "countries_al": "Albanien",
        "countries_am": "Armenien",
        "countries_at": "Österrike",
        "countries_au": "Australien",
        "countries_az": "Azerbajdzjan",
        "countries_ba": "Bosnien Hercegovina",
        "countries_be": "Belgien",
        "countries_bg": "Bulgarien",
        "countries_br": "Brasilien",
        "countries_ch": "Schweiz",
        "countries_cn": "Kina",
        "countries_cy": "Cypern",
        "countries_cz": "Tjeckien",
        "countries_de": "Tyskland",
        "countries_dk": "Danmark",
        "countries_ee": "Estland",
        "countries_es": "Spanien",
        "countries_fi": "Finland",
        "countries_fr": "Frankrike",
        "countries_ge": "Georgien",
        "countries_gr": "Grekland",
        "countries_hr": "Kroatien",
        "countries_hu": "Ungern",
        "countries_id": "Indonesien",
        "countries_ie": "Irland",
        "countries_il": "Israel",
        "countries_in": "Indien",
        "countries_ir": "Iran",
        "countries_is": "Island",
        "countries_it": "Italien",
        "countries_jo": "Jordanien",
        "countries_jp": "Japan",
        "countries_kr": "Korea",
        "countries_kz": "Kazakstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litauen",
        "countries_lu": "Luxemburg",
        "countries_lv": "Lettland",
        "countries_mc": "Monaco",
        "countries_md": "Moldavien",
        "countries_me": "Montenegro",
        "countries_mk": "Makedonien",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "Nederländerna",
        "countries_no": "Norge",
        "countries_ph": "Filippinerna",
        "countries_pl": "Polen",
        "countries_pt": "Portugal",
        "countries_ro": "Rumänien",
        "countries_rs": "Serbien",
        "countries_ru": "Ryssland",
        "countries_sa": "Saudiarabien",
        "countries_se": "Sverige",
        "countries_si": "Slovenien",
        "countries_sk": "Slovakien",
        "countries_sm": "San Marino",
        "countries_th": "Thailand",
        "countries_title": "Välj ditt land",
        "countries_tr": "Turkiet",
        "countries_ua": "Ukraina",
        "countries_uk": "Storbritannien",
        "countries_us": "USA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Välj ett annat land",
        "country_options_gt10": "11 och uppåt",
        "country_options_gt5": "5 och äldre",
        "country_options_let10": "under 11 år",
        "country_options_let5": "under 5 år",
        "country_options_oci": "OCI",
        "country_options_online": "Ladda upp",
        "country_options_printed": "Tryckt",
        "country_options_title": "Välj din fototyp",
        "email_subject": "Dina foton",
        "email_text": "Dina bilder skickas till dig via e-post. Tack för att du använder SelfIDeed!",
        "email_with_images": "Instruktioner för digitalt passfoto",
        "face_alignment_error_description": "Ditt foto kan inte bearbetas av AI utan att orsaka problem. Försök igen att flytta längre från kameran.",
        "face_alignment_error_title": "Ansiktsjusteringsfel",
        "hints_description_1": "Håll din mobil på armlängds avstånd och i ögonhöjd...",
        "hints_description_2": "...mot dagsljus.",
        "hints_description_3": "Stäng av blixten.",
        "hints_next_button": "Fortsätta",
        "hints_next_button_waiting": "Tänk på reglerna...",
        "hints_subtitle": "Instruktioner",
        "hints_title": "Regler",
        "image_resolution_too_small_description": "Bildupplösningen är för liten för att uppfylla kraven.",
        "image_resolution_too_small_title": "Bildupplösning för liten",
        "landing_digital_description": "Använd för att ladda upp ditt foto",
        "landing_digital_header": "Digital version",
        "landing_next_button": "Försök nu",
        "landing_passport_description": "Använd för att skriva ut själv på 10 x 15 cm fotopapper ... eller... låt det skrivas ut på en fotoutskriftstjänst",
        "landing_passport_header": "Passfoton",
        "landing_title": "Gör dina passfoton online",
        "no_face_error": "Ansiktet kunde inte hittas av vår algoritm - försök igen,",
        "preview_checked_photo": "Jag kollade på mitt foto och det är OK",
        "preview_error_title": "Fel uppstod!",
        "preview_offer": "Vi skickar det digitala passfotot direkt via e-post tillsammans med skriv ut-själv passfoton för 4,95 Euro",
        "preview_purchase_button": "Inköp",
        "preview_retry_button": "Försök igen",
        "preview_tc_1": "Jag håller med om",
        "preview_tc_2": "Allmänna Villkor",
        "preview_tc_3": "och den",
        "preview_tc_4": "Integritetspolicy",
        "preview_title": "Kontrollera ditt foto",
        "processing_hint": "Vår AI bearbetar ditt foto...",
        "processing_title": "Bearbetning",
        "result_download_button": "Ladda ner",
        "result_download_text": "Dina bilder har skickats till dig via e-post. Tack för att du använder SelfIDeed!",
        "result_title": "Resultat",
        "upload_button_text": "Ladda upp foto",
        "upload_title": "Ta ditt foto"
    },
    "sk": {
        "background_removal_error": "Napaka med odstranjevanjem ozadja",
        "common_subtitle": "Svetovne fotografije potnih listov/vizum",
        "countries_ad": "Andora",
        "countries_ae": "Spojené arabské emiráty",
        "countries_al": "Albanija",
        "countries_am": "Armenija",
        "countries_at": "Avstrija",
        "countries_au": "Avstralija",
        "countries_az": "Azerbajdžan",
        "countries_ba": "Bosna in Hercegovina",
        "countries_be": "Belgija",
        "countries_bg": "Bolgarija",
        "countries_br": "Brazilija",
        "countries_ch": "Švica",
        "countries_cn": "Kitajska",
        "countries_cy": "Ciper",
        "countries_cz": "Češka",
        "countries_de": "Nemčija",
        "countries_dk": "Danska",
        "countries_ee": "Estonija",
        "countries_es": "Španija",
        "countries_fi": "Finska",
        "countries_fr": "Francija",
        "countries_ge": "Georgia",
        "countries_gr": "Grčija",
        "countries_hr": "Hrvaška",
        "countries_hu": "Madžarska",
        "countries_id": "Indonezija",
        "countries_ie": "Irska",
        "countries_il": "Izrael",
        "countries_in": "Indija",
        "countries_ir": "Iran",
        "countries_is": "Islandija",
        "countries_it": "Italija",
        "countries_jo": "Jordánsko",
        "countries_jp": "Japonska",
        "countries_kr": "Koreja",
        "countries_kz": "Kazahstan",
        "countries_lb": "Libanon",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litva",
        "countries_lu": "Luksemburg",
        "countries_lv": "Latvija",
        "countries_mc": "Monako",
        "countries_md": "Moldavija",
        "countries_me": "Črna gora",
        "countries_mk": "Makedonija",
        "countries_mt": "Malta",
        "countries_my": "Malezija",
        "countries_ng": "Nigerija",
        "countries_nl": "Nizozemska",
        "countries_no": "Norveška",
        "countries_ph": "Filipini",
        "countries_pl": "Poljska",
        "countries_pt": "Portugalska",
        "countries_ro": "Romunija",
        "countries_rs": "Srbija",
        "countries_ru": "Rusija",
        "countries_sa": "Savdska Arabija",
        "countries_se": "Švedska",
        "countries_si": "Slovenija",
        "countries_sk": "Slovaška",
        "countries_sm": "San Marino",
        "countries_th": "Tajska",
        "countries_title": "Izberite svojo državo",
        "countries_tr": "Turecko",
        "countries_ua": "Ukrajina",
        "countries_uk": "Združeno kraljestvo",
        "countries_us": "ZDA",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Izberite drugo državo",
        "country_options_gt10": "11 in več",
        "country_options_gt5": "5 a staršie",
        "country_options_let10": "mlajši od 11 let",
        "country_options_let5": "do 5 rokov",
        "country_options_oci": "OCI",
        "country_options_online": "Naloži",
        "country_options_printed": "Tiskano",
        "country_options_title": "Izberite svojo vrsto fotografije",
        "email_subject": "Tvoje fotografije",
        "email_text": "Vaše fotografije vam pošljemo po e-pošti. Hvala, ker uporabljate SelfIDeed!",
        "email_with_images": "Navodila za fotografijo digitalnega potnega lista",
        "face_alignment_error_description": "Umetna inteligenca ne more obdelati vaše fotografije, ne da bi povzročila težave. Poskusite znova in se premaknite dlje od kamere.",
        "face_alignment_error_title": "Napaka pri poravnavi obraza",
        "hints_description_1": "Mobilni telefon držite na razdalji rok in v višini oči...",
        "hints_description_2": "...proti dnevu.",
        "hints_description_3": "Izklopi bliskavico.",
        "hints_next_button": "Nadaljuj",
        "hints_next_button_waiting": "Upoštevajte pravila ...",
        "hints_subtitle": "Navodila",
        "hints_title": "Pravila",
        "image_resolution_too_small_description": "Ločljivost slike je premajhna, da bi ustrezala zahtevam.",
        "image_resolution_too_small_title": "Premajhna ločljivost slike",
        "landing_digital_description": "Uporabite za nalaganje vaše fotografije",
        "landing_digital_header": "Digitalna različica",
        "landing_next_button": "Preizkusite zdaj",
        "landing_passport_description": "Uporabite za tiskanje sami na fotografski papir 10 x 15 cm ... ali ... pustite, da natisne pri servisu za tiskanje fotografij",
        "landing_passport_header": "Fotografije potnega lista",
        "landing_title": "Ustvarite svoje fotografije za potni list na spletu",
        "no_face_error": "Naš algoritem ni mogel najti obraza - poskusite znova,",
        "preview_checked_photo": "Preveril sem svojo fotografijo in je v redu",
        "preview_error_title": "Prišlo je do napak!",
        "preview_offer": "Digitalno fotografijo potnega lista vam bomo poslali neposredno po e-pošti, skupaj s fotografijami potnega lista, ki jih natisnete sami, za 4,95 evra",
        "preview_purchase_button": "Nakup",
        "preview_retry_button": "Poskusi ponovno",
        "preview_tc_1": "Strinjam se z",
        "preview_tc_2": "Pogoji uporabe",
        "preview_tc_3": "in",
        "preview_tc_4": "Politika zasebnosti",
        "preview_title": "Preverite svojo fotografijo",
        "processing_hint": "Naš AI obdeluje vašo fotografijo ...",
        "processing_title": "Obravnavati",
        "result_download_button": "Prenesi",
        "result_download_text": "Vaše fotografije so vam bile poslane po e-pošti. Hvala, ker uporabljate SelfIDeed!",
        "result_title": "Rezultat",
        "upload_button_text": "Naloži fotografijo",
        "upload_title": "Posnemite svojo fotografijo"
    },
    "th": {
        "background_removal_error": "เกิดข้อผิดพลาดระหว่างการลบพื้นหลัง",
        "common_subtitle": "ภาพถ่ายหนังสือเดินทาง / วีซ่าทั่วโลก",
        "countries_ad": "อันดอร์รา",
        "countries_ae": "สหรัฐอาหรับเอมิเรตส์",
        "countries_al": "แอลเบเนีย",
        "countries_am": "อาร์เมเนีย",
        "countries_at": "ออสเตรีย",
        "countries_au": "ออสเตรเลีย",
        "countries_az": "อาเซอร์ไบจาน",
        "countries_ba": "บอสเนียและเฮอร์เซโกวีนา",
        "countries_be": "เบลเยียม",
        "countries_bg": "บัลแกเรีย",
        "countries_br": "บราซิล",
        "countries_ch": "สวิตเซอร์แลนด์",
        "countries_cn": "จีน",
        "countries_cy": "ไซปรัส",
        "countries_cz": "เช็กเกีย",
        "countries_de": "เยอรมนี",
        "countries_dk": "เดนมาร์ก",
        "countries_ee": "เอสโตเนีย",
        "countries_es": "สเปน",
        "countries_fi": "ฟินแลนด์",
        "countries_fr": "ฝรั่งเศส",
        "countries_ge": "จอร์เจีย",
        "countries_gr": "กรีซ",
        "countries_hr": "โครเอเชีย",
        "countries_hu": "ฮังการี",
        "countries_id": "อินโดนีเซีย",
        "countries_ie": "ไอร์แลนด์",
        "countries_il": "อิสราเอล",
        "countries_in": "อินเดีย",
        "countries_ir": "อิหร่าน",
        "countries_is": "ไอซ์แลนด์",
        "countries_it": "อิตาลี",
        "countries_jo": "จอร์แดน",
        "countries_jp": "ญี่ปุ่น",
        "countries_kr": "เกาหลี",
        "countries_kz": "คาซัคสถาน",
        "countries_lb": "ลิบานอน",
        "countries_li": "ลิกเตนสไตน์",
        "countries_lt": "ลิทัวเนีย",
        "countries_lu": "ลักเซมเบิร์ก",
        "countries_lv": "ลัตเวีย",
        "countries_mc": "โมนาโก",
        "countries_md": "มอลโดวา",
        "countries_me": "มอนเตเนโกร",
        "countries_mk": "มาซิโดเนีย",
        "countries_mt": "มอลตา",
        "countries_my": "มาเลเซีย",
        "countries_ng": "ไนจีเรีย",
        "countries_nl": "เนเธอร์แลนด์",
        "countries_no": "นอร์เวย์",
        "countries_ph": "ฟิลิปปินส์",
        "countries_pl": "โปแลนด์",
        "countries_pt": "โปรตุเกส",
        "countries_ro": "โรเมเนีย",
        "countries_rs": "เซอร์เบีย",
        "countries_ru": "รัสเซีย",
        "countries_sa": "ซาอุดิอาราเบีย",
        "countries_se": "สวีเดน",
        "countries_si": "สโลวีเนีย",
        "countries_sk": "สโลวาเกีย",
        "countries_sm": "ซานมารีโน",
        "countries_th": "ประเทศไทย",
        "countries_title": "เลือกประเทศของคุณ",
        "countries_tr": "ตุรกี",
        "countries_ua": "ยูเครน",
        "countries_uk": "ประเทศอังกฤษ",
        "countries_us": "สหรัฐอเมริกา",
        "countries_vn": "เวียดนาม",
        "countries_xk": "โคโซโว",
        "country_options_choose_lang": "เลือกประเทศอื่น",
        "country_options_gt10": "11 ขึ้นไป",
        "country_options_gt5": "5 ปีขึ้นไป",
        "country_options_let10": "อายุต่ำกว่า 11 ปี",
        "country_options_let5": "อายุต่ำกว่า 5 ปี",
        "country_options_oci": "OCI",
        "country_options_online": "ที่อัพโหลด",
        "country_options_printed": "พิมพ์แล้ว",
        "country_options_title": "เลือกประเภทภาพถ่ายของคุณ",
        "email_subject": "รูปของคุณ",
        "email_text": "ภาพถ่ายของคุณจะถูกส่งถึงคุณทางอีเมล ขอบคุณที่ใช้ SelfIDeed!",
        "email_with_images": "คำแนะนำการถ่ายภาพหนังสือเดินทางดิจิทัล",
        "face_alignment_error_description": "AI ไม่สามารถประมวลผลรูปภาพของคุณโดยไม่ทำให้เกิดปัญหา โปรดลองขยับออกห่างจากกล้องอีกครั้ง",
        "face_alignment_error_title": "ข้อผิดพลาดในการจัดตำแหน่งใบหน้า",
        "hints_description_1": "ถือมือถือให้อยู่ในระยะแขนและระดับสายตา...",
        "hints_description_2": "...เข้าสู่ช่วงกลางวัน",
        "hints_description_3": "ปิดแฟลช",
        "hints_next_button": "ดำเนินการต่อ",
        "hints_next_button_waiting": "พิจารณากฎเกณฑ์...",
        "hints_subtitle": "คำแนะนำ",
        "hints_title": "กฎ",
        "image_resolution_too_small_description": "ความละเอียดของภาพน้อยเกินไปที่จะตอบสนองความต้องการ",
        "image_resolution_too_small_title": "ความละเอียดของภาพเล็กเกินไป",
        "landing_digital_description": "ใช้สำหรับอัพโหลดรูปภาพของคุณ",
        "landing_digital_header": "เวอร์ชันดิจิทัล",
        "landing_next_button": "ลองตอนนี้",
        "landing_passport_description": "ใช้เพื่อพิมพ์ตัวคุณเองบนกระดาษภาพถ่ายขนาด 10 x 15 ซม. ...หรือ... ให้พิมพ์ที่บริการพิมพ์ภาพถ่าย",
        "landing_passport_header": "ภาพถ่ายหนังสือเดินทาง",
        "landing_title": "ทำรูปถ่ายหนังสือเดินทางของคุณออนไลน์",
        "no_face_error": "อัลกอริทึมของเราไม่พบใบหน้า - โปรดลองอีกครั้ง",
        "preview_checked_photo": "ฉันตรวจสอบรูปถ่ายของฉันแล้ว และมันก็โอเค",
        "preview_error_title": "เกิดข้อผิดพลาด!",
        "preview_offer": "เราจะส่งภาพถ่ายหนังสือเดินทางดิจิทัลทางอีเมลโดยตรง พร้อมด้วยภาพถ่ายหนังสือเดินทางที่พิมพ์ด้วยตัวเองในราคา 4.95 ยูโร",
        "preview_purchase_button": "ซื้อ",
        "preview_retry_button": "ลองอีกครั้ง",
        "preview_tc_1": "ฉันเห็นด้วยกับ",
        "preview_tc_2": "ข้อตกลงและเงื่อนไข",
        "preview_tc_3": "และ",
        "preview_tc_4": "นโยบายความเป็นส่วนตัว",
        "preview_title": "ตรวจสอบรูปถ่ายของคุณ",
        "processing_hint": "AI ของเรากำลังประมวลผลรูปภาพของคุณ...",
        "processing_title": "กำลังประมวลผล",
        "result_download_button": "ดาวน์โหลด",
        "result_download_text": "ภาพถ่ายของคุณถูกส่งถึงคุณทางอีเมล ขอบคุณที่ใช้ SelfIDeed!",
        "result_title": "ผลลัพธ์",
        "upload_button_text": "อัพโหลดรูปภาพ",
        "upload_title": "ถ่ายภาพของคุณ"
    },
    "tr": {
        "background_removal_error": "Arka plan kaldırılırken hata oluştu",
        "common_subtitle": "Dünya Çapında Pasaport/Vize Fotoğrafları",
        "countries_ad": "Andorra",
        "countries_ae": "Birleşik Arap Emirlikleri",
        "countries_al": "Arnavutluk",
        "countries_am": "Ermenistan",
        "countries_at": "Avusturya",
        "countries_au": "Avustralya",
        "countries_az": "Azerbaycan",
        "countries_ba": "Bosna Hersek",
        "countries_be": "Belçika",
        "countries_bg": "Bulgaristan",
        "countries_br": "Brezilya",
        "countries_ch": "İsviçre",
        "countries_cn": "Çin",
        "countries_cy": "Kıbrıs",
        "countries_cz": "Çekya",
        "countries_de": "Almanya",
        "countries_dk": "Danimarka",
        "countries_ee": "Estonya",
        "countries_es": "ispanya",
        "countries_fi": "Finlandiya",
        "countries_fr": "Fransa",
        "countries_ge": "Gürcistan",
        "countries_gr": "Yunanistan",
        "countries_hr": "Hırvatistan",
        "countries_hu": "Macaristan",
        "countries_id": "Endonezya",
        "countries_ie": "İrlanda",
        "countries_il": "İsrail",
        "countries_in": "Hindistan",
        "countries_ir": "İran",
        "countries_is": "İzlanda",
        "countries_it": "İtalya",
        "countries_jo": "Ürdün",
        "countries_jp": "Japonya",
        "countries_kr": "Kore",
        "countries_kz": "Kazakistan",
        "countries_lb": "Lübnan",
        "countries_li": "Lihtenştayn",
        "countries_lt": "Litvanya",
        "countries_lu": "Lüksemburg",
        "countries_lv": "Letonya",
        "countries_mc": "Monako",
        "countries_md": "Moldova",
        "countries_me": "Karadağ",
        "countries_mk": "Makedonya",
        "countries_mt": "Malta",
        "countries_my": "Malezya",
        "countries_ng": "Nijerya",
        "countries_nl": "Hollanda",
        "countries_no": "Norveç",
        "countries_ph": "Filipinler",
        "countries_pl": "Polonya",
        "countries_pt": "Portekiz",
        "countries_ro": "Romanya",
        "countries_rs": "Sırbistan",
        "countries_ru": "Rusya",
        "countries_sa": "Suudi Arabistan",
        "countries_se": "İsveç",
        "countries_si": "Slovenya",
        "countries_sk": "Slovakya",
        "countries_sm": "San Marino",
        "countries_th": "Tayland",
        "countries_title": "Ülkenizi seçin",
        "countries_tr": "Türkiye",
        "countries_ua": "Ukrayna",
        "countries_uk": "Birleşik Krallık",
        "countries_us": "Amerika Birleşik Devletleri",
        "countries_vn": "Vietnam",
        "countries_xk": "Kosova",
        "country_options_choose_lang": "Farklı bir ülke seçin",
        "country_options_gt10": "11 ve üzeri",
        "country_options_gt5": "5 yaş ve üzeri",
        "country_options_let10": "11 yaş altı",
        "country_options_let5": "5 yaş altı",
        "country_options_oci": "OCI",
        "country_options_online": "Yüklemek",
        "country_options_printed": "Basılı",
        "country_options_title": "Fotoğraf Türünüzü Seçin",
        "email_subject": "Fotoğraflarınızı",
        "email_text": "Fotoğraflarınız tarafınıza e-posta ile gönderilmektedir. SelfIDeed'i kullandığınız için teşekkür ederiz!",
        "email_with_images": "Dijital Pasaport Fotoğraflı Talimatlar",
        "face_alignment_error_description": "Fotoğrafınız AI tarafından sorunlara yol açmadan işlenemez. Lütfen kameradan uzaklaşmayı tekrar deneyin.",
        "face_alignment_error_title": "Yüz Hizalama Hatası",
        "hints_description_1": "Cep telefonunuzu kol boyu ve göz hizasında tutun...",
        "hints_description_2": "...gün ışığına doğru.",
        "hints_description_3": "Flaşı kapatın.",
        "hints_next_button": "Devam etmek",
        "hints_next_button_waiting": "Kuralları göz önünde bulundurun...",
        "hints_subtitle": "Talimatlar",
        "hints_title": "Tüzük",
        "image_resolution_too_small_description": "Görüntü çözünürlüğü gereksinimleri karşılamak için çok küçük.",
        "image_resolution_too_small_title": "Görüntü Çözünürlüğü Çok Küçük",
        "landing_digital_description": "Fotoğrafınızı yüklemek için kullanın",
        "landing_digital_header": "Dijital Versiyon",
        "landing_next_button": "Şimdi dene",
        "landing_passport_description": "10 x 15 cm fotoğraf kağıdına kendiniz baskı yapmak için kullanın ...veya... bir fotoğraf baskı servisinde bastırın",
        "landing_passport_header": "Pasaport Fotoğrafları",
        "landing_title": "Pasaport Fotoğraflarınızı Çevrimiçi Yapın",
        "no_face_error": "Yüz, algoritmamız tarafından bulunamadı - lütfen tekrar deneyin.",
        "preview_checked_photo": "Fotoğrafımı kontrol ettim ve sorun yok",
        "preview_error_title": "Hatalar Oluştu!",
        "preview_offer": "Dijital vesikalık fotoğrafı, kendiniz bastırabileceğiniz vesikalık fotoğraflarla birlikte 4,95 Euro karşılığında doğrudan e-postayla göndereceğiz",
        "preview_purchase_button": "Satın almak",
        "preview_retry_button": "Tekrar deneyin",
        "preview_tc_1": "katılıyorum",
        "preview_tc_2": "şartlar ve koşullar",
        "preview_tc_3": "ve",
        "preview_tc_4": "Gizlilik Politikası",
        "preview_title": "Fotoğrafınızı Kontrol Edin",
        "processing_hint": "Yapay zekamız fotoğrafınızı işliyor...",
        "processing_title": "İşleme",
        "result_download_button": "İndirmek",
        "result_download_text": "Fotoğraflarınız e-posta yoluyla tarafınıza gönderildi. SelfIDeed'i kullandığınız için teşekkür ederiz!",
        "result_title": "Sonuç",
        "upload_button_text": "Fotoğraf yükle",
        "upload_title": "Fotoğrafını Çek"
    },
    "ua": {
        "background_removal_error": "Помилка під час видалення фону",
        "common_subtitle": "Фотографії паспортів/віз у всьому світі",
        "countries_ad": "Андорра",
        "countries_ae": "Об'єднані Арабські Емірати",
        "countries_al": "Албанія",
        "countries_am": "Вірменія",
        "countries_at": "Австрія",
        "countries_au": "Австралія",
        "countries_az": "Азербайджан",
        "countries_ba": "Боснія і Герцеговина",
        "countries_be": "Бельгія",
        "countries_bg": "Болгарія",
        "countries_br": "Бразилія",
        "countries_ch": "Швейцарія",
        "countries_cn": "Китай",
        "countries_cy": "Кіпр",
        "countries_cz": "Чехія",
        "countries_de": "Німеччина",
        "countries_dk": "Данія",
        "countries_ee": "Естонія",
        "countries_es": "Іспанія",
        "countries_fi": "Фінляндія",
        "countries_fr": "Франція",
        "countries_ge": "Грузія",
        "countries_gr": "Греція",
        "countries_hr": "Хорватія",
        "countries_hu": "Угорщина",
        "countries_id": "Індонезія",
        "countries_ie": "Ірландія",
        "countries_il": "Ізраїль",
        "countries_in": "Індія",
        "countries_ir": "Іран",
        "countries_is": "Ісландія",
        "countries_it": "Італія",
        "countries_jo": "Йорданія",
        "countries_jp": "Японія",
        "countries_kr": "Корея",
        "countries_kz": "Казахстан",
        "countries_lb": "Ліван",
        "countries_li": "Ліхтенштейн",
        "countries_lt": "Литва",
        "countries_lu": "Люксембург",
        "countries_lv": "Латвія",
        "countries_mc": "Монако",
        "countries_md": "Молдова",
        "countries_me": "Чорногорія",
        "countries_mk": "Македонія",
        "countries_mt": "Мальта",
        "countries_my": "Малайзія",
        "countries_ng": "Нігерія",
        "countries_nl": "Нідерланди",
        "countries_no": "Норвегія",
        "countries_ph": "Філіппіни",
        "countries_pl": "Польща",
        "countries_pt": "Португалія",
        "countries_ro": "Роменія",
        "countries_rs": "Сербія",
        "countries_ru": "Росія",
        "countries_sa": "Саудівська Аравія",
        "countries_se": "Швеція",
        "countries_si": "Словенія",
        "countries_sk": "Словаччина",
        "countries_sm": "Сан-Марино",
        "countries_th": "Таїланд",
        "countries_title": "Виберіть свою країну",
        "countries_tr": "Туреччина",
        "countries_ua": "Україна",
        "countries_uk": "Об'єднане Королівство",
        "countries_us": "США",
        "countries_vn": "В'єтнам",
        "countries_xk": "Косово",
        "country_options_choose_lang": "Виберіть іншу країну",
        "country_options_gt10": "11 і більше",
        "country_options_gt5": "5 і старше",
        "country_options_let10": "до 11 років",
        "country_options_let5": "до 5 років",
        "country_options_oci": "OCI",
        "country_options_online": "Завантажити",
        "country_options_printed": "Роздрукований",
        "country_options_title": "Виберіть тип фото",
        "email_subject": "Ваші фотографії",
        "email_text": "Ваші фотографії надсилаються вам електронною поштою. Дякуємо за використання SelfIDeed!",
        "email_with_images": "Інструкція з фото цифрового паспорта",
        "face_alignment_error_description": "ШІ не може обробити вашу фотографію без проблем. Спробуйте знову відійти далі від камери.",
        "face_alignment_error_title": "Помилка вирівнювання обличчя",
        "hints_description_1": "Тримайте мобільний на відстані витягнутої руки та на рівні очей...",
        "hints_description_2": "...назустріч денному світлу.",
        "hints_description_3": "Вимкніть спалах.",
        "hints_next_button": "Продовжити",
        "hints_next_button_waiting": "Розглянемо правила...",
        "hints_subtitle": "Інструкції",
        "hints_title": "правила",
        "image_resolution_too_small_description": "Роздільна здатність зображення занадто мала, щоб відповідати вимогам.",
        "image_resolution_too_small_title": "Роздільна здатність зображення занадто мала",
        "landing_digital_description": "Використовуйте для завантаження фото",
        "landing_digital_header": "Цифрова версія",
        "landing_next_button": "Спробуємо зараз",
        "landing_passport_description": "Використовуйте для самостійного друку на фотопапері розміром 10 x 15 см ... або... дозвольте надрукувати його в фотослужбі",
        "landing_passport_header": "Фотографії на паспорт",
        "landing_title": "Зробіть фотографії на паспорт онлайн",
        "no_face_error": "Обличчя не знайдено нашим алгоритмом. Спробуйте ще раз,",
        "preview_checked_photo": "Я перевірив свою фотографію, все гаразд",
        "preview_error_title": "Сталися помилки!",
        "preview_offer": "Ми надішлемо електронною поштою цифрову фотографію паспорта разом із роздрукованими фотографіями паспорта за 4,95 євро",
        "preview_purchase_button": "Купівля",
        "preview_retry_button": "Спробуйте знову",
        "preview_tc_1": "Я згоден на",
        "preview_tc_2": "Правила та умови",
        "preview_tc_3": "і",
        "preview_tc_4": "Політика конфіденційності",
        "preview_title": "Перевірте свою фотографію",
        "processing_hint": "Наш штучний інтелект обробляє вашу фотографію...",
        "processing_title": "Обробка",
        "result_download_button": "Завантажити",
        "result_download_text": "Ваші фотографії надіслано вам електронною поштою. Дякуємо за використання SelfIDeed!",
        "result_title": "Результат",
        "upload_button_text": "Завантажити фото",
        "upload_title": "Зробіть своє фото"
    },
    "vn": {
        "background_removal_error": "Lỗi khi xóa nền",
        "common_subtitle": "Ảnh hộ chiếu/visa trên toàn thế giới",
        "countries_ad": "Andorra",
        "countries_ae": "Các Tiểu vương quốc Ả Rập Thống nhất",
        "countries_al": "Albania",
        "countries_am": "Armenia",
        "countries_at": "Áo",
        "countries_au": "Châu Úc",
        "countries_az": "Azerbaijan",
        "countries_ba": "Bosnia Herzegovina",
        "countries_be": "nước Bỉ",
        "countries_bg": "Bulgaria",
        "countries_br": "Brazil",
        "countries_ch": "Thụy sĩ",
        "countries_cn": "Trung Quốc",
        "countries_cy": "Síp",
        "countries_cz": "Séc",
        "countries_de": "nước Đức",
        "countries_dk": "Đan mạch",
        "countries_ee": "Estonia",
        "countries_es": "Tây ban nha",
        "countries_fi": "Phần Lan",
        "countries_fr": "Pháp",
        "countries_ge": "Gruzia",
        "countries_gr": "Hy Lạp",
        "countries_hr": "Croatia",
        "countries_hu": "Hungary",
        "countries_id": "Indonesia",
        "countries_ie": "Ireland",
        "countries_il": "Israel",
        "countries_in": "Ấn Độ",
        "countries_ir": "Iran",
        "countries_is": "Nước Iceland",
        "countries_it": "Nước Ý",
        "countries_jo": "Jordan",
        "countries_jp": "Nhật Bản",
        "countries_kr": "Hàn Quốc",
        "countries_kz": "Kazachstan",
        "countries_lb": "Liban",
        "countries_li": "Lichtenstein",
        "countries_lt": "Litva",
        "countries_lu": "Luxembourg",
        "countries_lv": "Latvia",
        "countries_mc": "Monaco",
        "countries_md": "Moldova",
        "countries_me": "Montenegro",
        "countries_mk": "Macedonia",
        "countries_mt": "Malta",
        "countries_my": "Malaysia",
        "countries_ng": "Nigeria",
        "countries_nl": "nước Hà Lan",
        "countries_no": "Na Uy",
        "countries_ph": "Philippin",
        "countries_pl": "Ba Lan",
        "countries_pt": "Bồ Đào Nha",
        "countries_ro": "Romenia",
        "countries_rs": "Serbia",
        "countries_ru": "Nga",
        "countries_sa": "Ả Rập Saudi",
        "countries_se": "Thụy Điển",
        "countries_si": "Slovenia",
        "countries_sk": "Slovakia",
        "countries_sm": "San Marino",
        "countries_th": "nước Thái Lan",
        "countries_title": "Chọn quốc gia của bạn",
        "countries_tr": "Thổ Nhĩ Kỳ",
        "countries_ua": "Ukraina",
        "countries_uk": "Vương quốc Anh",
        "countries_us": "Hoa Kỳ",
        "countries_vn": "Việt Nam",
        "countries_xk": "Kosovo",
        "country_options_choose_lang": "Chọn một quốc gia khác",
        "country_options_gt10": "11 tuổi trở lên",
        "country_options_gt5": "5 tuổi trở lên",
        "country_options_let10": "dưới 11 tuổi",
        "country_options_let5": "dưới 5 tuổi",
        "country_options_oci": "OCI",
        "country_options_online": "Tải lên",
        "country_options_printed": "Đã in",
        "country_options_title": "Chọn loại ảnh của bạn",
        "email_subject": "Những bức ảnh của bạn",
        "email_text": "Ảnh của bạn sẽ được gửi cho bạn qua email. Cảm ơn bạn đã sử dụng SelfIDeed!",
        "email_with_images": "Hướng dẫn chụp ảnh hộ chiếu kỹ thuật số",
        "face_alignment_error_description": "AI của bạn không thể xử lý ảnh của bạn mà không gây ra sự cố. Vui lòng thử lại di chuyển xa hơn khỏi máy ảnh.",
        "face_alignment_error_title": "Lỗi căn chỉnh khuôn mặt",
        "hints_description_1": "Giữ điện thoại di động của bạn ở độ dài cánh tay và ngang tầm mắt...",
        "hints_description_2": "...về phía ánh sáng ban ngày.",
        "hints_description_3": "Tắt đèn nháy.",
        "hints_next_button": "Tiếp tục",
        "hints_next_button_waiting": "Hãy xem xét các quy tắc...",
        "hints_subtitle": "Hướng dẫn",
        "hints_title": "Quy tắc",
        "image_resolution_too_small_description": "Độ phân giải hình ảnh quá nhỏ so với yêu cầu.",
        "image_resolution_too_small_title": "Độ phân giải hình ảnh quá nhỏ",
        "landing_digital_description": "Sử dụng để tải lên ảnh của bạn",
        "landing_digital_header": "Phiên bản kỹ thuật số",
        "landing_next_button": "Thử ngay bây giờ",
        "landing_passport_description": "Dùng để tự in trên giấy ảnh 10 x 15 cm ...hoặc... để in ở dịch vụ in ảnh",
        "landing_passport_header": "Ảnh hộ chiếu",
        "landing_title": "Tạo ảnh hộ chiếu của bạn trực tuyến",
        "no_face_error": "Thuật toán của chúng tôi không thể tìm thấy khuôn mặt - vui lòng thử lại,",
        "preview_checked_photo": "Tôi đã kiểm tra ảnh của mình và nó ổn",
        "preview_error_title": "Đã xảy ra lỗi!",
        "preview_offer": "Chúng tôi sẽ trực tiếp gửi email ảnh hộ chiếu kỹ thuật số, cùng với ảnh hộ chiếu do bạn tự in với giá 4,95 Euro",
        "preview_purchase_button": "Mua",
        "preview_retry_button": "Thử lại",
        "preview_tc_1": "Tôi đồng ý với",
        "preview_tc_2": "Điều khoản và điều kiện",
        "preview_tc_3": "và",
        "preview_tc_4": "Chính sách bảo mật",
        "preview_title": "Kiểm tra ảnh của bạn",
        "processing_hint": "AI của chúng tôi đang xử lý ảnh của bạn...",
        "processing_title": "Xử lý",
        "result_download_button": "Tải xuống",
        "result_download_text": "Ảnh của bạn đã được gửi cho bạn qua email. Cảm ơn bạn đã sử dụng SelfIDeed!",
        "result_title": "Kết quả",
        "upload_button_text": "Tải ảnh lên",
        "upload_title": "Chụp ảnh của bạn"
    }
}