import React, { useContext } from "react";
import { BottomBar } from "./components/BottomBar";
import { HintList } from "./components/HintList";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./contexts/LanguageContext";

const imgRules = new URL("../img/ID.manual-4.gif", import.meta.url);

const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);


export const Hints4 = () => {
    const language = useContext(LanguageContext);
    const navigate = useNavigate();
    const searchParams = window.location.search;
    const [canContinue, setCanContinue] = React.useState(false);
    const [clickCount, setClickCount] = React.useState(0);

    React.useEffect(() => {
        console.log("free");
        async function load() {
            await sleep(5000);
            setCanContinue(true);
        }
        load();
    });

    const onClick = () => {
        setClickCount(clickCount + 1);
        if (canContinue || clickCount > 3) {
            navigate(`/hints5/${searchParams}`);
        }
    }

    return (
        <>
            {/* <HintList /> */}
            <img src={imgRules.toString()} className="mx-auto " />,
            <div onClick={onClick} className="">
                <BottomBar text={canContinue ? language.hints_next_button : language.hints_next_button_waiting} inactivate={!canContinue} />
            </div>
        </>
    );
}