import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { Upload } from "./micro/icons";

// const photo_preview = new URL("../../img/PhotoPreview.jpg", import.meta.url);
const photo_animation = new URL("../../img/ID.landingpage.gif", import.meta.url);

type Props = {
};
export const StartCard = (props: Props) => {
  const language = useContext(LanguageContext);
  return (
    <>
      <div className="bg-secondary border border-dashed border-primary rounded-3xl flex justify-center items-center h-72 w-72">
        <div className="flex flex-col items-center">
          <img src={photo_animation.toString()} className="object-contain drop-shadow-lg h-36 mx-2 mb-4" />
        </div>
      </div>
    </>
  );
};