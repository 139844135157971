import React, { useContext } from "react";

import { App } from "./App";
import { Upload } from "./Upload";
import { PhotoPreview } from "./PhotoPreview";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./error-page";
import { previewLoader } from "./previewLoader";
import { Login } from "./Login";
import { Payment } from "./Payment";
import { PhotoResult } from "./PhotoResult";
import { Home } from "./Home";
import { Countries } from "./Countries";
import { Landing } from "./Landing";
import { CountryOptions } from "./CountryOptions";
import { Hints } from "./Hints";
import { Hints2 } from "./Hints2";
import { Hints3 } from "./Hints3";
import { translations } from "./lang/texts";
import { LoginAnon } from "./LoginAnon";
import { Hints4 } from "./Hints4";
import { Hints5 } from "./Hints5";

const routes = [
  {
    path: "upload",
    element: <Upload />,
    title: "upload_title",
  },
  {
    path: "preview/:photoPaths",
    element: <PhotoPreview />,
    loader: previewLoader,
    title: "preview_title",
  },
  {
    path: "payment",
    element: <Payment />,
    title: "Payment",
  },
  {
    path: "result",
    element: <PhotoResult />,
    title: "result_title",
  },
  {
    path: "login",
    element: <LoginAnon />,
    title: "Login",
  },
  {
    path: "countries",
    element: <Countries />,
    title: "countries_title",
  },
  {
    path: "country-options",
    element: <CountryOptions />,
    title: "country_options_title",
  },
  {
    path: "hints",
    element: <Hints />,
    title: "hints_title",
  },
  {
    path: "hints2",
    element: <Hints2 />,
    title: "hints_title",
  },
  {
    path: "hints3",
    element: <Hints3 />,
    title: "hints_title",
  },
  {
    path: "hints4",
    element: <Hints4 />,
    title: "hints_title",
  },
  {
    path: "hints5",
    element: <Hints5 />,
    title: "hints_title",
  },
  {
    path: "landing",
    element: <Landing />,
    title: "landing_title",
  },
  {
    path: "",
    element: <Home />,
    title: "Home",
  },
];
export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: routes,
  },
]);

export { routes };