
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { SelectionButton } from "./components/SelectionButton";
import { BottomBar } from "./components/BottomBar";
// import { translations } from "./lang/texts";
import { LanguageContext } from "./contexts/LanguageContext";
import { StartCard } from "./components/StartCard";
import { CountrySelected } from "./components/CountrySelected";
import { CountryContext } from "./contexts/CountryContext";

const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);


export const Landing = () => {
    const navigate = useNavigate();
    const language = useContext(LanguageContext);
    const { country } = useContext(CountryContext);
    const [clickCount, setClickCount] = React.useState(0);
    const [canContinue, setCanContinue] = React.useState(false);


    const onNext = () => {
        if (!country) {
            navigate("/countries");
        }
        const supportedCountryList = [
            "de",
            "nl",
            "us",
            "gr",
        ]
        if (!supportedCountryList.includes(country || "")) {
            navigate("/countries");
        } else {
            navigate("/country-options/?country=" + country);
        }
    };

    React.useEffect(() => {
        console.log("free");
        async function load() {
            await sleep(8500);
            setCanContinue(true);
        }
        load();
    });

    const onClick = () => {
        setClickCount(clickCount + 1);
        if (canContinue || clickCount > 3) {
            onNext();
        }
    }

    return (
        <>
            <div className="flex flex-col w-full items-center justify-center px-6">
                {/* <div className="grid grid-cols-2">
                    <div className="flex flex-col p-3 flex-auto">
                        <img src={photo_preview.toString()} className="object-contain drop-shadow-lg h-24 mx-2 mb-4" />
                        <h3 className="text-grayish text-l font-bold mb-2">{language.landing_digital_header}</h3>
                        <p className="text-grayish">{language.landing_digital_description}</p>
                    </div>
                    <div className="flex flex-col p-3 flex-auto">
                        <img src={grid_preview.toString()} className="object-contain drop-shadow-lg h-24 mx-2 mb-4" />
                        <h3 className="text-grayish text-l font-bold mb-2">{language.landing_passport_header}</h3>
                        <p className="text-grayish">{language.landing_passport_description}</p>
                    </div>
                </div> */}
                <CountrySelected />
                <div className="mt-12">
                    <StartCard />
                </div>
            </div>
            <div onClick={onClick} className="">
                <BottomBar text={canContinue ? language.hints_next_button : "........"} inactivate={!canContinue} />
            </div>
        </>
    )
}